/**
 * Generates an empty legend item with a specified color or a default color.
 * @param {object} lastItem - The last legend item to inherit the color from.
 * @returns {object} - An empty legend item with a name and color.
 */
export const getEmptyLegendItem = (lastItem) => {
  // Create a new legend item with an empty name
  // and a color inherited from the last item, or use a default color if no last item.
  return {
    name: "",
    color: lastItem?.color ?? lastItem ?? "#ffffff",
  };
};

export const getLastItem = (legendItems, colors) => {
  if (!legendItems && !colors) {
    return;
  }
  return legendItems ? legendItems.slice(-1)[0] : colors.slice(-1)[0];
};

export function removeDuplicates(array) {
  return [...new Set(array)].filter(Boolean).map((item) => ({
    label: item,
    value: item,
  }));
}

export const mapColorsToLegendStyle = (colors, legendItems) => {
  if (legendItems) {
    return null;
  }

  return colors.map((color) => ({ name: "", color }));
};

export const mapLegendStyleToColors = (legendItems) => {
  if (!legendItems) {
    return null;
  }

  return legendItems.map((item) => item.color);
};

export function removeSelected(options, legendItems) {
  const names = (legendItems ?? []).map((item) => item.name).filter(Boolean);

  return options.filter((option) => !names.includes(option.value));
}

export function generateNotExistingOption(options, option) {
  const existing = options.find((o) => o.value === option.name);

  const generated = {
    label: option.name,
    value: option.name,
  };

  return existing ?? generated;
}

export function getIsFutureLegendItem(item, unselected, legendItems, index) {
  return (
    item.name === "" &&
    unselected.length === 0 &&
    legendItems.filter((item) => item.name).length <= index
  );
}
