import { cloneDeep } from "lodash-es";
import { setGroupingKeyMapping } from "../TableVisualizationCollection";
import { getPinnedRows } from "./tableMapper";
import { getMappingType } from "../../../utils/getVisualizationLabel";

export const sortDynamicGroupingValues = (
  rows,
  target,
  groupingKey,
  value,
  direction,
  pinnedRows,
  groupingKeyMapping,
  totals,
  weekOffsetKey
) => {
  const cloned = cloneDeep(rows);

  const commonArgs = {
    value,
    groupingKey,
    target,
    direction,
    groupingKeyMapping,
    weekOffsetKey,
  };

  // totals should be like pinned rows, out of sorting
  const total = totals ? cloned.pop() : null;

  if (!pinnedRows) {
    return [
      ...cloned.sort((a, b) => sortFn({ a, b, ...commonArgs })),
      total,
    ].filter(Boolean);
  }

  const { pinned, index } = cutPinnedRow(rows, pinnedRows);

  const sorted = cloned
    .filter((_, i) => filterFn(i, index, pinned))
    .sort((a, b) => sortFn({ a, b, ...commonArgs }));

  if (pinnedRows[0]?.position === "top") {
    return [pinned, ...sorted];
  }

  return [...sorted, pinned, total].filter(Boolean);
};

function getCompareValue(row, props) {
  const { value, groupingKey, target, groupingKeyMapping, weekOffsetKey } =
    props;

  function mapping(row) {
    const pivot = row[groupingKey];
    const offset = +row[weekOffsetKey];

    return setGroupingKeyMapping(pivot, offset, groupingKeyMapping) === value;
  }

  return row[value] || (row.values.find(mapping) ?? {})[target];
}

function compareNumbers(a, b, direction) {
  return direction === "ASC" ? a - b : b - a;
}

function compareStrings(a, b, direction) {
  return direction === "ASC"
    ? (a ?? "").localeCompare(b)
    : (b ?? "").localeCompare(a);
}

function compareDates(a, b, direction) {
  if (direction === "ASC") {
    return new Date(a ?? date) - new Date(b ?? date);
  }

  return new Date(b ?? date) - new Date(a ?? date);
}

function filterFn(i, index, pinned) {
  if (pinned) {
    return i !== index;
  }

  return true;
}

function sortFn(props) {
  const x = getCompareValue(props.a, props);
  const y = getCompareValue(props.b, props);

  if (!isNaN(+x) && !isNaN(+y)) {
    return compareNumbers(+x, +y, props.direction);
  }

  if (!isNaN(Date.parse(x)) || !isNaN(Date.parse(y))) {
    return compareDates(x, y, props.direction);
  }

  return compareStrings(x, y, props.direction);
}

function cutPinnedRow(rows, pinnedRows = []) {
  return {
    pinned: rows.find((row) =>
      row.values.every((item) => getPinnedRows(item, pinnedRows))
    ),
    index: rows.findIndex((row) =>
      row.values.every((item) => getPinnedRows(item, pinnedRows))
    ),
  };
}

const date = "1900-01-01"; // if date is undefined then use this

export function getSortDirection(currentSortKey, newSortKey, meta, direction) {
  // If the sort key changes, determine the sort direction based on the field type.
  if (currentSortKey !== newSortKey) {
    // Get the field type for the new sort key.
    const type = getMappingType(meta?.fields, newSortKey);
    // Field types that default to ascending order.
    const types = ["string", "text"];
    // Use ascending for text fields, otherwise descending.
    return types.includes(type) ? "ASC" : "DESC";
  }

  // If the sort key is the same, toggle the sort direction.
  return direction === "ASC" ? "DESC" : "ASC";
}
