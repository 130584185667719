import { format, parse, parseISO } from "date-fns";
import {
  customFilterOptions,
  DATE_TERM_MONTH_TO_MONTH,
} from "../../../utils/constants/constants";
import { keyBy } from "lodash-es";
import { setDateFilters } from "../../../utils/formatters/dateFormatter";

function getSelectedType(selected) {
  return selected?.value || selected;
}

function getSelectedTypeOption(optionsByValue, selectedDateType) {
  return selectedDateType && optionsByValue[selectedDateType];
}

function getDefaultDateTerm(values, config, options) {
  return values[config.filterCustomTerm] ?? options[0];
}

export function setCustomDateHandler(
  isFilterModeCustom,
  dateFiltersConfig,
  setSelectedDateType,
  pageOriented,
  onDateTypeChangeHandler,
  selected
) {
  const { filterCustomTerm, filterCustomValues } = dateFiltersConfig ?? {};

  if (isFilterModeCustom && filterCustomTerm) {
    const options = toDateOptions(filterCustomValues);
    const values = keyBy(options, "value");

    const selectedDateType = getSelectedType(selected);

    const selectedDateTypeOption = getSelectedTypeOption(
      values,
      selectedDateType
    );

    const defaultTerm = getDefaultDateTerm(values, dateFiltersConfig, options);
    const { dateTypes, defaultValue, userSelected } = pageOriented ?? {};

    if (dateTypes) {
      const nextType = setTypeBasedOnPageOriented({
        dateTypes,
        filterCustomTerm,
        selectedDateType,
        values,
        defaultValue,
        userSelected,
      });

      onDateTypeChangeHandler(nextType);
      setSelectedDateType(nextType);
    } else if (selectedDateTypeOption) {
      // The currently selected date type is supported by this configuration, so keep it.
      setSelectedDateType(selectedDateType);
    } else {
      setSelectedDateType(defaultTerm);
    }
  }
}

function setTypeBasedOnPageOriented(args) {
  // general default for all pages
  const generalDefaultTerm = args.dateTypes.find(
    (type) => type === args.filterCustomTerm
  );

  const type = getDateTermByPriority(
    generalDefaultTerm,
    args.userSelected,
    args.defaultValue
  );

  // set term by priority or first available term if no term is allowed for current page
  return args.values[type] ?? args.values[args.dateTypes[0]] ?? {};
}

/* 
  1. default term for current page from site config
  2. the user selected term before go to another page
  3. general default for all pages
*/
function getDateTermByPriority(generalDefaultTerm, userSelected, defaultValue) {
  return userSelected || defaultValue || generalDefaultTerm;
}

export const toDateOptions = (
  filterCustomValues = [],
  domainFormats,
  pageOriented,
  activeTab,
  domain,
  additionalTheme
) => {
  function setOptionBasedOnDomain(option) {
    if (domainFormats && domainFormats[option.alias]) {
      return {
        value: option.value,
        label: domainFormats[option.alias].label ?? option.label,
      };
    }

    const { showDatePeriodDetails } = additionalTheme ?? {};

    function buildPeriodRange() {
      // Define the types of dates to ignore
      const ignoreDateTypes = ["rw", "rm", "rq", "ry", "cr", "sd"];

      // Return null if the current option value is in the ignored types
      if (ignoreDateTypes.includes(option.value)) {
        return null;
      }

      // Get the period range based on the domain and option value
      const { from, to } = setDateFilters(domain, option.value);

      // Format the start and end dates
      const startDate = format(parseISO(from), "MM/dd/yyyy");
      const endDate = format(parseISO(to), "MM/dd/yyyy");

      // Return the formatted period range
      return `${startDate} - ${endDate}`;
    }

    const period = showDatePeriodDetails ? buildPeriodRange() : null;

    return {
      value: option.value,
      label: option.label,
      period,
    };
  }

  // special feature for pages
  // now we have opportunity to set different date filters on each page
  function areInPageOrientedArray(option, pageDates) {
    return pageDates.dateTypes.find((date) => option.value === date);
  }

  const pageDates = (pageOriented ?? []).find(
    (po) =>
      po.pageUuids?.includes(activeTab?.uuid) || po.pageUuid === activeTab?.uuid
  );

  const orderedCustomFilterOptions = filterCustomValues.reduce((acc, curr) => {
    const option = customFilterOptions.find((option) => option.value === curr);
    if (option) {
      acc.push(option);
    }
    return acc;
  }, []);

  return orderedCustomFilterOptions
    .filter((dateOption) => filterCustomValues.includes(dateOption.value))
    .filter((dateOption) => {
      if (pageDates) {
        return areInPageOrientedArray(dateOption, pageDates);
      }

      return dateOption;
    })
    .map(setOptionBasedOnDomain)
    .filter((o) => o.value !== DATE_TERM_MONTH_TO_MONTH);
};

export function isDateBetweenCorrectPeriod(dateString) {
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  const min = parse("1900-01-01", "yyyy-MM-dd", new Date());
  const max = parse("2100-01-01", "yyyy-MM-dd", new Date());
  return date > min && date < max;
}
