import React from "react";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";

const isCypress = window.Cypress;

export default function SpringBar(props) {
  const {
    height,
    y,
    startPos,
    color,
    chartNum,
    useLines,
    skipAnimation,
    cy,
    hideZeroHeightBars,
    ...rest
  } = props;

  const parsedHeight = isNaN(height) ? 10 : height;
  const parsedY = isNaN(y) ? 10 : y;
  const parsedStartPos = isNaN(startPos) ? 0 : startPos;

  const aniProps = useSpring({
    from: {
      height: 0,
      y: parsedStartPos,
      fill: "gray",
    },
    to: {
      height: parsedHeight,
      y: parsedY,
      fill: color || "blue",
    },
  });

  if (hideZeroHeightBars && !height) {
    return null;
  }

  return useLines ? (
    <line
      x1={props.x}
      x2={props.x}
      y1={y}
      y2={y + parsedHeight}
      stroke={color}
    />
  ) : skipAnimation || isCypress ? (
    <rect
      {...rest}
      fill={color}
      height={parsedHeight}
      y={parsedY}
      data-cy={cy || "bar-section"}
    />
  ) : (
    <animated.rect
      style={aniProps}
      fill={aniProps.fill}
      {...rest}
      data-cy={cy || `bar-section`}
    />
  );
}

SpringBar.defaultProps = {
  y: 0,
  height: 0,
};

SpringBar.propTypes = {
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  startPos: PropTypes.number.isRequired,
  color: PropTypes.string,
  skipAnimation: PropTypes.bool,
  useLines: PropTypes.bool,
};
