import React from "react";
import { scaleLinear, scaleBand } from "d3-scale";
import { stack } from "d3-shape";
import YAxis from "../Axes/YAxis";
import SimpleXAxis from "../SimpleXAxis";
import BarLabels from "../BarLabels";
import { maxed } from "../../utils/func";
import SpringBar from "../GroupedBar/SpringBar";
import StackedLinesVisualization from "./StackedLinesVisualization";
import TargetLine from "../TargetLine/TargetLine";
import applyYAxisFormat from "../Axes/applyYAxisFormat";
import PercentLine from "./PercentLine";
import { useTheme } from "emotion-theming";
import { mapTooltipConfig } from "../Tooltip/mapTooltipConfig";
import { getColor } from "../BaseChart/mapColorConfig";

export default function StackedBarTwo(props) {
  const {
    data,
    width,
    height,
    colors,
    yFormat,
    yLabelFormat,
    labelFormat,
    xKeys,
    summedValues,
    setTooltip,
    yAxisFormat,
    initialData,
    useSameYAxisScale,
    lineKeys,
    circleKeys,
    negativeYKeys = [],
    yValueKeys,
    maxYScale,
    totalTrend,
    totalTrendType,
    breakdownKey,
    valueKeys,
    meta,
    xKey,
    showLabel,
  } = props;
  const theme = useTheme();
  if (!data) return null;

  const positiveKeys = yValueKeys;

  const { positiveData, negativeData } = separatePositivesAndNegatives(
    data,
    negativeYKeys,
    positiveKeys
  );

  const dataStack = stack().keys(positiveKeys);
  const negativeDataStack = stack().keys(negativeYKeys);
  const seriesA = data[0]?.second ? dataStack(data.map((d) => d.second)) : null; // ignore negative values here
  const seriesB = dataStack(positiveData.map((d) => d.first)); // Main
  const seriesBNegative = negativeDataStack(negativeData.map((d) => d.first));

  const x = scaleBand().domain(xKeys).range([0, width]).paddingInner(0.4);

  // We create an arbitrary array that would have a length of up to 2 depending on how many bar groups are used.
  // We pass this array to domain().
  const arbitraryArray = [seriesA, seriesB].filter((v) => v != null);

  const xInner = scaleBand()
    .domain(arbitraryArray)
    .range([0, x.bandwidth()])
    .paddingInner(0.4);

  const rightYAxisKeys = [...lineKeys.map((k) => k.alias), ...circleKeys]; // this needs to be configurable

  const lineAndCircleYValues = initialData.flatMap((item) =>
    rightYAxisKeys.map((key) => item[key])
  );

  // const leftAxisMaxValue = maxed(summedValues.map((s) => +s.total));
  const rightAxisMaxValue = maxed(lineAndCircleYValues);

  // left y axis scale
  // instead of zero here, we want to use the negative
  const maxPositive = findMaxSum(data, positiveKeys);
  const maxNegative = findMaxSum(data, negativeYKeys);
  // on useSameYAxisScale we need to compare both axes max values to get bigger one
  const max = useSameYAxisScale
    ? Math.max(maxPositive, rightAxisMaxValue)
    : maxPositive;

  const maxWithOverride = maxYScale ?? max * 1.1;

  const fullYScale = scaleLinear()
    .domain([-maxNegative * 1.02, maxWithOverride])
    .range([height, 0]);

  const zeroHeight = fullYScale(0);

  const negativeYScale = negativeYKeys.length
    ? scaleLinear()
        .domain([0, maxNegative * 1.02])
        .range([zeroHeight, height])
    : null;
  const yScale1 = scaleLinear()
    .domain([0, maxWithOverride])
    .range([zeroHeight, 0]);

  // right y axis scale
  const yScale2 = scaleLinear()
    .domain([0, rightAxisMaxValue])
    .range([height, 0]);

  function handleTooltip(s, v, dataIndex, xKeyValue, i, yKeys) {
    const xPos = Math.floor(
      x(xKeys[dataIndex]) + x.bandwidth() * 0.45 + xInner.bandwidth() * 0.6
    );
    const yPos = yScale1((v[0] + v[1]) / 2);

    const matchedRow = { ...s[dataIndex].data, [xKey]: xKeyValue };

    const tt = mapTooltipConfig(
      xPos,
      yPos,
      matchedRow,
      yKeys[i],
      xKey,
      breakdownKey,
      meta,
      v.data,
      breakdownKey,
      valueKeys
    );
    setTooltip(tt);
  }

  return (
    <g data-cy="stacked-bar-two-container">
      <g data-cy="grouped-bar-value-axis">
        <YAxis
          {...props}
          yScale={fullYScale}
          yTicksCount={props.yTicksCount || 5}
          yTicksColor={props.yTicksColor}
          hideYAxisLine={props.hideYAxisLine}
          hideYAxisTicks={props.hideYAxisTicks}
          yAxisGrid={props.yAxisGrid}
          yAxisGridColor={props.yAxisGridColor || theme.divider}
          width={width}
          yAxisFormat={yAxisFormat || applyYAxisFormat(yFormat)}
        />
      </g>
      <SimpleXAxis
        width={width + 15}
        height={height}
        xScale={x}
        values={xKeys}
        xKeyFormat={props.xKeyFormat} // do we still need this?
        bands={true}
        xInner={xInner}
        allTicks
      />

      {seriesB
        ? seriesB.map((s, i) =>
            s.map((v, j) =>
              isNaN(x(xKeys[j])) ? null : (
                <SpringBar
                  key={j}
                  x={x(xKeys[j]) + x.bandwidth() / 2}
                  width={xInner.bandwidth()}
                  y={yScale1(v[1])}
                  height={
                    zeroHeight - yScale1(isNaN(v[1] - v[0]) ? 0 : v[1] - v[0])
                  }
                  color={getColor(colors, positiveKeys[i], xKeys[j])}
                  startPos={height}
                  onMouseEnter={() =>
                    handleTooltip(s, v, j, xKeys[j], i, positiveKeys)
                  }
                  onMouseLeave={() => setTooltip(null)}
                  skipAnimation={seriesB[0].length > 20}
                  useLines={seriesB[0].length > 100}
                  hideZeroHeightBars
                />
              )
            )
          )
        : null}
      {seriesBNegative
        ? seriesBNegative.map((s, i) =>
            s.map((v, j) =>
              isNaN(x(xKeys[j])) ? null : (
                <SpringBar
                  key={j}
                  x={x(xKeys[j]) + x.bandwidth() / 2}
                  width={xInner.bandwidth()}
                  y={negativeYScale(0)}
                  height={negativeYScale(v[0] - v[1]) - negativeYScale(0)}
                  color={getColor(colors, negativeYKeys[i], xKeys[j])}
                  startPos={height}
                  onMouseEnter={() =>
                    handleTooltip(s, v, j, xKeys[j], i, negativeYKeys)
                  }
                  onMouseLeave={() => setTooltip(null)}
                  skipAnimation={seriesB[0]?.length > 20}
                  useLines={seriesB[0]?.length > 100}
                />
              )
            )
          )
        : null}

      {showLabel && (
        <BarLabels
          skipAnimation={seriesB[0] && seriesB[0].length > 20}
          values={summedValues}
          x={x}
          y={yScale1}
          yFormat={labelFormat || yLabelFormat || yFormat}
          xInner={xInner}
          meta={meta}
          valueKeys={valueKeys}
        />
      )}

      {negativeYKeys.length ? (
        <TargetLine
          width={width}
          y={fullYScale}
          target={0}
          thickness={1}
          color="#499bff"
        />
      ) : null}

      {totalTrend ? (
        <PercentLine
          data={data}
          y={fullYScale}
          x={x}
          totalTrendType={totalTrendType}
        />
      ) : null}

      <StackedLinesVisualization
        lineKeys={lineKeys}
        data={data}
        initialData={initialData}
        width={width}
        height={height}
        xScale={x}
        xInner={xInner}
        yScale={useSameYAxisScale ? fullYScale : yScale2}
        hideAxis={!!useSameYAxisScale}
        setTooltip={setTooltip}
        xKey={xKey}
        colors={colors}
        stackedBarAllowance
        meta={meta}
      />
    </g>
  );
}

const findMaxSum = (data, keys) => {
  if (!keys) return 0;
  let maxSum = -Infinity; // Initialize to the lowest possible value to ensure any sum will be higher

  data.forEach((item) => {
    const sum = keys.reduce((acc, key) => {
      // Add the value if key exists in the object, otherwise add 0
      let normalValue = +item.first[key];
      let allDataKey = +item.first?.allData?.[key];
      normalValue = Number.isNaN(normalValue) ? undefined : normalValue;
      allDataKey = Number.isNaN(allDataKey) ? 0 : allDataKey;

      return acc + (normalValue ?? allDataKey ?? 0);
    }, 0);

    if (sum > maxSum) {
      maxSum = sum;
    }
  });

  return maxSum;
};

const separatePositivesAndNegatives = (data, negativeYKeys, positiveKeys) => {
  return data.reduce(
    (acc, item) => {
      const positives = {};
      const negatives = {};

      negativeYKeys.forEach((key) => {
        negatives[key] = +item.first[key] * -1;
      });
      positiveKeys.forEach((key) => {
        // This doesn't seem right, but...
        const value =
          item.first[key] !== undefined && item.first[key] !== null
            ? +item.first[key]
            : +item.first?.allData?.[key];
        positives[key] = value;
      });

      if (Object.keys(positives).length > 0) {
        acc.positiveData.push({ first: positives, xKey: item.xKey });
      }
      if (Object.keys(negatives).length > 0) {
        acc.negativeData.push({ first: negatives, xKey: item.xKey });
      }

      return acc;
    },
    { positiveData: [], negativeData: [] }
  );
};
