import React from "react";
import styled from "@emotion/styled";
import TabContent from "./TabContent";
import { themeGrayIf } from "../../styles/styledSnippets";

function calculateBackground(props) {
  if (props.isFlat) return "#ffd500";
  if (props.admin) return props.theme.background.admin;
  if (props.subTabs)
    return props.subPrimary
      ? props.theme.primary
      : props.theme.background.primary;
  return "transparent";
}

const TabsContainer = styled.nav`
  display: flex;
  @media (max-width: 920px) {
    display: none;
  }

  flex-wrap: wrap;

  background: ${calculateBackground};
  ${(props) =>
    props.subTabs
      ? `
      border: 1px solid ${
        props.subPrimary ? "transparent" : themeGrayIf(500, 300)(props)
      };
    `
      : ""}
  ${(props) =>
    props.subTabs
      ? `
    div {
      margin-bottom: 0; 
    }
  `
      : ""}
`;

export default function DashboardTabs(props) {
  const { domain, admin, subTabs, subPrimary, currentTab, onChange } = props;
  const isFlat = domain === "bosco";

  return (
    <>
      <TabsContainer isFlat={isFlat} data-cy="tabs-container" admin={admin}>
        <TabContent
          list={props.list}
          isFlat={isFlat}
          current={props.current}
          baseUrl={props.baseUrl}
          currentTab={currentTab}
          onChange={onChange}
          cy="nav-tabs"
        />
      </TabsContainer>

      {subTabs?.length ? (
        <TabsContainer
          subTabs
          subPrimary={subPrimary}
          style={{
            borderRadius: 2,
          }}
        >
          <TabContent
            list={subTabs}
            isFlat={isFlat}
            current={props.current}
            baseUrl={props.baseUrl}
            sub
            subPrimary={subPrimary}
            currentTab={currentTab}
            cy="nav-sub-tabs"
          />
        </TabsContainer>
      ) : null}
    </>
  );
}
