import React from "react";
import { format as d3Format } from "d3-format";
import {
  addDays,
  format as dateFormat,
  parseISO,
  isValid,
  parse,
  format,
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { dateFormatOptions, roundConstants } from "../constants/constants";
import { findBestMatch } from "string-similarity";
import { get, isEmpty } from "lodash-es";
import ApiImage from "../../charts/TableView/Elements/ApiImage";
import { getDateWithoutTimezoneDifference } from "./dateFormatter";
import StarRating from "../../UI/StarRating/StarRating";

const Success = styled.span`
  path {
    fill: ${(props) => props.theme.notification.successMain};
  }
`;

const allowedZeroTypes = ["integer", "boolean"];

export const formatter = (
  value,
  type,
  precision = null,
  append = null,
  rounding = null,
  fiscalQuarterStartOffset = null,
  emptyOverride = ""
) => {
  if (
    !value &&
    value !== 0 &&
    value !== "" &&
    !allowedZeroTypes.find((t) => t === type)
  )
    return "--";

  if ((type || "").includes("json:")) {
    return getNestedJsonValue(value, type, emptyOverride);
  }

  const isD3Type = type && (type.includes(".") || type.includes("~"));

  if (isD3Type) {
    const hasReplacementChar = type.includes("-");
    const replacedString = hasReplacementChar ? type.split("-")[0] : type;
    const formatString =
      value[0] < 1 ? replacedString.replace("s", "f") : replacedString;
    const formatted = d3Format(formatString)(value).replace("G", "B");
    return hasReplacementChar ? formatted.replace("$", "£") : formatted;
  }

  // date fns format type
  const dateValue = parseAsDate(value);
  const isValidDate = Date.parse(value);

  const { bestMatch } = findBestMatch(
    typeof type === "string" ? type : "",
    dateFnsFormats
  );
  if (!isNaN(dateValue) && bestMatch.rating > 0.8) {
    return dateFormat(dateValue, bestMatch.target);
  }

  if (!isNaN(isValidDate) && bestMatch.rating > 0.8) {
    return dateFormat(
      getDateWithoutTimezoneDifference(value),
      bestMatch.target
    );
  }

  const convertedType = type && type.includes("%") ? "percent" : type;

  switch (convertedType) {
    case "percent-sig":
      if (value < 1) {
        return d3Format(".0%")(value);
      } else {
        return d3Format(`.2s`)(value * 100) + "%";
      }

    case "percent-round": {
      return (+value).toFixed(0) + "%";
    }

    case "percent-whole":
      return (+value).toFixed(1) + "%";

    case "percent-whole-2":
      return (+value).toFixed(2) + "%";

    case "percent-tenth":
      return d3Format(".1%")(value);

    case "percent-hundredth":
      return d3Format(".2%")(value);

    case "percent":
      if (isNaN(value)) return "--";
      if (append) return (value * 100).toFixed(1) + append;
      return d3Format(`,.${Number.isInteger(precision) ? precision : 2}%`)(
        value
      );

    case "currency-round":
      return d3Format("$.2s")(value);

    case "currency":
      if (rounding) {
        if (rounding === "M") {
          return "$" + (value / 1000000).toFixed(1) + "M";
        } else {
          return "$" + (value / 1000).toFixed(1) + "k";
        }
      }
      return d3Format("$,.2f")(value);

    case "currency-tenth":
      if (rounding) {
        if (rounding === "M") {
          return "$" + (value / 1000000).toFixed(1) + "M";
        } else {
          return "$" + (value / 1000).toFixed(1) + "k";
        }
      }
      return d3Format("$,.1f")(value);

    case "currency-whole":
      if (rounding) {
        if (rounding === "M") {
          return "$" + (value / 1000000).toFixed(1) + "M";
        } else {
          return "$" + (value / 1000).toFixed(1) + "k";
        }
      }
      return d3Format("$,.0f")(value);

    case "number": {
      return d3Format(",")(value);
    }

    case "decimal": {
      return d3Format(",.2f")(value);
    }

    case "decimal-tenth": {
      //@todo Farhod, am I missing something here?
      // return Math.round(+value * 10) / 10;
      return d3Format(",.1f")(value);
    }

    case "pretty-decimal": {
      return +value.toString().slice(0, value.toString().indexOf(".") + 3);
    }

    case "date": {
      if (value === 0 || value === null) {
        return "--";
      }

      const d = dateValue;
      if (isNaN(d) || d === null) {
        return value;
      } else {
        return dateFormat(d, "MMMM d, yyyy");
      }
    }

    case "month": {
      return formatAsDate("MMMM");
    }

    case "month-year": {
      return formatAsDate("MMM yyyy");
    }

    case "datetime": {
      return formatAsDate("M/d/yyyy hh:mm a");
    }
    case "date-short":
      return formatAsDate("M/d/yyyy");

    case "stars":
      return <StarRating value={value} />;

    case "stars-and-values":
      return <StarRating value={value} showValue />;

    case "date-micro":
      return formatAsDate("M/d/yy");

    case "date-mini":
      return formatAsDate("M/yy");

    case "date-month": {
      if (dateValue) {
        return formatAsDate("MMM yyyy");
      }

      return null;
    }

    case "date-iso": {
      return formatAsDate("yyyy-MM-dd");
    }

    /* deprecated */
    case "date-week-start":
    case "date-week": {
      if (typeof value !== "string") {
        return value;
      }

      const bits = value.split(" ");
      const fromDate = dateFromWeek(+bits[1].substring(1), bits[0]);
      const toDate = addDays(fromDate, 7);

      if (fromDate instanceof Date && toDate instanceof Date) {
        const toDateString =
          convertedType === "date-week"
            ? " - " + dateFormat(toDate, "M/d/yyyy")
            : "";
        return dateFormat(fromDate, "M/d/yyyy") + toDateString;
      }
      return null;
    }

    case "minutesToHours":
      return d3Format(",.1f")(value / 60);

    case "boolean":
      return +value ? (
        <Success>
          <FontAwesomeIcon icon={["fas", "check"]} />
        </Success>
      ) : (
        ""
      );
    case "integer": {
      if (value !== 0 && !value) {
        return "--";
      }
      return d3Format(",.0f")(value);
    }

    case "two-fixed": {
      if (value !== 0 && !value) {
        return "--";
      }
      return d3Format(",.2f")(+value);
    }

    case "rounded":
      return d3Format(".2s")(value);

    case "hourOfDay":
      return getHour(+value);

    case "hourOfDayRange":
      if (isNaN(+value)) {
        return value;
      }
      return getHourRange(+value);

    case "daily":
      if (!isNaN(value)) return value;
      return dateFormat(dateValue, "M/d/yyyy");

    case "monthly": {
      if (!isNaN(value) && isValid(value)) {
        return dateFormat(dateFromMonth(value), "MMM");
      }

      const splits = value.split(" M");
      if (!splits[1]) {
        return value;
      }
      const [year, month] = splits;
      return dateFormat(dateFromMonth(month, year), "MMM yyyy");
    }

    case "weekly": {
      if (!isNaN(value)) return value;
      return dateFormat(dateValue, "M/d/yyyy");
    }

    case "mixedMonthsQuarters": {
      const [year, month] = value.split(" M");
      if (Number.isInteger(+month)) {
        return dateFormat(dateFromMonth(month, year), "MMMM");
      }

      return value;
    }

    case "day-of-week": {
      return formatAsDate("EEEE");
    }

    case "week-number": {
      if (isValid(dateValue)) {
        return dateFormat(dateValue, "I");
      }

      return value;
    }

    case "quarter-to-fiscal": {
      const value = format(dateValue, quarterAggregationFormat);
      if (fiscalQuarterStartOffset) {
        return setFiscalQuearterOffset(value, fiscalQuarterStartOffset);
      }
      return value;
    }
    case "WEEK_RANGE_TO_US_DATE_SHORT": {
      return value
        .split(" - ")
        .map((key) =>
          new Date(key).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
          })
        )
        .join("-");
    }

    case "string":
    case "string-right":
      return value || emptyOverride;

    case "api-image":
      if (!value || value === "Total" || typeof value !== "string") {
        return value;
      }

      return <ApiImage value={value} />;

    case "month-day":
      if (dateValue && isValidDate) {
        return format(dateValue, "MM/dd");
      }

      return value;

    default:
      if (isValidDate) {
        return value.toString();
      }

      return value ?? "--";
  }

  function formatAsDate(format) {
    if (isNaN(dateValue)) {
      return value;
    } else {
      return dateFormat(dateValue, format);
    }
  }
};

export function parseAsDate(value) {
  if (!value) return value;
  let ret;

  try {
    if (isValid((ret = parseISO(value)))) {
      return ret;
    }
  } catch (e) {}

  if (isValid((ret = new Date(value)))) {
    return ret;
  }

  value = String(value);

  const currentDate = new Date();

  if (isValid((ret = parse(value, "yyyy 'M'MM", currentDate)))) {
    return ret;
  }

  if (isValid((ret = parse(value, quarterAggregationFormat, currentDate)))) {
    return ret;
  }

  if (isValid((ret = parse(value, "RRRR 'W'II", currentDate)))) {
    return ret;
  }

  if (isValid((ret = parse(value, "yyyy", currentDate)))) {
    return ret;
  }

  if (isValid((ret = parse(value, "yyyyMM", currentDate)))) {
    return ret;
  }

  return ret;
}

const quarterAggregationFormat = "yyyy 'Q'Q";

export const percent = (value, precision) =>
  formatter(value, "percent", precision);
export const currency = (value) => formatter(value, "currency-round");

export const labelFormatter = (num, format) => {
  const isDate = format?.includes("yy");
  if (isDate) return dateFormat(absoluteDate(num), format);

  const topValue = roundConstants[format?.replace("$", "")];

  if (format && !topValue) {
    return d3Format(format)(num);
  }

  const currency = format?.includes("$") ? "$" : "";

  if (num >= topValue) {
    const val = d3Format("~s")(num).replace("G", "B");
    const letter = val.charAt(val.length - 1);
    const number = Number(val.slice(0, -1)).toFixed(0);

    return `${currency}${number}${letter}`;
  }

  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e7, symbol: "M" },
  ];

  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  const [beforeDot, afterDot] = d3Format(`${currency}.21~s`)(num).split(".");
  const tenth = afterDot?.slice(0, 1);

  return `${beforeDot?.replace(/[a-zA-Z]+/g, "") || 0}.${tenth || 0}${
    si[i].symbol
  }`;
};

const getAmOrPm = (h) => (h % 24 >= 12 ? "pm" : "am");
const getHour = (h) => ((h + 11) % 12) + 1 + getAmOrPm(h);

const getHourRange = (h) => {
  return getHour(h) + " - " + getHour(h + 1);
};

const absoluteDate = (str) => {
  if (!str) return null;
  const s = str.substr(0, 10);
  return parse(s, "yyyy-MM-dd", new Date());
};

const dateFromMonth = (month, year = 2020) => {
  const yearDate = parse(year, "yyyy", new Date());
  return parse(String(+month), "M", yearDate);
};

const dateFromWeek = (week, year) => {
  if (!week || !year) return null;

  const yearDate = new Date(year, 1, 1);
  return parse(String(+week), "I", yearDate);
};

export const setFiscalQuearterOffset = (quarter, offset) => {
  const [year, number] = quarter.split(" Q");

  if (!year || !number) {
    return quarter;
  }

  const math = (+number + offset) % 5;
  const newQuarter = math < 2 ? math + 1 : math;
  const newYear = math < 2 ? +year + 1 : year;

  return `${newYear} Q${newQuarter}`;
};

export default formatter;

const dateFnsFormats = [
  "MM-dd-yyyy HH:mm:ss",
  "MM/dd/yyyy HH:mm:ss",
  "MM-dd-yyyy",
  "MM/dd/yyyy",
  "MMM yyyy",
  "MMM-yyyy",
  // since we using this options in chart builder then lets use them also for date fns formatting in formatter.js
  ...dateFormatOptions
    .map((item) => item.value)
    .filter((format) => format !== "date-week"),
];

export function getNestedJsonValue(value, type, emptyOverride) {
  const jsonKey = (type || "").split("json:")[1];
  const correctValue = getCorrectValue(value);
  const object = JSON.parse(correctValue);
  const result = get(object, jsonKey);

  if (!result || isEmpty(result)) {
    return emptyOverride;
  }

  return result;
}

const getCorrectValue = (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return "{}";
  }

  return value;
};
