import { getDataReducer } from "./getDataReducer";

export default function (
  names,
  mappedWithTotals,
  allXKeys,
  sortedData,
  mappedXKey,
  yValueKeys,
  breakdownKey,
  yKeys,
  negativeYKeys
) {
  const keys =
    yKeys ||
    names ||
    mappedWithTotals
      .filter((m) => yValueKeys.find((k) => k === m.segmentKey))
      .map((a) => a.segmentKey)
      .filter((a) => a); // ['LocationA', 'LocationB', 'LocationC']

  const convertedArray = allXKeys.reduce((acc, curr) => {
    const atDate = sortedData.filter((d) => d[mappedXKey] === curr);

    const obj = {
      first: atDate.reduce(
        getDataReducer(yValueKeys && yValueKeys[0], breakdownKey, yKeys),
        {}
      ),
      xKey: curr,
    };
    if (yValueKeys && yValueKeys[1]) {
      obj.second = atDate.reduce(
        getDataReducer(yValueKeys[1], breakdownKey, null),
        {}
      );
    }
    return [...acc, obj];
  }, []);

  // totals for yScale and labels
  const totals = allXKeys.map((k) => ({
    x: k,
    total: getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys),
    labelTotal: getTotals(
      k,
      allXKeys,
      sortedData,
      mappedXKey,
      yValueKeys,
      yKeys
    ),
  }));

  return { keys, convertedArray, totals };
}

function getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys) {
  const totalKeys = yKeys ?? yValueKeys;

  const row = sortedData.find((d) => d[mappedXKey] === k);
  if (!row) {
    console.warn(`No row found for key: ${k}`);
    return 0;
  }

  return totalKeys.reduce((acc, curr) => {
    const value = +row[curr];
    return acc + (isNaN(value) ? 0 : value);
  }, 0);
}
