export default function (valueKey, valueKeys, groupBy, labelKey, groupKey) {
  if (groupBy && valueKeys?.length === 1) {
    return {
      valueKey: valueKeys[0].alias,
      valueKeys: null,
      labelKey: undefined,
    };
  }
  const nextLabelKey = labelKey || groupBy;
  const nextValueKeys = valueKeys && valueKeys.map((v) => v.alias);
  return { valueKey, valueKeys: nextValueKeys, labelKey: nextLabelKey };
}
