import React, { useEffect, useRef } from "react";
import "d3-transition";
import { select } from "d3-selection";
import { interpolate } from "d3-interpolate";
import usePrevious from "../../utils/usePrevious";
import isEqual from "react-fast-compare";
import styled from "@emotion/styled";
import getVisualizationLabel, {
  mapping,
} from "../../utils/getVisualizationLabel";

const Path = styled.path(
  ({ theme, outline, fill }) => `
    stroke: ${outline ? theme.divider : "none"};
    stroke-width: 3px;
    fill: ${fill};
`
);

export default function Arc(props) {
  const {
    data,
    index,
    createArc,
    colors,
    delay = 0,
    width,
    outline,
    setTooltip,
    meta,
    valueKey,
    tipArc,
    disableTooltip,
    includeCategoryInTooltip,
  } = props;
  const pathRef = useRef(null);
  const previousData = usePrevious(data);

  const strictColor = data?.data?.color;

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      select(pathRef.current)
        .attr("d", createArc(yo))
        .transition()
        .duration(500)
        .delay(delay)
        .attrTween("d", arcTween);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    select(pathRef.current).attr("d", createArc(data));
  }, [createArc, data, width]);

  const handleMouseover = (e) => {
    if (!tipArc || disableTooltip) return null;
    const { x, y } = pathRef.current.ownerSVGElement.getBoundingClientRect();

    const tooltip = [
      {
        label: includeCategoryInTooltip
          ? data.data.name
          : getVisualizationLabel(meta.fields, valueKey),
        value: data.percentValue ?? data.value,
        type: mapping(meta.fields, valueKey)?.type ?? "currency",
        name: data.data.name,
      },
    ];

    setTooltip({
      x: e.clientX - x - 20,
      y: e.clientY - y,
      skipAnimation: true,
      tooltip,
    });
  };

  function arcTween() {
    const i = interpolate(yo, data);
    return (t) => createArc(i(t));
  }

  // olors[index]?.color for pie visualization and colors[index] for gauge vis
  const fill = colors[index]?.color ?? colors[index];

  return (
    <Path
      ref={pathRef}
      data-pie="slice"
      data-cy={`pie-slice-${data.data.name}`}
      fill={fill}
      outline={outline}
      onMouseMove={handleMouseover}
      onMouseLeave={() => setTooltip && setTooltip(null)}
    />
  );
}

const yo = {
  startAngle: 0,
  endAngle: 0.1,
  padAngle: 0,
};
