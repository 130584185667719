import Tippy from "@tippyjs/react";
import styled from "@emotion/styled";

// freeze left column is not working properly when wrapped by tooltip
// this make sticky container wrapper
const Container = styled.div(
  ({ freeze, freezeNextColumn, freezeWidth }) => `
  ${
    freeze &&
    `
      position: sticky;
      left: ${freezeNextColumn ? freezeWidth : 0}px;
      z-index: 2;
    `
  }
`
);

export default function TippyTooltipWrapper({
  children,
  content,
  freeze,
  freezeNextColumn,
  freezeWidth,
  ...rest
}) {
  return (
    <Tippy
      placement="top"
      animation="scale"
      arrow={true}
      theme="material"
      // Need to ensure it can be tabbed to directly after with no clipping issues
      appendTo={document.body}
      content={content}
      {...rest}
    >
      <Container
        freeze={freeze}
        freezeNextColumn={freezeNextColumn}
        freezeWidth={freezeWidth}
      >
        {children}
      </Container>
    </Tippy>
  );
}
