import { isEqual } from "lodash-es";
import * as actionTypes from "../actions/actionTypes";
import {
  UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET,
  BULK_UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET,
} from "../actions/actionTypes";
import produce from "immer";
import { setDataUniqueRowUuid } from "./layoutReducer";
import { performBulkUpdateOnLocalData } from "../../utils/activeTable";

const initialState = {
  tables: [],
  errorMessage: null,
  currentTable: null,
  currentViewUuid: null,
  data: null,
  dataError: null,
  dataErrorDetail: null,
  loadedActiveTable: null,
  queryFields: null,
  activeTableBlock: null,
  activeTableDataLoading: true,
  activeTableUpdateLoading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_ACTIVE_TABLE_START: {
      return { ...state, errorMessage: null };
    }

    case actionTypes.CREATE_NEW_ACTIVE_TABLE_SUCCESS: {
      return {
        ...state,
        currentTable: action.results.data,
        currentViewUuid: null,
        errorMessage: null,
      };
    }

    case actionTypes.UPDATE_ACTIVE_TABLE_SUCCESS: {
      const res = action.results.data;

      return {
        ...state,
        activeTableUpdateLoading: false,
        currentTable: {
          ...res,
          views: res.views.map((view) => ({
            ...view,
            displaySettings: JSON.parse(view.displaySettings ?? "{}"),
          })),
        },
      };
    }

    case actionTypes.UPDATE_ACTIVE_TABLE_START:
      return {
        ...state,
        activeTableUpdateLoading: true,
      };

    case actionTypes.UPDATE_ACTIVE_TABLE_COLUMN_SUCCESS: {
      const res = action.activeTable.data.data;
      const queryFields = action.query.data.data.dataSources[0].fields;

      return {
        ...state,
        activeTableUpdateLoading: false,
        currentTable: {
          ...res,
          views: res.views.map((view) => ({
            ...view,
            displaySettings: JSON.parse(view.displaySettings ?? "{}"),
          })),
        },
        queryFields,
      };
    }

    case actionTypes.CREATE_NEW_ACTIVE_TABLE_FAIL: {
      return {
        ...state,
        loadedActiveTable: false,
        errorMessage: action.error.message,
      };
    }
    case actionTypes.CREATE_NEW_ACTIVE_TABLE_UPDATE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case actionTypes.LOAD_ACTIVE_TABLES_SUCCESS: {
      return { ...state, tables: action.results?.data ?? [] };
    }
    case actionTypes.GET_ACTIVE_TABLE_START: {
      return { ...state, loadedActiveTable: null };
    }

    case actionTypes.GET_ACTIVE_TABLE_SUCCESS: {
      const queryFields = action.query.dataSources
        .map((datasource) => datasource.fields)
        .flat();
      const res = action.activeTable;
      return {
        ...state,
        currentTable: {
          ...res,
          views: res.views.map((view) => ({
            ...view,
            displaySettings: parseJsonString(view.displaySettings),
          })),
        },
        currentViewUuid: null,
        loadedActiveTable: res.uuid,
        queryFields,
      };
    }

    case actionTypes.GET_ACTIVE_TABLE_DATA_START:
      return {
        ...state,
        activeTableDataLoading: true,
      };

    case actionTypes.GET_ACTIVE_TABLE_DATA_FAIL:
    case actionTypes.UPDATE_AT_VIEW_FAIL:
    case actionTypes.ADD_ACTIVE_TABLE_ROW_FAIL:
    case actionTypes.UPDATE_ACTIVE_TABLE_ROW_FAIL:
    case actionTypes.SAVE_AT_VIEW_FAIL:
    case actionTypes.UPDATE_ACTIVE_TABLE_FAIL:
      return {
        ...state,
        activeTableDataLoading: false,
        activeTableUpdateLoading: false,
        dataError: action.error?.message,
        dataErrorDetail: getErrorDetails(action.error),
      };

    case actionTypes.GET_ACTIVE_TABLE_DATA_SUCCESS: {
      const convertedData = transformRows(action.results.data, state);

      return {
        ...state,
        data: setDataUniqueRowUuid(convertedData),
        activeTableDataLoading: false,
      };
    }
    case actionTypes.UPDATE_ACTIVE_TABLE_ROW_SUCCESS: {
      const payload = action.results.data;
      const { changedFieldName, uniqueRowUuid } = action;

      function mergeRow(currentRow, updatedRow) {
        return Object.keys(currentRow).reduce((acc, key) => {
          if (changedFieldName === key) {
            return { ...acc, [key]: updatedRow[key] };
          }

          return { ...acc, [key]: updatedRow[key] ?? currentRow[key] };
        }, {});
      }

      const nextData = produce(state.data, (draft) => {
        const itemIndex = draft.findIndex(
          (item) => item.uniqueRowUuid === uniqueRowUuid
        );

        if (itemIndex > -1) {
          // If the element was found, update it with the new payload data
          draft[itemIndex] = mergeRow(draft[itemIndex], payload);
        }
      });

      return {
        ...state,
        data: nextData,
        dataError: null,
        dataErrorDetail: null,
      };
    }
    case UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET: {
      const updatedRows = action.payload;

      return produce(state, (draft) => {
        const data = draft.data;
        for (const row of data) {
          for (const updatedRow of transformRows(updatedRows, state)) {
            if (row.uuid === updatedRow.uuid) {
              Object.assign(row, updatedRow);
            }
          }
        }
        return draft;
      });
    }
    case BULK_UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET: {
      return produce(state, (draft) => {
        draft.data = performBulkUpdateOnLocalData(draft.data, action.payload);
      });
    }

    case "CLEAR_ACTIVE_TABLE_DATA_MESSAGE": {
      return { ...state, dataError: null, dataErrorDetail: null };
    }

    case actionTypes.UPDATE_AT_VIEW_SUCCESS: {
      return produce(state, (draftState) => {
        const viewIndex = draftState.currentTable.views.findIndex(
          (v) => v.uuid === action.results.data.uuid
        );

        const res = action.results.data;
        const displaySettings = JSON.parse(res.displaySettings ?? "{}");

        draftState.currentTable.views[viewIndex] = {
          ...res,
          displaySettings,
        };

        // drop isDefaultView from other view if current is true
        if (displaySettings.isDefaultView) {
          draftState.currentTable.views = draftState.currentTable.views.map(
            (view, index) => {
              if (index === viewIndex) {
                return view;
              }

              return {
                ...view,
                displaySettings: {
                  ...(displaySettings ?? {}),
                  isDefaultView: false,
                },
              };
            }
          );
        }
      });
    }

    case actionTypes.SAVE_AT_VIEW_SUCCESS: {
      return produce(state, (draftState) => {
        const res = action.results.data;
        draftState.currentTable.views.push({
          ...res,
          displaySettings: JSON.parse(res.displaySettings ?? "{}"),
        });
      });
    }

    // case actionTypes.GET_QUERY_SUCCESS: {
    //   const queryFields = action.results.data.dataSources
    //     .map((datasource) => datasource.fields)
    //     .flat();
    //   return { ...state, queryFields };
    // }

    case actionTypes.INITIALIZE_ACTIVE_TABLE_SUCCESS: {
      return { ...state, activeTableBlock: action.results.data };
    }

    case actionTypes.ADD_ACTIVE_TABLE_ROW_SUCCESS:
      const { data: row } = action.results;
      const { key, apiImageColumn, value } = action;

      // hack for api-image create when updating different column
      function getActualApiImageData(response, stateItem, name) {
        const actual = response[name] ?? stateItem[apiImageColumn];
        return actual ? { [apiImageColumn]: actual } : {};
      }

      // for joined mode
      if (key && value) {
        const nextData = state.data.map((item) =>
          item[key] === value
            ? {
                ...item,
                ...row,
                ...getActualApiImageData(row, item, apiImageColumn),
              }
            : item
        );

        return {
          ...state,
          data: nextData,
        };
      }

      return {
        ...state,
        data: [row, ...state.data],
      };

    case actionTypes.CLEAR_ACTIVE_TABLE:
      return {
        ...state,
        currentTable: null,
        currentViewUuid: null,
        data: null,
        dataError: null,
        dataErrorDetail: null,
        loadedActiveTable: null,
        activeTableBlock: null,
        activeTableDataLoading: true,
      };

    case actionTypes.SET_ACTIVE_TABLE_CURRENT_VIEW_UUID: {
      return {
        ...state,
        currentViewUuid: action.payload,
      };
    }

    default:
      return state;
  }
};

function parseJsonString(jsonString) {
  if (!jsonString || isEqual(jsonString, {})) {
    return {};
  }

  return JSON.parse(jsonString);
}

function getErrorDetails(error) {
  return (
    error.response?.data?.errors ??
    error?.errors ??
    error?.data ?? { Error: [error?.message] }
  );
}

function transformRows(rows, state) {
  const booleans = (state.currentTable?.columns ?? [])
    .filter((col) => col.type === "boolean")
    .map((b) => b.name);

  return rows.map((d) => {
    return booleans.reduce(
      (acc, curr) => {
        return { ...acc, [curr]: d[curr] === null ? null : !!+d[curr] };
      },
      { ...d }
    );
  });
}
