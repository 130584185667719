import GroupedBar from "./GroupedBar/GroupedBar";
import {
  groupByKey,
  pipe,
  assignAsKey,
  assignAsKeys,
  unique,
  mapToKey,
  doIf,
  makePercent,
  convertNumbersToBoolean,
  getWithOrOutNegatives,
  getDynamicData,
  filterOutNullValues,
} from "../utils/func";
import Legend from "./Legend/Legend";
import VisualizationBase from "./BaseChart/VisualizationBase";
import Details from "../Layout/Details/Details";
import xKeyParser from "../utils/charts/xKeyParser";
import { sortByKey } from "../utils/dates/dateSorting";
import { reportDeprecated } from "../utils/errorHandling";
import conformGroupedBar from "./GroupedBar/conformGroupedBar";
import mapColorConfig from "./BaseChart/mapColorConfig";

export default function MultiGroupedBarVisualization(props) {
  const { chart, details, dashboardId, term } = props;
  const {
    data: propsData,
    yKey: propsYKey,
    yKeys: propsYKeys,
    xKey,
    groupBy: _groupBy,
    groupByFormat,
    groupByTerm,
    seriesItemKey,
    xFormat,
    xDateFromMonth,
    booleanValues, // what is this?
    percentLabel,
    xSort,
    ignoreDateTerm,
    yAxisFormat,
    seriesSorting,
    hideDetailLink,
    xQuarterFromDate,
    withNegative,
    hideLegend,
    title,
    titleColor,
    showLabel,
    xAxisFormat,
    legendItems,
    fiscalQuarterStartOffset,
    legendTooltips,
    dynamicSortConfig,
    fixedSeriesSorting,
    allTicks,
    forceRotateTicks,
    tooltipConfig,
    totalBar,
    legendHeight,
    relativeY,
    valueKeys: propsValueKeys,
    maxYScale,
    xKeyFormat,
    forceMultiColor,
    useSameYAxisScale,
    colors,
  } = chart;

  const margin = {
    top: 30,
    left: 46,
    bottom: 62,
    right: useSameYAxisScale ? 20 : 50,
  };

  let propsGroupBy = _groupBy;
  if (seriesItemKey) {
    propsGroupBy = seriesItemKey;
    reportDeprecated(
      `"seriesItemKey" is deprecated for MultiBar. Use "groupBy" instead.`
    );
  }

  const { data, yKey, yKeys, groupBy, lineKeys, valueKeys, negativeYKeys } =
    conformGroupedBar(
      propsData,
      propsYKey,
      propsYKeys,
      propsValueKeys,
      propsGroupBy,
      xKey
    );

  const mappedXKey = xKeyParser(term, xKey, ignoreDateTerm || groupByTerm);

  const nonNullItems = filterOutNullValues(data, yKey, yKeys);

  const withOrOutNegatives = getWithOrOutNegatives(
    withNegative,
    nonNullItems,
    yKey
  );

  const dynamicData = getDynamicData(
    dynamicSortConfig,
    withOrOutNegatives,
    yKey
  );

  const converted = convertNumbersToBoolean(
    dynamicData,
    groupBy,
    booleanValues
  );

  const seriesItemKeys = booleanValues
    ? [true, false]
    : fixedSeriesSorting
    ? fixedSeriesSorting
    : pipe(
        mapToKey(groupBy, groupByTerm, term),
        doIf(seriesSorting, sortByKey(seriesSorting)),
        unique
      )(converted);

  const allXKeys = unique(propsData.map((d) => d[mappedXKey]));

  const grouped = pipe(
    yKeys ? assignAsKeys(yKeys, "value") : assignAsKey(yKey, "value"),
    groupByKey(mappedXKey)
  )(converted);

  if (booleanValues) {
    grouped.forEach((v) => {
      v.values = v.values.filter(
        (v) => v[groupBy] === true || v[groupBy] === false
      );
      v.values.sort((a, b) => b[groupBy] - a[groupBy]);
    });
  }

  if (xSort) {
    grouped.sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0));
    allXKeys.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  }

  if (percentLabel) {
    grouped.forEach((v) => {
      if (v.values[0] && v.values[1]) {
        v.label = makePercent(
          v.values[0].value,
          v.values[1].value + v.values[0].value
        );
      }
    });
  }

  function calcTotalBarValue(item) {
    return item.values.reduce((acc, curr) => (acc += curr[yKey]), 0);
  }

  function addTotalBar(item) {
    return {
      ...item,
      values: [
        ...item.values,
        {
          [yKey]: calcTotalBarValue(item),
          [groupBy]: totalBar,
          [xKey]: item.key,
          value: calcTotalBarValue(item),
        },
      ],
    };
  }

  const withTotal = grouped.map((item) => {
    const filteredValues = item.values.filter((v) => v.value);
    const nextItem = { ...item, values: filteredValues };
    return totalBar ? addTotalBar(nextItem) : nextItem;
  });

  const series = totalBar ? [...seriesItemKeys, totalBar] : seriesItemKeys;

  const singleBarColorMode = yKeys.length === 1 && !groupBy && forceMultiColor;

  const colorKeys = [...yKeys, ...lineKeys.map((l) => l.alias)];
  if (!groupBy) {
    colorKeys.sort((a, b) => {
      // Find the indices of `a` and `b` in `valueKeys` based on the `alias` property
      const indexA = (valueKeys ?? []).findIndex((item) => item.alias === a);
      const indexB = (valueKeys ?? []).findIndex((item) => item.alias === b);

      return indexA - indexB; // Sort based on their indices
    });
  }

  const colorArray = mapColorConfig(
    colorKeys,
    legendItems,
    singleBarColorMode,
    allXKeys,
    colors
  );

  const allValues = colorArray
    .map((colorItem) => colorItem.alias) // Extract aliases
    .flatMap((alias) => data.map((item) => item[alias] ?? null)) // Get values for each alias
    .filter((v) => v !== null); // Filter out null or undefined values

  const maxMinValues = {
    max: Math.max(...allValues),
    min: Math.min(...allValues),
  };

  return (
    <>
      {title ? (
        <div
          style={{
            float: "left",
            fontSize: 16,
            fontWeight: 500,
            color: titleColor || null,
          }}
        >
          {title}
        </div>
      ) : null}
      <div id={chart.visualizationId} style={{ position: "relative" }}>
        <VisualizationBase {...{ ...props, margin, tooltipConfig }}>
          <GroupedBar
            {...chart}
            xKey={chart.xKey}
            yKey={yKey}
            yKeys={yKeys}
            xType={chart.xType}
            allXKeys={allXKeys}
            groupBy={groupBy}
            propsGroupBy={_groupBy}
            seriesItemKeys={series}
            data={withTotal}
            colors={colorArray}
            xFormat={xFormat}
            yAxisFormat={yAxisFormat}
            term={term}
            xDateFromMonth={xDateFromMonth}
            meta={chart.meta}
            dateKey={chart.dateKey}
            xQuarterFromDate={xQuarterFromDate}
            withNegative={withNegative}
            showLabel={showLabel}
            xAxisFormat={xAxisFormat}
            fiscalQuarterStartOffset={fiscalQuarterStartOffset}
            allTicks={allTicks}
            forceRotateTicks={forceRotateTicks}
            relativeY={relativeY}
            lineKeys={lineKeys}
            rawData={propsData}
            valueKeys={valueKeys}
            maxYScale={maxYScale}
            groupByMode={propsGroupBy}
            xKeyFormat={xKeyFormat ?? xAxisFormat}
            maxMinValues={maxMinValues}
            negativeYKeys={negativeYKeys}
          />

          <Legend
            highTop
            foreignObject
            colors={colorArray}
            sections={colorKeys}
            horizontal
            wrapping
            hide={hideLegend || singleBarColorMode}
            legendTooltips={legendTooltips}
            groupByFormat={groupByFormat}
            term={term}
            ignoreDateTerm={ignoreDateTerm}
            meta={chart.meta}
            legendHeight={legendHeight}
          />
        </VisualizationBase>
      </div>

      {details && chart.data.length > 0 && (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
          hideDetailLink={hideDetailLink}
          chart={chart}
        />
      )}
    </>
  );
}
