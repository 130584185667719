import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import Select from "../../../UI/Form/Select/Select";
import styled from "@emotion/styled";
import { FilterDisplayLabel } from "../../../Layout/Filters/GeneralFilters";
import { MAX_ITEMS } from "./useActiveTableDropdown";

//noinspection CssUnusedSymbol
const Container = styled.div`
  line-height: normal;

  .option--is-disabled,
  .list__option--is-disabled {
    cursor: not-allowed;
  }
`;

/**
 * Custom Active Table Dropdown, allowing for loading possible
 * values from query field.
 *
 * @see import('./useActiveTableDropdown.js').default
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function ActiveTableDropdown(props) {
  const queryUuid = props.loadFromQueryUuid;
  const fieldName = props.loadFromFieldName;
  const loadValues = props.loadValues;
  const loadValuesSearch = props.loadValuesSearch;
  const [options, setOptions] = useState(null);

  const [loading, setLoading] = useState(false);
  const selectRef = useRef(null);

  // This allows for being able to close the menu on blur when defaultMenuIsOpen
  useLayoutEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadValues(queryUuid, fieldName)
      .then(setOptions)
      .finally(() => setLoading(false));
  }, [fieldName, loadValues, queryUuid]);

  const value = useMemo(() => {
    if (props.value === undefined) {
      return null;
    }
    return (
      options?.find((o) => o.value === props.value) ?? {
        value: props.value,
        label: FilterDisplayLabel({
          label: props.value,
          italicizeSpecial: true,
        }),
      }
    );
  }, [options, props]);

  const serverSideFiltering = MAX_ITEMS < options?.length;

  return (
    <Container style={{ width: "100%" }}>
      <Select
        async
        isLoading={loading}
        loadOptions={(search, callback) => {
          return serverSideFiltering
            ? loadValuesSearch(queryUuid, fieldName, search).then(callback)
            : callback(options);
        }}
        options={options ?? []}
        defaultOptions={options ?? []}
        defaultMenuIsOpen
        ref={selectRef}
        value={value}
        onChange={({ value }) => {
          props.onValueChange(value);
          props.stopEditing();
        }}
        isOptionDisabled={(option) => option.disabled}
        filterOption={(option, search) => {
          const searchLower = search.toLowerCase();

          return (
            String(option.data.value).toLowerCase().includes(searchLower) ||
            String(option.data.search).toLowerCase().includes(searchLower)
          );
        }}
        onBlur={props.stopEditing}
      ></Select>
    </Container>
  );
}
