import * as actionTypes from "../actions/actionTypes";
import {
  CHANGE_THEME_CONFIGURATION_SUCCESS,
  CACHE_MENU_FILTER_CHECKED_VALUES,
} from "../actions/actionTypes";
import { doMerge } from "../../utils/dataOptionsValues";
import produce from "immer";
import { findMaxIndex } from "../../utils/termConfig";
import {
  buildFilters,
  fromToDateFormatter,
  setDateFilters,
  setSpecificTimezone,
} from "../../utils/formatters/dateFormatter";
import {
  customFilterOptions,
  DATE_FILTER_MODE_CUSTOM,
  DATE_FILTER_MODE_NONE,
  DATE_TERM_MONTH_TO_MONTH,
  filterTypes,
  FILTER_TYPE_DATE_RANGE,
  hardCodedFilterLabels,
  FILTER_TYPE_SINGLE_DATE,
  FILTER_TYPE_DATE_PRESETS,
} from "../../utils/constants/constants";
import {
  getPageOrientedData,
  migrateDateFilters,
} from "../../utils/siteConfiguration/dateFilters";
import { setCustomDefaultSelected } from "./siteConfiguration";
import { castToNumber, isMultiRangeFilterType } from "../../utils/func";
import { endOfYesterday, format } from "date-fns";
import menuFilterSettingsFormatConverter from "../../utils/formatConverters/menuFilterSettingsFormatConverter";
import { getFiltersFromLocalStorage } from "../../utils/browser";
import { isEqual, pull } from "lodash-es";
import { getComparisonModeItem } from "../../Layout/Block/interruptDashboard";
import { v4 as uuid } from "uuid";
import formatter from "../../utils/formatters/formatter";
import { applyComparisonSettingsToDraft } from "./layoutReducerComparisonMode";
import { convertCustomDefaultValue } from "../../Pages/SiteConfiguration/Components/AdminMenuFilters/helper";

export function setDataUniqueRowUuid(data) {
  return (data ?? []).map((item) => ({ ...item, uniqueRowUuid: uuid() }));
}

const initialState = {
  domain: "",
  siteName: "",
  logo: null,
  favicon: null,
  loginBackground: null,
  tabs: [],
  tabsError: null,
  dateFilters: {},
  menuFilters: [],
  singleQueryFiltersByQueryUuid: {},
  menuFiltersSelectedValuesByMenuFilterUuid: {},
  multiRangeFilters: null,
  menuFilterValueShortlists: {},
  booleanFilters: [],
  dateFiltersConfig: null,
  tenantId: null,
  headerVis: false,
  filtersUpdateHeaderKPIs: false,
  links: [],
  theme: {},
  loading: true,
  header: null,
  landing: false,
  invalidDomain: false,
  additionalTheme: {},
  term: null,
  activeTab: {},
  dataSourceAccessConfig: convertDataSourcesAccess([]),
  importantNotifications: [],
  importantNotificationsLoading: false,
  currentDateTerm: null,
  markerIODestination: "",
  comparisonMode: null,
  comparisonModeFilterName: null,
  dateFilterType: null,
  userSettings: null,
  isGlobalSticky: false,
  customDatesFilterTypeMap: {},
  searchFilterLoading: false,
};

// TODO: replace it with real options from site config
const comparisonOptions = [
  {
    options: ["GeneralManager", "RegionalSalesDirector", "Store", "FitterName"],
    count: 2,
    pages: ["44EE9635-7A4B-4C85-BAAA-8A156C33E819"],
    checked: 0,
  },
  {
    options: [
      "GeneralManager",
      "RegionalSalesDirector",
      "FitterName",
      "Store",
      "State",
      "Vintage",
      "FitterTenure",
    ],
    count: Infinity, // dynamic
    pages: ["A837BBA3-5613-4BD4-B107-0C60146DE099"],
    checked: 0,
    useFilterUnselect: true,
  },
  {
    options: ["GeneralManager", "RegionalSalesDirector", "FitterName"],
    count: Infinity, // dynamic
    pages: ["656E160D-0886-498D-B01B-A245B3B280A9"],
    checked: 0,
    useFilterUnselect: true,
  },
];

const comparisonOptionsDemo = [
  {
    options: ["State", "FarmType"],
    count: 2,
    pages: ["661CCF03-5D34-416A-BA34-CAF31811B37D"],
    checked: 0,
  },
];

const springDemo = [
  {
    options: ["Market", "PortCo"],
    count: 2,
    pages: ["85B3700F-332F-47B0-9AC8-8C3E5CB886F9"],
    checked: 0,
  },
];

export { initialState as layoutInitialState };

// special feature for pages
// now we have opportunity to set different date filters on each page
function initializePageOrientedDates(
  dates,
  config,
  pageUuid,
  domain,
  currentTerm
) {
  const { dateTypes } = getPageOrientedData(
    config?.pageOriented ?? [],
    pageUuid
  );

  if (dateTypes) {
    const selectedTermExists = dateTypes.find(
      (type) => type === currentTerm || type === currentTerm?.value
    );
    if (selectedTermExists) return dates;

    const defaultTerm = dateTypes.find(
      (type) => type === config.filterCustomTerm
    );

    const { from, to } = setDateFilters(domain, defaultTerm ?? dateTypes[0], 0);

    return {
      start: { ...dates.start, value: from },
      end: { ...dates.end, value: to },
    };
  }

  return dates;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHECK_AUTH_START:
      return { ...state, landing: false, loading: true };

    case actionTypes.SET_ACTIVE_TAB:
      const comparisonModeItem =
        getComparisonModeItem(state.comparisonMode, action.data?.uuid) ?? {};

      const inComparisonMode = comparisonModeItem.pages?.includes(
        action.data?.uuid
      );

      return {
        ...state,
        isGlobalSticky: false,
        activeTab: action.data,
        dateFilters: initializePageOrientedDates(
          state.dateFilters,
          state.dateFiltersConfig,
          action.data?.uuid,
          state.domain,
          state.currentDateTermCode
        ),
        ...(inComparisonMode && {
          menuFilters: state.menuFilters.map((filter) => ({
            ...filter,
            values: comparisonModeItem.options?.includes(filter.name)
              ? filter.values.map((value) => ({
                  ...value,
                  checked: false,
                }))
              : filter.values,
          })),
          comparisonMode: state.comparisonMode.map((item) => ({
            ...item,
            checked: 0,
          })),
        }),
      };

    case actionTypes.GET_PUBLIC_CONFIG_SUCCESS: {
      const {
        background,
        customPrimaryColor,
        darkThemeEnabled,
        favicon,
        logo,
        siteName,
        tenant,
        additionalTheme,
        markerIODestination,
      } = action.results.data;
      return {
        ...state,
        landing: false,
        loading: false,
        invalidDomain: false,
        siteName,
        logo,
        loginBackground: background,
        favicon,
        tenantId: tenant.uuid,
        theme: {
          name: darkThemeEnabled ? "dark" : "light",
          primary: customPrimaryColor,
          menuPrimary:
            additionalTheme && JSON.parse(additionalTheme).menuPrimary,
        },
        markerIODestination: markerIODestination || "",
      };
    }

    case actionTypes.GET_PUBLIC_CONFIG_FAIL: {
      return {
        ...state,
        loading: false,
        invalidDomain: true,
      };
    }

    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.CHECK_AUTH_SUCCESS:
    case actionTypes.UPDATE_TENANT_CONFIG: {
      const {
        tenantConfig,
        dataSourcesAccess = [],
        importantNotifications,
        menuFilterSettings,
        userSettings,
      } = action.results;

      const dates = migrateDateFilters(
        tenantConfig.dateFilters && JSON.parse(tenantConfig.dateFilters)
      );

      // if user select date term and clicked save settings
      // set selected as default
      if (userSettings?.dateFilterSettings) {
        dates.filterCustomTerm = userSettings.dateFilterSettings.term;
      }

      const additionalTheme = tenantConfig.additionalTheme
        ? JSON.parse(tenantConfig.additionalTheme)
        : {};
      const settings = JSON.parse(tenantConfig.settings);
      const dateFilterMode = dates?.filterMode ?? DATE_FILTER_MODE_NONE;
      const localMenuFilterSettings =
        menuFilterSettingsFormatConverter.response.toLocal(menuFilterSettings);

      const filters = tenantConfig.menuFilters
        .filter((filter) =>
          removeHiddenFilters(filter, additionalTheme.hiddenFilters)
        )
        .map(limitDateRangeFilterType)
        .map((item) => mapMenuFilters(item, localMenuFilterSettings))
        .map((item) => clearDatePresetsFilters(item, tenantConfig.domain));

      const startValue = configureStartDate(
        tenantConfig.domain,
        dates,
        dateFilterMode,
        userSettings?.dateFilterSettings
      );

      // if user have saved date filters then use them
      const endValue = (function () {
        if (
          tenantConfig.domain === "ccg" &&
          !userSettings?.dateFilterSettings
        ) {
          return setSpecificTimezone();
        }

        return configureEndDate(
          dates,
          dateFilterMode,
          tenantConfig.domain,
          userSettings?.dateFilterSettings
        );
      })();

      const { dynamicAggregationDefault, dynamicAggregationValues } =
        dates || {};

      function getMaxAggregation() {
        if (dynamicAggregationDefault) {
          return dynamicAggregationDefault;
        }
        return findMaxIndex(dynamicAggregationValues) || null;
      }

      return {
        ...state,
        siteName: tenantConfig.siteName,
        logo: tenantConfig.logo,
        loginBackground: tenantConfig.background,
        favicon: tenantConfig.favicon,
        tenantId: tenantConfig.tenant.uuid,
        theme: {
          name: tenantConfig.darkThemeEnabled ? "dark" : "light",
          primary: tenantConfig.customPrimaryColor,
          menuPrimary: additionalTheme.menuPrimary,
        },
        additionalTheme,
        dateFiltersConfig: dates || null,
        ...(dateFilterMode !== DATE_FILTER_MODE_NONE && {
          dateFilters: {
            start: {
              type: dates.fieldName,
              value: startValue,
            },
            end: {
              type: dates.fieldName,
              value: endValue,
            },
          },
        }),
        menuFilters: filters,
        menuFiltersSelectedValuesByMenuFilterUuid: {},
        booleanFilters: setNewBooleans(additionalTheme),
        ...(!state.header && {
          header: tenantConfig.showHeaderKPI ? { blocks: [] } : false,
        }),
        // @todo save as setting on site config
        comparisonMode:
          tenantConfig.domain === "ccg"
            ? comparisonOptions
            : tenantConfig.domain === "demoem"
            ? comparisonOptionsDemo
            : tenantConfig.domain === "cspring"
            ? springDemo
            : tenantConfig.comparisonMode,
        loading: false,
        invalidDomain: false,
        headerVis: tenantConfig.showHeaderKPI,
        domain: tenantConfig.domain,
        filtersUpdateHeaderKPIs: !!settings?.filtersUpdateHeaderKPIs,
        term: getMaxAggregation(),
        twoFactorAuthRequired: tenantConfig.twoFactorAuthRequired,
        dataSourceAccessConfig: convertDataSourcesAccess(dataSourcesAccess),
        importantNotifications,
        markerIODestination: tenantConfig.markerIODestination || "",
        hasDataDictionary: tenantConfig.hasDataDictionary,
        userSettings,
      };
    }

    case actionTypes.UPDATE_TWO_FACTOR_AUTHENTICATION_REQUIRED: {
      return {
        ...state,
        twoFactorAuthRequired: action.payload,
      };
    }

    case actionTypes.LOAD_LANDING_SITE:
      return {
        ...state,
        landing: true,
      };

    case actionTypes.LOAD_HEADER_CONFIG_SUCCESS:
      return {
        ...state,
        header: {
          ...action.results,
          loading: false,
          responseData: action.responseData,
        },
      };

    case actionTypes.SET_LOAD_HEADER_BUNDLE:
      return {
        ...state,
        ...(state.header && {
          header: {
            ...state.header,
            blocks: state.header.blocks.map((block) => ({
              ...block,
              hide: block.charts.find((chart) =>
                chart.hideOnFilters?.find((hof) =>
                  action.activeMenuFilters.includes(hof)
                )
              ),
              charts: block.charts.map((chart) => ({
                ...chart,
                refreshing: action.visualizationIds.includes(
                  chart.visualizationId
                ),
              })),
            })),
          },
        }),
      };

    case actionTypes.EXECUTE_QUERY_FAIL:
      return {
        ...state,
        ...(state.header && {
          header: {
            ...state.header,
            blocks: state.header.blocks.map((block) => ({
              ...block,
              charts: block.charts.map((chart) => ({
                ...chart,
                error: true,
                refreshing: !action.visualizationId,
              })),
            })),
          },
        }),
      };

    case actionTypes.EXECUTE_QUERY_SUCCESS: {
      if (action.slice !== "layout") return { ...state };
      const { data, meta } = action.results;

      const tData = setDataUniqueRowUuid(data);

      const updateChart = doMerge(
        state.header.blocks,
        action.visualizationId,
        tData,
        action.cacheKey,
        meta
      );

      return {
        ...state,
        header: {
          ...state.header,
          blocks: state.header.blocks.map((block) => ({
            ...block,
            charts: block.charts.map((chart) =>
              chart.visualizationId === action.visualizationId
                ? {
                    ...chart,
                    ...updateChart,
                  }
                : chart
            ),
          })),
        },
      };
    }

    case actionTypes.SHOW_SUBMENU:
      return { ...state, showSubmenu: true };

    case actionTypes.HIDE_SUBMENU:
      return { ...state, showSubmenu: false };

    case actionTypes.SELECT_ALL_COMPARISON_FILTERS: {
      return {
        ...state,
        menuFilters: state.menuFilters.map((filter) => {
          if (filter.name === action.key) {
            return {
              ...filter,
              values: filter.values.map((item) => ({
                ...item,
                checked: item.value && action.checked,
              })),
            };
          }

          return filter;
        }),
        comparisonMode: state.comparisonMode.map((item) => {
          if (item.pages.includes(state.activeTab.uuid)) {
            return {
              ...item,
              checked: Number.isFinite(item.count) ? item.count : action.count,
            };
          }
          return item;
        }),
      };
    }

    case actionTypes.SELECT_ALL_GENERAL_FILTERS:
      return produce(state, (draft) => {
        draft.menuFilters.forEach((filter) => {
          if (filter.name === action.name) {
            filter.values = action.filters;
          }
        });
        if (draft.comparisonMode) {
          const filter = action.filters.find((f) => f.key === action.name);
          applyComparisonSettingsToDraft(draft, filter);
        }
      });

    case actionTypes.SELECT_FILTER:
      const { key, value, checked, menuFilterUuid } = action.filter;
      const { singleQueryFiltersUuid, filterType } = action;
      let exitOnFound = false;

      return produce(state, (draft) => {
        for (const [menuFilterIndex, menuFilter] of Object.entries(
          state.menuFilters
        )) {
          if (menuFilter.uuid !== menuFilterUuid) {
            continue;
          }
          for (const [valueIndex, filter] of Object.entries(
            menuFilter.values
          )) {
            exitOnFound = value === filter.value;
            if (exitOnFound) {
              draft.menuFilters[menuFilterIndex].values[valueIndex].checked =
                checked;
              break;
            }
          }

          if (exitOnFound) {
            break;
          }
        }
        if (singleQueryFiltersUuid) {
          const selectedValues = ensureFieldForSingleQueryFiltersDraft(
            draft,
            singleQueryFiltersUuid,
            key,
            filterType
          ).selectedValues;
          const amongValues = selectedValues.includes(value);
          if (checked && !amongValues) {
            selectedValues.push(value);
            selectedValues.sort();
          } else if (!checked && amongValues) {
            pull(selectedValues, value);
          }
        }
        if (draft.comparisonMode) {
          applyComparisonSettingsToDraft(draft, action.filter);
        }
      });

    case actionTypes.CHANGE_COMPARISON_TYPE:
      return produce(state, (draft) => {
        const { options } = action.comparisonModeItem ?? {};

        draft.menuFilters = draft.menuFilters.map((mf) => {
          return {
            ...mf,
            values: options?.includes(mf.name)
              ? mf.values.map((v) => ({ ...v, checked: false }))
              : mf.values,
          };
        });
        draft.comparisonMode = draft.comparisonMode.map((item) => {
          if (item.pages.includes(draft.activeTab.uuid)) {
            return { ...item, checked: 0 };
          }
          return item;
        });
      });

    case actionTypes.CHANGE_COMPARISON_MODE_FILTER_NAME:
      return { ...state, comparisonModeFilterName: action.name };

    case actionTypes.SELECT_MULTI_RANGE_FILTER: {
      return produce(state, (draft) => {
        const { filter } = action;
        const { singleQueryFiltersUuid, filterType } = filter;
        if (singleQueryFiltersUuid) {
          const fieldDraft = ensureFieldForSingleQueryFiltersDraft(
            draft,
            singleQueryFiltersUuid,
            filter.name,
            filterType
          );
          const values = [filter.min, filter.max];
          if (!isEqual(values, fieldDraft.selectedValues)) {
            fieldDraft.selectedValues = values;
          }
          return;
        }
        for (const item of draft.menuFilters) {
          if (item.type === "multiRange") {
            item.values = [
              {
                type: action.filter.type,
                key: action.filter.type,
                value: action.filter.min,
                label: formatter(action.filter.min, ".3s"),
                checked: true,
              },
              {
                type: action.filter.type,
                key: action.filter.type,
                value: action.filter.max,
                label: formatter(action.filter.max, ".3s"),
                checked: true,
              },
            ];
          }
        }
      });
    }

    case actionTypes.SELECT_SLIDER:
      const filterIndex = state.menuFilters.findIndex(
        (f) => f.name === action.filter.name
      );

      return produce(state, (draft) => {
        draft.menuFilters[filterIndex].selectedValue =
          action.filter.selectedValue;
      });

    case actionTypes.SELECT_DATE_RANGE_FILTER:
      return produce(state, (draft) => {
        for (const item of draft.menuFilters) {
          if (item.name === action.name) {
            item.values = action.filters;
          }
        }
      });

    case actionTypes.SELECT_SINGLE_DATE_FILTER:
      return produce(state, (draft) => {
        for (const item of draft.menuFilters) {
          if (item.name === action.name) {
            item.values = [
              {
                ...action.filters[0],
                checked: item.values[0]?.value !== action.filters[0]?.value,
              },
            ];
          }
        }
      });

    case actionTypes.SELECT_DATE_PRESETS_FILTER:
      return produce(state, (draft) => {
        const { singleQueryFiltersUuid, name, filters, filterType } = action;
        if (singleQueryFiltersUuid) {
          const fieldDraft = ensureFieldForSingleQueryFiltersDraft(
            draft,
            singleQueryFiltersUuid,
            name,
            filterType
          );
          const values = filters.filter((f) => f.checked).map((f) => f.value);
          if (!isEqual(values, fieldDraft.selectedValues)) {
            fieldDraft.selectedValues = values;
          }
          return;
        }

        for (const item of draft.menuFilters) {
          if (item.name === action.name) {
            item.values = action.filters;
          }
        }
      });

    case actionTypes.SAVE_SELECTED_DATE_PESETS_FILTER_MAP:
      return {
        ...state,
        customDatesFilterTypeMap: {
          ...state.customDatesFilterTypeMap,
          ...action.customDateKeyValue,
        },
      };

    // Unselect everything else when selecting an item.
    case actionTypes.SELECT_SINGLE_FILTER:
      return produce(state, (draft) => {
        for (const item of draft.menuFilters) {
          for (const el of item.values) {
            if (el.key !== action.filter.key) {
              break;
            }
            el.checked = el.checked ? false : action.filter.value === el.value;
          }
        }
      });

    case actionTypes.SET_BOOLEAN_FILTER:
      return {
        ...state,
        booleanFilters: state.booleanFilters.map((b) =>
          b.key === action.key
            ? { ...b, checked: action.checked, value: action.value }
            : b
        ),
      };

    case actionTypes.RESET_FILTERS:
      return {
        ...state,
        multiRangeFilters: null,
        menuFilters: state.menuFilters.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({
            ...value,
            checked: isChecked(
              { ...filter, values: filter.values.map((x) => x.value) },
              value.value
            ),
          })),
        })),
        singleQueryFiltersByQueryUuid: {},
        booleanFilters:
          state.booleanFilters &&
          state.booleanFilters.map((filter) => ({
            ...filter,
            checked: false,
          })),
      };

    // Turn off everything, then set new filters, also for insights
    case actionTypes.SET_FILTER:
      function matchFilterValue(value) {
        const match = action.filters.find((fil) => {
          return fil.key === value.key && value.value === fil.value;
        });
        return !!match;
      }

      return {
        ...state,
        menuFilters: state.menuFilters.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({
            ...value,
            checked: matchFilterValue(value),
          })),
        })),
        booleanFilters:
          state.booleanFilters &&
          state.booleanFilters.map((filter) => ({
            ...filter,
            checked: false,
          })),
      };

    case CACHE_MENU_FILTER_CHECKED_VALUES: {
      return produce(state, (draft) => {
        draft.menuFiltersSelectedValuesByMenuFilterUuid[
          action.payload.menuFilterUuid
        ] = action.payload.values;
      });
    }

    case actionTypes.LOAD_MENU_FILTER_VALUES_START: {
      return {
        ...state,
        menuFilterValueShortlists: {
          ...state.menuFilterValueShortlists,
          [action.payload.menuFilterUuid]: "loading",
        },
      };
    }

    case actionTypes.LOAD_MENU_FILTER_VALUES_SUCCESS: {
      const data = action.results.data;
      const parentValuesRef = action.payload.parentsValuesRef;

      if (parentValuesRef.current !== action.payload.parentsValues) {
        // The selected parent values have since changed, so don't update
        // the child values.

        return state;
      }

      return {
        ...state,
        menuFilterValueShortlists: {
          ...state.menuFilterValueShortlists,
          [action.payload.menuFilterUuid]: data.values,
        },
      };
    }

    case actionTypes.RESET_MENU_FILTER_VALUES: {
      return produce(state, (draft) => {
        delete draft.menuFilterValueShortlists[action.payload];
      });
    }

    case actionTypes.SET_DATE_FILTERS: {
      return {
        ...state,
        dateFilters: action.dateFilters,
        dateFilterType: action.dateType?.value,
      };
    }

    case actionTypes.SET_CURRENT_DATE_FILTER_TERM:
      const term = action.term?.value ?? action.term;
      return {
        ...state,
        currentDateTerm: getCurrentDateTerm(term),
        currentDateTermCode: term,
      };

    case CHANGE_THEME_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        theme: {
          ...state.theme,
          name: action.config,
        },
      };
    }

    case actionTypes.HIDE_HEADER: {
      return produce(state, (draft) => {
        draft.headerVis = false;
        draft.header.headerVis = false;
      });
    }

    case actionTypes.SET_DATE_TERM: {
      return produce(state, (draft) => {
        draft.term = action.term;
      });
    }

    case actionTypes.GET_ISSUE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        importantNotifications: action.data,
      };

    case actionTypes.SAVE_DATA_LOAD_ISSUE_NOTIFICATION_START:
      return {
        ...state,
        importantNotificationsLoading: true,
      };

    case actionTypes.SAVE_DATA_LOAD_ISSUE_NOTIFICATION_SUCCESS:
    case actionTypes.SAVE_DATA_LOAD_ISSUE_NOTIFICATION_FAIL:
      return {
        ...state,
        importantNotificationsLoading: false,
      };

    case actionTypes.GET_PAGES_SUCCESS:
      return {
        ...state,
        tabs: action.results,
        tabsError: null,
      };

    case actionTypes.GET_PAGES_FAIL:
      return {
        ...state,
        tabsError: action.payload,
      };

    case actionTypes.EXTEND_MENU_FILTERS:
      if (action.filters.length === 0) {
        return {
          ...state,
          menuFilters: state.menuFilters.filter((mf) => !mf.extended),
        };
      }

      const map = new Map();

      state.menuFilters
        .filter((mf) => !mf.extended)
        .forEach((mf) => map.set(mf.name, mf));
      action.filters.forEach((f) => map.set(f.name, f));

      return {
        ...state,
        menuFilters: [...map.values()],
      };

    case actionTypes.SET_FULL_PAGE_STICKY_HEADERS:
      return {
        ...state,
        isGlobalSticky: action.isSticky,
      };

    case actionTypes.GET_MENU_FILTER_VALUES_START:
      return {
        ...state,
        searchFilterLoading: true,
      };

    case actionTypes.GET_MENU_FILTER_VALUES_SUCCESS:
      const { results, filter } = action;

      function createFilterItem(value) {
        const { name } = filter;
        return {
          key: name,
          type: name,
          value,
          label: value,
          checked: false,
          menuFilterUuid: filter.uuid,
        };
      }

      const values = results.data.map(createFilterItem);

      function removeDuplicates(val, checked) {
        return !checked.find((check) => check.value === val.value);
      }

      function updateFilterValues(item) {
        const { uuid } = filter;
        const checked = item.values.filter((value) => value.checked);

        if (item.uuid === uuid) {
          return {
            ...item,
            values: [
              ...checked,
              ...values.filter((val) => removeDuplicates(val, checked)),
            ],
          };
        }

        return item;
      }

      return {
        ...state,
        menuFilters: state.menuFilters.map(updateFilterValues),
        searchFilterLoading: false,
      };

    case actionTypes.GET_MENU_FILTER_VALUES_FAIL:
      return {
        ...state,
        searchFilterLoading: false,
      };

    case actionTypes.SAVE_STICKY_DATE_TYPE:
      const { pageUuid, term: dateTerm } = action;
      const config = state.dateFiltersConfig ?? {};

      return {
        ...state,
        dateFiltersConfig: {
          ...config,
          pageOriented: (config.pageOriented ?? []).map((item) => {
            const ids = item.pageUuids ?? [item.pageUuid];
            if (ids.includes(pageUuid)) {
              return {
                ...item,
                userSelected: dateTerm.value,
              };
            }

            return item;
          }),
        },
      };

    default:
      return state;
  }
}

function getCurrentDateTerm(term) {
  const { label } = customFilterOptions.find(
    (option) => option.value === term
  ) ?? { label: "Selected Range" };

  return `[${label}]`;
}

function buildDateRangeFilterValues(item) {
  if (item.type !== FILTER_TYPE_DATE_RANGE) {
    return null;
  }

  // hardcoded period start of 2022-12-12 to yesterday
  const start = "2022-12-12";
  const end = format(endOfYesterday(), "yyyy-MM-dd");

  // do not remove these lines
  // const { defaultValues } = item;
  // return defaultValues?.length ? defaultValues : ["Start", "End"];
  return [start, end];
}

function mapBasicMenuFilters(item) {
  return {
    ...item,
    displayName: item.displayName ?? item.name,
    type: item.type || filterTypes[0].value,
  };
}

function limitDateRangeFilterType(item) {
  if (item.type === FILTER_TYPE_DATE_RANGE) {
    return {
      ...item,
      values: [item.values[0], item.values[item.values.length - 1]],
      ...(item.labeledValues && {
        labeledValues: [
          item.labeledValues[0],
          item.labeledValues[item.labeledValues.length - 1],
        ],
      }),
    };
  }

  if (item.type === FILTER_TYPE_SINGLE_DATE) {
    return {
      ...item,
      values: [],
    };
  }

  return item;
}

function clearDatePresetsFilters(item, domain) {
  if (item.type !== FILTER_TYPE_DATE_PRESETS) {
    return item;
  }

  // if we have default value in date presets then apply them like default menu filter
  const defaultValue = (item.defaultValues ?? [])[0];
  if (defaultValue) {
    const { from, to } = setDateFilters(domain, defaultValue);
    const values = buildFilters(from, to, item.name);
    return { ...item, values };
  }

  return { ...item, values: [] };
}

// this function replace original values with gaps to ordered multi range slider with step 1
function createArrayWithStep(min, max) {
  const resultArray = [min, max];
  // We shouldn't need this anymore
  // const steps = 100;
  // const stepSize = (max - min) / steps;
  //
  // for (let i = 0; i <= steps; i++) {
  //   resultArray.push(min + i * stepSize);
  // }

  return resultArray;
}

export function mapMenuFilters(item, localMenuFilterSettings) {
  const values = buildDateRangeFilterValues(item) || [...item.values];

  const labeledValues = item.labeledValues
    ? [...item.labeledValues]
    : values.map((value) => ({
        value,
        label: value,
      }));

  const retVal = {
    ...mapBasicMenuFilters(item),
    values: labeledValues
      .sort((a, b) => {
        return isMultiRangeFilterType(item.type)
          ? castToNumber(a.label) - castToNumber(b.label)
          : 0;
      })
      .map(mapMenuFilterValue(item, localMenuFilterSettings)),
  };

  if (item.type === "slider" && item.defaultValues?.length) {
    retVal.selectedValue = item.defaultValues[0];
  }

  if (isMultiRangeFilterType(item.type)) {
    const min = +retVal.values[0].value;
    const max = +retVal.values[retVal.values.length - 1].value;
    retVal.limitMin = min;
    retVal.limitMax = max;

    retVal.values = createArrayWithStep(min, max).map((value) => ({
      key: item.name,
      type: item.name,
      checked: true,
      value,
      label: formatter(value, ".3s"),
    }));
  }

  return retVal;
}

function getHardCodedLabel(name, value, values) {
  const hardCoded = hardCodedFilterLabels[name];
  if (hardCoded) {
    return hardCoded[value];
  }

  // more then 2 values mean that its not boolean like filter
  if ((values ?? []).length > 2) {
    return value;
  }

  return value === "0" ? "No" : value === "1" ? "Yes" : value;
}

function isJsonString(value) {
  // do not parse numbers or stringed numbers
  // something strange happen in js when you do JSON.parse("401916510162963932") or +"401916510162963932"
  // js cast this to different value like that 401916510162963900
  if (!isNaN(+value)) {
    return value;
  }

  try {
    JSON.parse(value);
  } catch (e) {
    return value;
  }
  return JSON.parse(value);
}

// correct priority for checked filters
// user saved filters is top priority if they exists
function getCorrectIsCheckedByPriority(filter, value, userSavedFilters) {
  const saved = userSavedFilters ?? {};

  const defaultValues = [convertCustomDefaultValue(filter.defaultValues?.[0])];
  const currentFilter = { ...filter, defaultValues };

  if (saved[currentFilter.uuid]?.length) {
    return isInSavedMenuFilterSettings(userSavedFilters, currentFilter, value);
  }

  return (
    isChecked(currentFilter, value) || isInLocalStorage(currentFilter, value)
  );
}

function mapMenuFilterValue(item, localMenuFilterSettings) {
  return (labeledValue) => {
    let { label, value } = labeledValue;
    value = typeof value === "boolean" ? (value ? "1" : "0") : value;
    const convertToYesNo = getHardCodedLabel(item.name, label, item.values);

    return {
      key: item.name,
      type: item.name,
      value: isJsonString(value),
      label: item.type !== "multiRange" ? convertToYesNo : label,
      checked: getCorrectIsCheckedByPriority(
        item,
        value,
        localMenuFilterSettings
      ),
      menuFilterUuid: item.uuid,
    };
  };
}

/**
 * @deprecated Should be removed in the future once most localStorage filters
 *  have gotten saved to the API.
 */
function isInLocalStorage(filter, value) {
  const localStorageValues = getFiltersFromLocalStorage();
  return (localStorageValues[filter.uuid] || []).includes(value);
}

export function mapToStringThenCompare(array, value) {
  return array.some((val) => val + "" === value + "");
}

export function isInSavedMenuFilterSettings(
  localMenuFilterSettings,
  filter,
  value
) {
  if (value !== null && typeof value === "object") {
    return (localMenuFilterSettings[filter.uuid] || []).some(
      isEqual.bind(null, value)
    );
  }

  return mapToStringThenCompare(
    localMenuFilterSettings[filter.uuid] || [],
    value
  );
}

function isChecked(filter, value) {
  // force check date range filter as default
  if (filter.type === FILTER_TYPE_DATE_RANGE || filter.number) {
    return true;
  }

  const val = value && typeof value === "string" ? value.trim() : value;

  const defaults = setCustomDefaultSelected(filter).defaultValues ?? [];

  return defaults.includes(
    val // for case when we have " 1" as filter value but "1" as default selected
  );
}

/**
 * Converts the dataSourcesAccess property coming from the API to a value that's
 * better to work with.
 */
export function convertDataSourcesAccess(dataSourcesAccess) {
  const ret = {
    dataSources: {
      hasWhitelist: false,
      byUuid: {},
    },
    dataSourceFields: {
      hasWhitelist: false,
      byUuid: {},
    },
    dataSourceFieldValues: {
      hasWhitelist: false,
      byUuid: {},
    },
    paramValue: null,
  };

  for (const dataSourceAccess of dataSourcesAccess) {
    ret.dataSources.hasWhitelist = true;
    ret.dataSources.byUuid[dataSourceAccess.dataSourceUuid] = {
      displayName: dataSourceAccess.displayName,
    };
    for (const fieldAccess of dataSourceAccess.fieldsAccess) {
      ret.dataSourceFields.hasWhitelist = true;
      ret.dataSourceFields.byUuid[fieldAccess.dataSourceFieldUuid] = {};
    }
    for (const allowedValues of dataSourceAccess.allowedValues) {
      ret.dataSourceFieldValues.hasWhitelist = true;
      ret.dataSourceFieldValues.byUuid[allowedValues.dataSourceFieldUuid] = {
        values: allowedValues.values,
      };
      if (!ret.paramValue) ret.paramValue = allowedValues.values[0];
    }
  }

  return ret;
}

function configureStartDate(
  domain,
  config,
  dateFilterMode,
  dateFilterSettings
) {
  if (config.globalMinimumDate) {
    return config.globalMinimumDate;
  }

  if (
    dateFilterMode === DATE_FILTER_MODE_CUSTOM &&
    !config.filterCustomValues.includes(DATE_TERM_MONTH_TO_MONTH)
  ) {
    const term = config.filterCustomTerm ?? config.filterCustomValues[0];

    return setDateFilters(
      domain,
      term,
      config.rangeSettingsRange,
      dateFilterSettings?.startDate,
      dateFilterSettings?.endDate,
      config
    ).from;
  }

  return fromToDateFormatter(
    config.rangeSettingsTerm,
    config.rangeSettingsRange
  ).from;
}

function configureEndDate(config, dateFilterMode, domain, dateFilterSettings) {
  if (config.globalMaximumDate) {
    return config.globalMaximumDate;
  }

  if (
    dateFilterMode === DATE_FILTER_MODE_CUSTOM &&
    !config.filterCustomValues.includes(DATE_TERM_MONTH_TO_MONTH)
  ) {
    return setDateFilters(
      domain,
      config.filterCustomTerm,
      undefined,
      dateFilterSettings?.startDate,
      dateFilterSettings?.endDate,
      config
    ).to;
  }

  return fromToDateFormatter(
    config.rangeSettingsTerm,
    config.rangeSettingsRange
  ).to;
}

function setNewBooleans({ booleanFilters, booleans }) {
  if (booleans?.length) {
    return booleans.map((b) => ({
      key: b.column,
      label: b.displayName,
      checked: false,
      value: true,
      hideOnPages: b.hideOnPages,
      onLabelOverride: b.onLabelOverride,
      offLabelOverride: b.offLabelOverride,
    }));
  }

  if (booleanFilters) {
    return booleanFilters.map((b) => {
      const [key, label] = b.split("::");
      return { key, label, checked: false, value: true };
    });
  }

  return [];
}

function removeHiddenFilters(filter, hiddenFilters = {}) {
  return !hiddenFilters[filter.uuid];
}

/**
 * Returns a subdraft representing a query field for single query menu filters.
 * If the field does not yet exist in the state, it gets created first.
 *
 * @param draft
 * @param singleQueryFiltersUuid
 * @param key
 * @param filterType
 * @returns {*}
 */
function ensureFieldForSingleQueryFiltersDraft(
  draft,
  singleQueryFiltersUuid,
  key,
  filterType
) {
  if (!draft.singleQueryFiltersByQueryUuid[singleQueryFiltersUuid]) {
    draft.singleQueryFiltersByQueryUuid[singleQueryFiltersUuid] = {
      fieldsByName: {},
    };
  }
  const fieldsByName =
    draft.singleQueryFiltersByQueryUuid[singleQueryFiltersUuid].fieldsByName;
  if (!fieldsByName[key]) {
    fieldsByName[key] = { key, selectedValues: [] };
  }
  fieldsByName[key].filterType = filterType;
  return fieldsByName[key];
}
