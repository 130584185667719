/**
 * Gets the reducer function for gathering numbers or totals to appear on each stack component.
 *
 * @param yGroupValues
 * @param breakdownKey
 * @param yKeys
 * @returns {(function(*, *): (*))|*}
 */
export function getDataReducer(yGroupValues, breakdownKey, yKeys) {
  function dataReducer(acc, curr) {
    const ret = { ...acc };
    if (yKeys) {
      return multiKeyMode(acc, curr, yKeys);
    } else {
      return singleValueMode(acc, curr, ret, breakdownKey, yGroupValues);
    }
  }

  return dataReducer;
}

function multiKeyMode(acc, curr, yKeys) {
  const yKeyValues = yKeys.reduce((a, c) => {
    return { ...a, [c]: curr[c] };
  }, {});
  return { ...acc, ...yKeyValues };
}

function singleValueMode(acc, curr, ret, breakdownKey, yGroupValues) {
  if (breakdownKey) {
    if (Array.isArray(yGroupValues)) {
      for (const groupValue of yGroupValues) {
        const isObj = typeof groupValue === "object";
        const source = isObj ? groupValue.source : groupValue;
        const target = isObj ? groupValue.target : groupValue;

        ret[target] = (acc[source] || 0) + curr[source];
      }
      return ret;
    }

    const currKey = curr[breakdownKey];
    return {
      ...acc,
      allData: curr,
      [currKey]: (acc[currKey] || 0) + curr[yGroupValues],
    };
  }
  if (Array.isArray(yGroupValues)) {
    for (const groupValue of yGroupValues) {
      const isObj = typeof groupValue === "object";
      const source = isObj ? groupValue.source : groupValue;
      const target = isObj ? groupValue.target : groupValue;

      ret[target] = (acc[source] || 0) + curr[source];
    }
    return ret;
  }

  // const currKey = curr[breakdownKey]; // Not sure what this was doing
  return {
    ...acc,
    ...curr,
    // allData: curr,
    // [yGroupValues]: (acc[currKey] || 0) + curr[yGroupValues],
  };
}

export function calculateCAGR(beginningValue, endingValue, numOfYears) {
  if (beginningValue <= 0 || endingValue <= 0 || numOfYears <= 0) {
    return { errorMessage: "Values must be greater than zero." };
  }

  const cagr = Math.pow(endingValue / beginningValue, 1 / numOfYears) - 1;
  return (cagr * 100).toFixed(2) + "%"; // Return CAGR as a percentage with 2 decimal places
}
