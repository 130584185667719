import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadioButton from "../../../../UI/RadioButton/RadioButton";
import RepeatingGroupLogic from "./RepeatingGroups/RepeatingGroupLogic";

export default function Grouping({
  RadioGroup,
  Group,
  theme,
  chart,
  setChartState,
  repeatingOptions,
  immutableChart,
  removeDynamicSubTitleColumn,
  isParameterized,
  setSelectedColumn,
  setNavigationState,
}) {
  const handleManualGroups = () => {
    setChartState({
      ...chart,
      staticGroupingKeys: immutableChart.staticGroupingKeys ?? [""],
      groupingKey: null,
      rowGroupKey: null,
      uniqueValueKey: immutableChart.uniqueValueKey,
      subTitles: immutableChart.subTitles ?? chart.subTitles,
      dynamicSubTitleKeys: null,
    });
  };

  const repeatingGroups = chart.dynamicSubTitleKeys
    ? ["D1", "D2", "D1", "D2"]
    : ["D1", "D2", "D3", "D4"];

  return (
    <div>
      <span>Grouping Style</span>
      <RadioGroup>
        <RadioButton
          checked={!chart.staticGroupingKeys && !chart.groupingKey}
          onChange={() =>
            setChartState({
              ...chart,
              staticGroupingKeys: null,
              groupingKey: null,
              subTitles: immutableChart.subTitles,
              dynamicSubTitleKeys: null,
            })
          }
          cy="no-grouping-option"
        />
        <label>No Grouping</label>
      </RadioGroup>
      <Group background={theme.divider}>
        <FontAwesomeIcon
          icon={["far", "times-circle"]}
          style={{ color: theme.notification.errorMain }}
        />
      </Group>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
      </div>

      <hr color={theme.background.secondary} />

      <RadioGroup>
        <RadioButton
          checked={!!chart.staticGroupingKeys}
          onChange={handleManualGroups}
          cy="manual-grouping-option"
        />
        <label>Manual Groups</label>
      </RadioGroup>
      <Group background={theme.primary} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group width="30%" background={theme.divider} fontSize={12}>
          D1
        </Group>
        <Group width="30%" background={theme.divider} fontSize={12}>
          D2
        </Group>
        <Group width="30%" background={theme.divider} fontSize={12}>
          D3
        </Group>
      </div>

      <hr color={theme.background.secondary} />

      <RadioGroup>
        <RadioButton
          checked={!!chart.groupingKey}
          onChange={() =>
            setChartState({
              ...chart,
              staticGroupingKeys: null,
              groupingKey: "Weekly...",
              uniqueValueKey: null,
              subTitles: chart.subTitles || [[]],
            })
          }
          cy="repeating-grouping-option"
        />
        <label>Repeating Groups</label>
      </RadioGroup>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group background={theme.primary} width="45%" color="white">
          A
        </Group>
        <Group background={theme.primary} width="45%" color="white">
          B
        </Group>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {repeatingGroups.map((group, index) => (
          <Group
            width="18%"
            background={theme.divider}
            fontSize={12}
            key={group + index}
          >
            {group}
          </Group>
        ))}
      </div>

      <RepeatingGroupLogic
        chart={chart}
        setChartState={setChartState}
        repeatingOptions={repeatingOptions}
        removeDynamicSubTitleColumn={removeDynamicSubTitleColumn}
        isParameterized={isParameterized}
        setSelectedColumn={setSelectedColumn}
        setNavigationState={setNavigationState}
      />
    </div>
  );
}
