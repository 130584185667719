import styled from "@emotion/styled";
import Flex from "../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSigma as falSigma } from "@fortawesome/pro-light-svg-icons";

const Container = styled.div(
  ({ theme, isActive }) => `
  height: 32px;
  padding: 0 0.2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 18px;
  background: gray;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background: ${theme.primary};
  }
  ${isActive && `background: ${theme.primary};`};
`
);

export default function ActiveTableAggregations({
  activeColumn,
  changeValue,
  aggregationType,
  aggregationIcons,
}) {
  const aggregationOptions = Object.keys(aggregationIcons);

  const setAggregationType = (type) => {
    const newType = type === aggregationType ? null : type;
    changeValue(activeColumn, "aggregationType", newType);
  };

  const getTitle = (type) => {
    return aggregationType === type ? "Do not aggregate" : `Aggregate ${type}`;
  };

  return (
    <Flex>
      {aggregationOptions.map((type) => (
        <Container
          key={type}
          title={getTitle(type)}
          onClick={() => setAggregationType(type)}
          isActive={aggregationType === type}
        >
          <FontAwesomeIcon fixedWidth icon={falSigma} />
          <FontAwesomeIcon fixedWidth icon={aggregationIcons[type]} />
        </Container>
      ))}
    </Flex>
  );
}
