import React from "react";
import DependencyFilter from "../Filters/DependencyFilter/DependencyFilter";
import DateFilters from "./DateFilters";
import BooleanFilters from "./BooleanFilters";
import MenuFiltersList from "./MenuFiltersList";
import { useSingleQueryFiltersBasic } from "./useSingleDataSourceFilters";
import SingleDataSourceMenuFilters from "./SingleDataSourceMenuFilters";

export default function FilterSection(props) {
  const {
    filters,
    currentTabId,
    dataSourceAccessConfig,
    menuFilterShortList,
    domain,
    dualPage,
    dualType,
    dateConfig,
    select,
    booleanFilters,
    selectBoolean,
    additionalTheme,
    showDateFilters,
    comparisonMode,
    userSettings,
    selectPowerEditorModeFilter,
    searchFilterLoading,
  } = props;

  const dateFiltersVisible = dateConfig?.show(
    currentTabId,
    dataSourceAccessConfig
  );

  const { isSingleQueryFiltersEnabled, queryUuid } =
    useSingleQueryFiltersBasic(currentTabId);

  return (
    <>
      {isSingleQueryFiltersEnabled ? (
        <SingleDataSourceMenuFilters
          key={queryUuid}
          additionalTheme={additionalTheme}
          dataSourceAccessConfig={dataSourceAccessConfig}
          select={select}
          pageUuid={currentTabId}
        />
      ) : (
        <MenuFiltersList
          filters={filters}
          currentTabId={currentTabId}
          dataSourceAccessConfig={dataSourceAccessConfig}
          menuFilterShortList={menuFilterShortList}
          select={select}
          additionalTheme={additionalTheme}
          comparisonMode={comparisonMode}
          selectPowerEditorModeFilter={selectPowerEditorModeFilter}
          searchFilterLoading={searchFilterLoading}
        />
      )}

      {/* Hard coded for Revenue Insights */}
      <DependencyFilter filters={filters} />

      <BooleanFilters
        booleanFilters={booleanFilters}
        selectBoolean={selectBoolean}
        currentTabId={currentTabId}
      />

      {dateFiltersVisible ? (
        <DateFilters
          dateConfig={dateConfig}
          showDateFilters={showDateFilters}
          domain={domain}
          dualPage={dualPage}
          dualType={dualType}
          userSettings={userSettings}
          additionalTheme={additionalTheme}
        />
      ) : null}
    </>
  );
}

FilterSection.defaultProps = {
  menuFilterShortList: {},
};
