import { summedByKey, unique } from "../../utils/func";

class PieValueObject {
  constructor(
    data,
    groupKey,
    valueKey,
    colors,
    legendItems,
    absoluteValues,
    valueKeys
  ) {
    const uniqueGroupValues =
      valueKeys ?? unique(data.map((d, i) => d[groupKey]));

    const summaryChart = uniqueGroupValues.map((g, i) => ({
      value: absoluteValues
        ? Math.abs(getTotalledSummaryValue(g))
        : getTotalledSummaryValue(g),
      // color: masterColor(g, i),
      name: g || valueKey, // apply valueKeys approach here
    }));

    summaryChart.sort((a, b) => b.value - a.value);
    this.summaryChart = summaryChart;

    function getTotalledSummaryValue(g) {
      if (valueKeys) {
        return summedByKey(g)(data);
      }

      return summedByKey(valueKey)(data.filter(matchNameToGroupKey));

      function matchNameToGroupKey(d) {
        return d[groupKey] === g;
      }
    }

    // function masterColor(g, i) {
    //   return legendItems
    //     ? legendItems.find((l) => l.name === g)?.color
    //     : colors(i);
    // }
  }

  getChart() {
    return this.summaryChart;
  }
  // getColors() {
  //   return this.summaryChart.map((slice) => slice.color);
  // }

  getSliceNames() {
    return this.summaryChart.map((slice) => slice.name);
  }
}

export default PieValueObject;
