import React from "react";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 5px;

  ${(props) =>
    props.disabled &&
    `
     pointer-events: none;
    opacity: 0.4;
  `}
`;

const CheckboxItem = styled.input(
  ({ theme, overPrimary, info }) => `
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ span {
        border-color: ${
          info
            ? theme.notification.infoMain
            : overPrimary
            ? lightenDarkenColor(theme.primary, -25)
            : theme.primary
        };
        background: ${
          overPrimary
            ? lightenDarkenColor(theme.primary, -25)
            : theme.type === "light"
            ? "white"
            : theme.gray.gray800
        };
        &:after {
            display: block;
        }
    }
`
);

const Label = styled.label(
  ({
    theme,
    overPrimary,
    height = "21px",
    width = "21px",
    top = "3px",
    left = "7px",
    paddingLeft = "34px",
    color,
    fontSize = "13px",
    info,
  }) => `
    font-size: ${fontSize};
    padding-top: 2px;
    padding-left: ${paddingLeft};
    cursor: pointer;
    display: block;
    position: relative;
    user-select: none;
    color: ${
      color || (overPrimary ? theme.text.textOnPrimary : theme.text.primary)
    };
    &:hover span {
      background: ${
        overPrimary
          ? lightenDarkenColor(theme.primary, 100)
          : theme.type === "light"
          ? theme.gray.gray100
          : theme.gray.gray900
      };
    }
    span {
        position: absolute;
        background: ${
          overPrimary
            ? lightenDarkenColor(theme.primary, -10)
            : theme.type === "light"
            ? "white"
            : theme.gray.gray800
        };
        top: 0;
        left: 0;
        height: ${height};
        width: ${width};
        border-radius: 2px;
        border: 1px solid ${
          overPrimary
            ? lightenDarkenColor(theme.primary, -25)
            : theme.type === "light"
            ? theme.divider
            : "none"
        };
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: ${left};
          top: ${top};
          width: 5px;
          height: 10px;
          border: solid ${
            info
              ? theme.notification.infoMain
              : overPrimary
              ? "white"
              : theme.primary
          };
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
    }
`
);

const Checkbox = React.forwardRef((props, ref) => {
  const {
    overPrimary,
    height,
    width,
    top,
    left,
    cy,
    paddingLeft,
    title,
    disabled,
    color,
    fontSize,
    info,
    ...rest
  } = props;

  return (
    <Container
      data-cy="checkbox-container"
      style={rest.style}
      disabled={disabled}
    >
      <Label
        overPrimary={overPrimary}
        height={height}
        width={width}
        top={top}
        left={left}
        paddingLeft={paddingLeft}
        title={title}
        color={color}
        fontSize={fontSize}
        info={info}
      >
        <CheckboxItem
          type="checkbox"
          {...rest}
          info={info}
          overPrimary={overPrimary}
          data-cy={cy || "checkbox-input"}
          ref={ref}
        />
        <span />
        {props.label}
      </Label>
    </Container>
  );
});

Checkbox.propTypes = {
  overPrimary: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.node,
  color: PropTypes.string,
};

export default Checkbox;
