import { getRidOfAggregation } from "../../../../../../charts/TableView/Elements/EditableMenu";
import Select from "../../../../../../UI/Form/Select/Select";
import { components } from "react-select";
import { getOverrideByName } from "../../helper";

export default function MultipleColumnsRowGroupKeys(props) {
  const {
    repeatingOptions,
    chart,
    setChartState,
    setSelectedColumn,
    isParameterized,
    setNavigationState,
  } = props;

  const { rowGroupKeys, overrides, hiddenSubTitles } = chart;

  function setGroupingColumns(options) {
    const definedOptions = options ?? [];

    setChartState({
      ...chart,
      rowGroupKeys: definedOptions.map((option) => option?.value),
      rawRows: false,
    });
  }

  function getRowGroupKeysValues() {
    return repeatingOptions.filter((ro) =>
      rowGroupKeys?.find(
        (key) => getRidOfAggregation(key, overrides) === ro.value
      )
    );
  }

  function onRowGroupKeysOptionClick(props) {
    const override = getOverrideByName(overrides, props.data.value);

    setSelectedColumn({
      ...override,
      column: props.data.value,
      isParameterized,
      hidden: (hiddenSubTitles ?? []).includes(props.data.value),
    });

    setNavigationState("Column");
  }

  return (
    <Select
      options={repeatingOptions}
      value={getRowGroupKeysValues()}
      label="Grouping Multiple columns (Rows)"
      onChange={setGroupingColumns}
      isClearable
      isMulti
      components={{
        MultiValueLabel: (props) => {
          return (
            <div
              onClick={() => onRowGroupKeysOptionClick(props)}
              style={{ cursor: "pointer" }}
            >
              <components.MultiValueLabel {...props} />
            </div>
          );
        },
      }}
    />
  );
}
