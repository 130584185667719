import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from "date-fns";
import {
  dateFromMonth,
  dateFromQuarter,
  dateFromWeek,
} from "../../../utils/dates/dateFunc";

const isExpandableRow = (isDrillable, row) => {
  if (!row || !row.values) {
    return;
  }
  return row[isDrillable] || row.values.some((val) => val[isDrillable]);
};

export const getFilterPrefix = (chart) => {
  if (chart.rowExpandVisualizationParams?.isParameterized === false) {
    return "";
  }

  return (
    chart.rowExpandVisualizationParams?.dynamicFilterPrefix ||
    chart.parameterizedFilterPrefix
  );
};

export function getExpandableBoolean({
  index,
  hasCommonTotalRow,
  row = {},
  isDrillable,
  hasRowExpand,
  expandedTitle,
  expandIndexes,
}) {
  if (isDrillable) {
    return isExpandableRow(isDrillable, row);
  }

  if (hasCommonTotalRow || !hasRowExpand || expandedTitle) {
    return false;
  }

  if (Array.isArray(expandIndexes)) {
    return expandIndexes.includes(index);
  }

  return true;
}

const buildDynamicFilter = (type, value, prefix) => {
  return {
    type: (prefix ?? "") + type,
    value,
    isDrilldownFilter: true,
  };
};

export const getDynamicFilterValue = (
  row = {},
  dynamicFilter,
  dynamicFilterValue
) => {
  // "dynamicFilterValue": {"date_day": "Monthdate_day"}
  // dynamicFilterValue changed from string to object to support multiple dynamicFilters
  const dynamic = (dynamicFilterValue ?? {})[dynamicFilter] || dynamicFilter;
  return (
    row[dynamic] ||
    (row.values && row.values[0] && row.values[0][dynamic]) ||
    null
  );
};

export const buildRowIndexKey = (row, dynamicFilters, dynamicFilterValue) => {
  return (dynamicFilters || []).reduce((acc, curr) => {
    const value = getDynamicFilterValue(row, curr, dynamicFilterValue);
    acc += value;
    return acc;
  }, "");
};

export const buildDynamicFiltersQuery = (
  row,
  dynamicFilters,
  dynamicFilterValue,
  prefix
) => {
  return (dynamicFilters || []).reduce((acc, curr) => {
    const value = getDynamicFilterValue(row, curr, dynamicFilterValue);
    const filter = buildDynamicFilter(curr, value, prefix);
    acc.push(filter);

    return acc;
  }, []);
};

export const removeRowIndex = (rowIndexes, expandedRow) => {
  return rowIndexes.filter((rowIndex) => rowIndex !== expandedRow);
};

export const postMessageFromIframe = (parameter, value) => {
  const message = {
    type: "IO-message",
    [parameter]: value || null,
  };

  window.parent.postMessage(message, "*");
};

function isNumbers(a, b) {
  return Number.isInteger(+a) && Number.isInteger(+b);
}

function getPeriodDates(date, startFn, endFn) {
  return {
    start: format(startFn(date), "yyyy-MM-dd"),
    end: format(endFn(date), "yyyy-MM-dd"),
  };
}

/**
 * Builds a date range based on the provided value.
 * @param {string} value - The value representing the date range.
 * @returns {Object} An object containing the start and end dates of the date range.
 */
function buildDateRange(value) {
  // convert value to string
  const str = value + "";

  const [yearW, week] = str.split(" W");
  if (isNumbers(yearW, week)) {
    const date = dateFromWeek(week, yearW);
    return getPeriodDates(date, startOfWeek, endOfWeek);
  }

  const [yearM, month] = str.split(" M");
  if (isNumbers(yearM, month)) {
    const date = dateFromMonth(month, yearM);
    return getPeriodDates(date, startOfMonth, endOfMonth);
  }

  const [yearQ, quarter] = str.split(" Q");
  if (isNumbers(yearQ, quarter)) {
    const date = dateFromQuarter(quarter, yearQ);
    return getPeriodDates(date, startOfQuarter, endOfQuarter);
  }

  return {};
}

/**
 * Converts filters to date ranges based on their values.
 * @param {Array} filters - An array of filters containing values to be converted to date ranges.
 * @returns {Array} An array of objects representing date ranges.
 */
export const convertFiltersToDateRange = (filters) => {
  return filters.flatMap((curr) => {
    const { start, end } = buildDateRange(curr.value);

    if (start && end) {
      return [
        { type: curr.type, value: start },
        { type: curr.type, value: end },
      ];
    } else {
      return curr;
    }
  });
};
