import React from "react";
import { PreviewContainer } from "./common";
import useApiImage from "../useApiImage";
import {
  getActiveTableFileTypeConfig,
  getSpecificFileTypeByFilename,
} from "../../../utils/activeTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../constants";

export default function ActiveTablePreviewImage({ existingFile, columnType }) {
  return columnType === "image" ? (
    <Image existingImage={existingFile} />
  ) : (
    <File
      columnType={
        getSpecificFileTypeByFilename(existingFile)?.apiType ?? columnType
      }
    />
  );
}

function Image({ existingImage }) {
  const { image } = useApiImage(existingImage);

  return (
    <PreviewContainer style={{ backgroundImage: `url(${image?.data})` }} />
  );
}

function File({ columnType }) {
  const config = getActiveTableFileTypeConfig(columnType);

  return (
    <PreviewContainer>
      <FontAwesomeIcon
        icon={config.solid_icon}
        style={{ height: "100%" }}
        color={colors.fileIcon}
      />
    </PreviewContainer>
  );
}
