import {
  FILTER_TYPE_DATE_PRESETS,
  customFilterOptions,
} from "../../../../utils/constants/constants";

import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { pageLoadDateString } from "../../../../utils/formatters/dateFormatter";

function isOpen(parent, type, localType) {
  if (localType.label) {
    return localType.label === parent.label;
  }

  if (!type) {
    return;
  }

  return (
    parent.value === type ||
    parent.options.find((option) => option.value === type)
  );
}

function isChecked(option, type) {
  return option.value === type;
}

export function getIsPageChecked(uuids, uuid) {
  return (uuids ?? []).includes(uuid);
}

export function mapTypesToElements(options, type, localType) {
  return options.map((item) => ({
    ...item,
    isOpen: isOpen(item, type, localType),
    options: item.options.map((option) => ({
      ...option,
      isChecked: isChecked(option, type),
    })),
  }));
}

export function getDefaultSelectedValues(options = [], selected, isMulti) {
  if (isMulti) {
    return options.filter((o) => selected.find((s) => s === o.value));
  }

  return selected ? options.find((o) => o.value === selected[0]) : null;
}

export function getIsMulti(filterTypes, filter) {
  return filterTypes.find((ft) => ft.value === filter.type)?.isMulti;
}

export function filterValueMapper(filterValue) {
  if (typeof filterValue.value !== "boolean") {
    return filterValue;
  }

  return {
    label: filterValue.value ? "1" : "0",
    value: filterValue.value ? "1" : "0",
  };
}

export const menuFilterModes = {
  edit: "edit",
  drag: "drag",
  conditionalFilters: "conditionalFilters",
};

export const menuFilterModeTabList = [
  {
    displayName: "Edit Mode",
    key: menuFilterModes.edit,
  },
  {
    displayName: "Drag Mode",
    key: menuFilterModes.drag,
  },
  {
    displayName: "Linked Filters",
    key: menuFilterModes.conditionalFilters,
  },
];

export const dateFiltersModeTabList = [
  menuFilterModeTabList[0],
  menuFilterModeTabList[1],
];

export function getTypeIcon(element) {
  const checked = element.options.find((option) => option.isChecked);
  const firstItem = element.options[0]?.value;

  return checked?.value ?? firstItem ?? element.value;
}

export function setProperty(mf, key, value, i, index) {
  if (i !== index) {
    return mf;
  }

  return {
    ...mf,
    [key]: value,
  };
}

export const setDefault = (options, isMulti) => {
  if (isMulti) {
    return (options ?? []).map((o) => o.value);
  }
  return options ? [options.value] : [];
};
/**
 * Returns the default selected values based on the filter type.
 * @param {string} type - The type of the filter.
 * @param {Array} values - Available values for the filter.
 * @param {Array} defaultValues - Default values for the filter.
 * @param {boolean} isMulti - Indicates whether the filter allows multiple selections.
 * @returns {Array} An array of default selected values or value.
 */
export function getDefaultSelectedOption(type, values, defaultValues, isMulti) {
  if (type === FILTER_TYPE_DATE_PRESETS) {
    return getDatePreset(defaultValues[0]);
  }

  return getDefaultSelectedValues(
    values?.map(filterValueMapper),
    defaultValues,
    isMulti
  );
}

/**
 * Returns the default options based on the filter type.
 * @param {string} type - The type of the filter.
 * @param {Array} values - Available values for the filter.
 * @param {Array} availableDatePresets - Available date presets for the filter.
 * @returns {Array} An array of default options.
 */
export function getDefaultOptions(type, values, availableDatePresets) {
  if (type === FILTER_TYPE_DATE_PRESETS) {
    return availableDatePresets.map((preset) => ({
      value: preset,
      label: getDatePreset(preset)?.label ?? "Unkown",
    }));
  }

  return values;
}

/**
 * Returns the preset label for a given preset value.
 * @param {string} preset - The preset value.
 * @returns {Object | undefined} The preset object or undefined if not found.
 */
function getDatePreset(preset) {
  return customFilterOptions.find((option) => option.value === preset);
}

/**
 * Converts a placeholder key into a formatted date string based on the current date.
 * We using this in menu filters for special default value
 * @param {string} key - A placeholder representing a specific date or date range.
 * @returns {string} - The calculated date in "yyyy-MM-dd" format or the original key if no match is found.
 */
export function convertCustomDefaultValue(key) {
  switch (key) {
    // Returns the current day in "yyyy-MM-dd" format
    case "[CURRENT_DAY]":
      return format(parseISO(pageLoadDateString), "MM/dd/yyyy");

    // Returns yesterday's date
    case "[YESTERDAY]":
      return format(subDays(parseISO(pageLoadDateString), 1), "MM/dd/yyyy");

    // Returns the start of the current week
    case "[START_OF_CURRENT_WEEK]":
      return format(startOfWeek(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the end of the current week
    case "[END_OF_CURRENT_WEEK]":
      return format(endOfWeek(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the start of the previous week
    case "[START_OF_PREVIOUS_WEEK]":
      return format(
        subWeeks(startOfWeek(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the end of the previous week
    case "[END_OF_PREVIOUS_WEEK]":
      return format(
        subWeeks(endOfWeek(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the start of the current month
    case "[START_OF_CURRENT_MONTH]":
      return format(startOfMonth(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the end of the current month
    case "[END_OF_CURRENT_MONTH]":
      return format(endOfMonth(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the start of the previous month
    case "[START_OF_PREVIOUS_MONTH]":
      return format(
        subMonths(startOfMonth(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the end of the previous month
    case "[END_OF_PREVIOUS_MONTH]":
      return format(
        subMonths(endOfMonth(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the start of the current quarter
    case "[START_OF_CURRENT_QUARTER]":
      return format(startOfQuarter(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the end of the current quarter
    case "[END_OF_CURRENT_QUARTER]":
      return format(endOfQuarter(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the start of the previous quarter
    case "[START_OF_PREVIOUS_QUARTER]":
      return format(
        subQuarters(startOfQuarter(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the end of the previous quarter
    case "[END_OF_PREVIOUS_QUARTER]":
      return format(
        subQuarters(endOfQuarter(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the start of the current year
    case "[START_OF_CURRENT_YEAR]":
      return format(startOfYear(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the end of the current year
    case "[END_OF_CURRENT_YEAR]":
      return format(endOfYear(parseISO(pageLoadDateString)), "MM/dd/yyyy");

    // Returns the start of the previous year
    case "[START_OF_PREVIOUS_YEAR]":
      return format(
        subYears(startOfYear(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Returns the end of the previous year
    case "[END_OF_PREVIOUS_YEAR]":
      return format(
        subYears(endOfYear(parseISO(pageLoadDateString)), 1),
        "MM/dd/yyyy"
      );

    // Default case: return the key itself if no match is found
    default:
      return key;
  }
}
