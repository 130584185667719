export default function prepareAttrs(
  attrs,
  menuFilters,
  fields,
  groupBy,
  data,
  requiresTotals
) {
  const nextAttrs = attrs.reduce((acc, attr) => {
    const { filterDependentValues, trendValues, extras } = attr;

    const overriddenValueKey = getFilterDependentValueKey(
      filterDependentValues,
      menuFilters
    );

    const overriddenFormula = getFilterDependentValueKey(
      trendValues?.filterDependentValues,
      menuFilters
    );

    const metaField = fields.find((f) => f.alias === attr.valueKey);
    const nextAttr = {
      ...attr,
      title: overriddenValueKey ?? attr.title ?? metaField?.label,
      valueKey: overriddenValueKey ?? attr.valueKey,
      numberFormat: attr.numberFormat ?? metaField?.type ?? "number",
      trendValues: overriddenFormula
        ? { ...attr.trendValues, formulas: [overriddenFormula] }
        : attr.trendValues,
      requiresTotals: !!requiresTotals,
      valueColor: extras?.valueColor,
    };
    if (attr.dateFormat) {
      nextAttr.dateFormat = attr.dateFormat;
      delete nextAttr.numberFormat;
    }

    const arr = groupBy
      ? data.map((d) => ({
          ...nextAttr,
          label: d[groupBy],
          groupBy,
        }))
      : [nextAttr];

    return [...acc, ...arr];
  }, []);

  return nextAttrs;
}

const getFilterDependentValueKey = (filterDependentValues, menuFilters) => {
  if (!filterDependentValues) {
    return;
  }

  const filter = menuFilters.find(
    (menuFilter) => menuFilter.name === filterDependentValues.name
  );

  const checkedValue = filter.values.find((value) => value.checked)?.value;
  return filterDependentValues[checkedValue];
};
