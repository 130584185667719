import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getVisualizationLabel from "../../utils/getVisualizationLabel";
import Th from "./Elements/Th";
import { getFrozenSettings, offsets } from "./functions/tableCellHelper";
import { getSortDirection } from "./functions/dynamicSorting";

const termSelectorMemoFn = (state) => state.layout.currentDateTerm;

export default React.memo(function ParentHeaders(props) {
  const term = useSelector(termSelectorMemoFn);

  const {
    headers,
    hasNumeration,
    subTitles,
    meta,
    freezeLeft,
    freezeWidth,
    allowGroupsRightBorder,
    staticGroupingKeys,
    stickyHeaders,
    parentHeaderPrefix = "",
    postfixes,
    hiddenGroupings,
    staticPostfixColumn,
    rows,
    isGroupingSortable,
    sortDynamicGrouping,
    groupingKeysAlign,
    maxColumnsWidth = {},
    parentHeaderSort,
    setParentHeaderSort,
    rowGroupKey,
    emptyPivotColumn,
  } = props;

  function generatePostfixes() {
    const { values } = rows[0];
    return ["", ...values.map((val) => val[staticPostfixColumn])];
  }

  const postfixOverrides = staticPostfixColumn
    ? generatePostfixes()
    : postfixes;

  const headersMemo = useMemo(() => {
    if (!hiddenGroupings?.length) {
      return headers;
    }

    return headers.filter((header) => !hiddenGroupings.includes(header));
  }, [hiddenGroupings, headers]);

  const makeSort = useCallback(
    (header) => {
      if (!isGroupingSortable) {
        return;
      }

      setParentHeaderSort((prev) => {
        const direction = getSortDirection(
          prev.header,
          header,
          meta,
          prev.direction
        );

        sortDynamicGrouping(header, direction);
        return {
          header,
          direction,
        };
      });
    },
    [isGroupingSortable, meta, setParentHeaderSort, sortDynamicGrouping]
  );

  const setTextAlign = useCallback(
    (header) => {
      if (!groupingKeysAlign) {
        return null;
      }

      return groupingKeysAlign[header] ?? groupingKeysAlign.others;
    },
    [groupingKeysAlign]
  );

  if (!headersMemo) {
    return null;
  }

  return (
    <tr>
      {headersMemo.map((item, index) => {
        const prefix = setPrefix(index, parentHeaderPrefix);
        const itemString = (item + "").toString();
        const actualHeader = rowGroupKey === itemString ? "" : itemString;

        const header = getDynamicHeader(
          actualHeader?.replace("::BORDERED", ""),
          term,
          meta,
          staticGroupingKeys
        );

        const postfix = setPostfix(postfixOverrides, index);

        // for monitoring history page
        const values = rows.map((row) => row?.values).flat();
        const keys = Object.keys(maxColumnsWidth ?? {});
        // parent headers for reteating groups is value not a column name
        const key = keys.find((key) => values.find((val) => val[key] === item));
        const sorting = item && parentHeaderSort?.header === item;

        // set frozen settings basxed on rowGroupKeys
        const { isFrozen, frozenWidth } = getFrozenSettings(!!offsets[item], {
          freezeLeft,
          freezeWidth,
          index,
        });

        return (
          <Th
            freeze={isFrozen}
            freezeWidth={frozenWidth}
            offsetLeft={offsets[item]}
            colSpan={getColSpan(subTitles, index)}
            key={item + "-" + index}
            background={getBackground(hasNumeration, index)}
            borderRight={
              allowGroupsRightBorder || itemString?.includes("::BORDERED")
            }
            stickyHeaders={stickyHeaders}
            onClick={() => makeSort(item)}
            pointer={isGroupingSortable}
            sorting={sorting}
            sortOrder={parentHeaderSort?.direction}
            textAlign={setTextAlign(item)}
            maxColumnsWidth={maxColumnsWidth[item]}
            strictWidth={maxColumnsWidth[key]}
            tableCell
            emptyPivotColumn={index === 0 && emptyPivotColumn}
          >
            {prefix && <div>{prefix}</div>}
            {header && (
              <div
                style={{
                  whiteSpace: header.includes(`\n`) ? "pre" : "inherit",
                }}
              >
                {header}
              </div>
            )}
            {postfix && <div>{postfix}</div>}
          </Th>
        );
      })}
    </tr>
  );
});

function setPrefix(index, parentHeaderPrefix) {
  return index === 0 ? "" : parentHeaderPrefix;
}

function setPostfix(postfixes, index) {
  if (!postfixes || !postfixes[index]) {
    return null;
  }

  return postfixes[index];
}

function getColSpan(subTitles, index) {
  return subTitles && subTitles[index] ? subTitles[index].length : 1;
}

function getBackground(hasNumeration, index) {
  return hasNumeration ? index % 2 === 0 && index !== 0 : index % 2;
}

function getDynamicHeader(header, term, meta, staticGroupingKeys) {
  if (header === "[DYNAMIC_DATE_TERM]") {
    return term;
  }

  return !staticGroupingKeys
    ? getVisualizationLabel(meta?.fields, header)
    : header;
}
