import React from "react";
import PropTypes from "prop-types";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";

const Label = styled.td`
  font-size: 12px;
  text-align: left;
  padding-bottom: 6px;
  color: ${(props) => props.color};
`;

const getMetaData = (fields, key) => {
  return (fields ?? []).find((meta) => meta.name === key || meta.alias === key);
};

export default function ChartTip(props) {
  const { config, tooltip, data, metaFields } = props;

  if (!config) return null;

  const { sourceData } = tooltip ?? {};
  const { flatValues, advanced } = config;

  // for pie chart
  function getPieTooltipRows() {
    return Object.entries(config).map((e) => ({
      label: e[1].label,
      value: tooltip.sourceData[e[0]],
      displayValue: formatter(tooltip.sourceData[e[0]], e[1].format),
    }));
  }

  // for line chart
  function getLineTooltipRows() {
    return config.tooltipRows.map((r) => {
      const meta = getMetaData(metaFields, r.key);

      if (!meta) return null;
      const key = meta.alias || meta.name;
      const type = r.typeOverride || meta.type;

      const values = data.allValues ?? {};
      const allData = values.allData ?? {};
      const tooltip = data.tooltip ?? [];

      const value =
        values[key] ??
        allData[key] ??
        tooltip.find((item) => item.key === key)?.value;

      return {
        label: r.label,
        value,
        displayValue: formatter(value, type),
      };
    });
  }

  // for bar chart
  function getBarTooltipRows() {
    const formatType = (row) => {
      const meta = getMetaData(metaFields, row.key);
      return row.typeOverride || meta?.type;
    };

    const tt = tooltip.tooltip[0];
    const getValueKey = (row) => {
      const meta = getMetaData(metaFields, row.key);
      return meta?.alias || tt.label || meta?.name;
    };

    const getDisplayValue = (row, value) => {
      const format = formatType(row);
      return formatter(value, format);
    };

    if (!tooltip.selectedRow) {
      return [];
    }

    return config.tooltipRows.map((row, i) => {
      const key = getValueKey(row);
      const value = tooltip.selectedRow[key] || (i === 0 ? tt.label : tt.value);
      const tooltipValue = data.tooltip.selectedRow;

      return {
        label: row.label || tt.label,
        value: value || tt.value || tooltipValue,
        displayValue: getDisplayValue(row, value),
      };
    });
  }

  // Stacked bars atm
  function getStackedBarTooltipRows() {
    const start = Object.entries(config)
      .filter((e) => e[1]?.label)
      .filter((e) => e[0] === tooltip.key); // tooltip.key is alias?

    const second = start.map((e, i) => ({
      label: e[1].label,
      value: data[e[0]],
      displayValue: formatter(data[e[0]], e[1].format),
    }));
    return second;
    // return Object.entries(config).map((e, i) => {
    //   console.log("yo:", { tooltip, e });
    //   const dataPart = e[0] === tooltip.key ? data : data.allData;
    //   const value = dataPart && dataPart[e[0]];
    //
    //   return {
    //     label: e[1]?.label,
    //     value: value,
    //     displayValue: value ? formatter(value, e[1]?.format) : "--",
    //   };
    // });
  }

  const getChartTypeKey = () => {
    if (sourceData) return "pie";
    if (flatValues) return "line";
    if (advanced) return "bar";

    return "stacked";
  };

  const chartTypeKeys = {
    pie: getPieTooltipRows,
    line: getLineTooltipRows,
    bar: getBarTooltipRows,
    stacked: getStackedBarTooltipRows,
  };

  const key = getChartTypeKey() ?? [];
  const rows = chartTypeKeys[key]() ?? [];

  return (
    <div>
      <table>
        <tbody>
          {tooltip.piePercent ? (
            <tr>
              <Label data-cy="chart-tip-label">
                Percent of total
                <br /> <b>{data.tooltip[0].value || "--"}</b>
              </Label>
            </tr>
          ) : null}
          {rows.filter(Boolean).map((item) => (
            <tr key={item.label}>
              {config.inlineTextStyle ? (
                <>
                  <Label data-cy="chart-tip-label" color={config.primaryColor}>
                    {item.label}:{" "}
                  </Label>
                  <Label
                    data-cy="chart-tip-value"
                    style={{ paddingLeft: 5 }}
                    color={config.primaryColor}
                  >
                    <b>{item.displayValue || item.value || "--"}</b>
                  </Label>
                </>
              ) : (
                <Label data-cy="chart-tip-label" color={config.primaryColor}>
                  {item.label}
                  <br /> <b>{item.displayValue || item.value || "--"}</b>
                </Label>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ChartTip.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
};
