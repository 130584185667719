import React, { useState } from "react";
import styled from "@emotion/styled";
import AddDot from "./AddDot";
import Flex from "../../Flex/Flex";
import PropTypes from "prop-types";
import produce from "immer";
import { isNumber } from "lodash-es";
import InputSetRow from "./InputSetRow";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 14px;
`;

export default function InputSet(props) {
  const [adding, setAdding] = useState(!props.values.length);
  const [tempValue, setTempValue] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const { showLabels, options, listMode, loading } = props;

  function handleChange(e, key) {
    setTempValue({ ...tempValue, [key]: e.target.value });
  }

  function commitChange(index, key, value) {
    const hasNextArray = shouldSendNewArray(index, key, value);
    if (hasNextArray) {
      const nextArray = assembleNextArray(value);

      props.onChange(nextArray);
    }
    reset();

    function assembleNextArray(value) {
      const v = value ?? tempValue;
      const nextArray = produce(props.values, (draft) => {
        if (isNumber(index)) {
          if (v[key] === "") {
            delete draft[index][key];
          } else {
            draft[index][key] = v[key];
          }
        } else {
          draft.push(v);
        }
      });
      return nextArray.filter((v) => v);
    }
  }
  function shouldSendNewArray(index, key, value) {
    const v = value ?? tempValue;
    const isExistingValue = isNumber(index);

    if (isExistingValue && props.values[index][key] === v[key]) {
      return false;
    } else if (!isExistingValue && !v[key]) {
      return false;
    } else return true;
  }

  function reset() {
    setTempValue({});
    setEditingIndex(null);
    return setAdding(false);
  }

  function handleRemove(index) {
    const nextArray = props.values.filter((v, i) => i !== index);
    return props.onChange(nextArray);
  }

  function startEdit(index) {
    setTempValue(props.values[index]);
    setEditingIndex(index);
  }

  function handleEnter(e, index, key) {
    if (e.key === "Enter" || e.code === "13") {
      e.preventDefault();
      return commitChange(index, key);
    }
  }

  return (
    <Container data-cy={props.cy ?? "input-set"}>
      <div style={{ width: "100%" }}>
        {props.values.map((item, i) => (
          <Flex
            style={{ marginBottom: 8 }}
            key={item.value + i}
            justifyContent="flex-start"
          >
            <InputSetRow
              handleRemove={handleRemove}
              editingIndex={editingIndex}
              commitChange={commitChange}
              startEdit={startEdit}
              showLabels={showLabels}
              item={item}
              tempValue={tempValue}
              i={i}
              handleChange={handleChange}
              handleEnter={handleEnter}
              listMode={listMode}
              reset={reset}
              options={options}
              setTempValue={setTempValue}
              loading={loading}
            />
          </Flex>
        ))}
        {adding ? (
          <Flex
            style={{ marginBottom: 8, paddingLeft: 39, marginTop: 8 }}
            justifyContent="flex-start"
          >
            <InputSetRow
              tempValue={tempValue}
              item={null}
              addingMode
              showLabels={showLabels}
              handleChange={handleChange}
              listMode={listMode}
              options={options}
              handleEnter={handleEnter}
              setTempValue={setTempValue}
              commitChange={commitChange}
              startEdit={startEdit}
              cy="input-set-value-new"
            />
          </Flex>
        ) : null}
      </div>
      {!adding ? (
        <div style={{ marginLeft: 120 }}>
          <AddDot
            cy={"input-set-add"}
            relative
            onClick={() => setAdding(true)}
          />
        </div>
      ) : null}
    </Container>
  );
}

InputSet.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array,
  cy: PropTypes.string,
};

InputSet.defaultProps = {
  values: [],
};
