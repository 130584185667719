import React, { Fragment } from "react";
import { scaleBand, scaleLinear } from "d3-scale";
import YAxis from "../Axes/YAxis";
import { useTheme } from "emotion-theming";
import SpringBar from "./SpringBar";
import { tooltipOffsets } from "../../utils/constants/constants";
import { termMap } from "../../utils/charts/xKeyParser";
import SpringGroup from "./SpringGroup";
import formatter from "../../utils/formatters/formatter";
import StackedLinesVisualization from "../StackedBar/StackedLinesVisualization";
import SimpleXAxis from "../SimpleXAxis";
import { mapTooltipConfig } from "../Tooltip/mapTooltipConfig";
import { getColor } from "../BaseChart/mapColorConfig";
import { getMappingType } from "../../utils/getVisualizationLabel";
import TargetLine from "../TargetLine/TargetLine";

export default function GroupedBar(props) {
  const {
    data,
    colors,
    width,
    height,
    seriesItemKeys,
    groupBy,
    groupByTerm,
    allXKeys,
    setTooltip,
    meta,
    yKeys,
    yAxisFormat,
    term,
    withNegative,
    showLabel,
    labelFormat,
    xKey,
    relativeY,
    lineKeys,
    rawData,
    maxYScale,
    valueKeys,
    groupByMode,
    maxMinValues,
  } = props;

  const theme = useTheme();

  const yDomain = [maxMinValues.min, maxMinValues.max];

  if (relativeY) {
    yDomain[0] = maxMinValues.min * 0.95;
  } else if (maxMinValues.min >= 0) {
    yDomain[0] = 0;
  } else {
    yDomain[0] *= 1.05;
  }
  // For zero zero we need to have a fake domain
  if (yDomain[0] === 0 && yDomain[1] === 0) yDomain[1] = 1;

  if (maxYScale) {
    yDomain[1] = maxYScale;
  } else {
    yDomain[1] = yDomain[1] * 1.05;
  }

  const y = scaleLinear().domain(yDomain).range([height, 10]);

  const getMaxValueForKeys = (dataArray, keysToInclude) => {
    let max = -Infinity; // Initialize with a very low value
    dataArray.forEach((currentObj) => {
      keysToInclude.forEach((key) => {
        if (key in currentObj) {
          max = Math.max(max, currentObj[key]);
        }
      });
    });
    return max;
  };
  const maxLine = getMaxValueForKeys(
    rawData,
    lineKeys.map((l) => l.alias)
  );
  const lineScale = scaleLinear().domain([0, maxLine]).range([height, 10]);

  const xOuter = scaleBand().domain(allXKeys).range([0, width]);

  const xInner = scaleBand()
    .domain(yKeys || seriesItemKeys)
    .range([0, width / data.length])
    .paddingOuter(0.8)
    .paddingInner(0.3);

  const matched = (da, key, key2, index, isDate, fullRow) => {
    if (yKeys && !isDate) {
      return da.values[index]?.value;
    }

    const match = da.values.find(
      (daa) => daa[groupByTerm ? termMap[term] + groupBy : groupBy] === key
    );
    if (fullRow) {
      return match;
    } else {
      return match ? (key2 ? match[key2] : match.value) : 0;
    }
  };

  const getFormat = yAxisFormat || ".2s";

  const getYPos = (da, sik, index) => {
    const val = matched(da, sik, null, index);
    if (isNaN(val)) return 0;
    return y(Math.max(val, 0));
  };

  const getHeight = (da, sik, index) => {
    const val = matched(da, sik, null, index);
    if (isNaN(val)) return 0;

    if (val >= 0) {
      if (relativeY) {
        const zerPoint = y.domain()[0];
        return y(zerPoint) - y(val);
      } else {
        return y(0) - y(val);
      }
    } else {
      return y(val) - y(0);
    }
  };

  const doFormat = (val, sik) => {
    if (isNaN(val)) return "--";
    const keys = valueKeys ?? [];
    const name = keys.find((key) => key.alias === sik)?.name;
    const firstAvailableName = (keys[0] ?? {}).name;
    const type = getMappingType(meta?.fields, name || firstAvailableName);

    // todo: get rid of labelFormat
    const currFormat = labelFormat || type || yAxisFormat;
    return formatter(val, currFormat);
  };

  const rotateBarLabels = width / data.length < 45;

  function handleTooltip(da, dai, sik, keyIndex) {
    const xPos = xOuter(da.key) + xInner(sik) + (xInner.bandwidth() / 2) * 0.8;
    const yPos = y(matched(da, sik, null, keyIndex)) + tooltipOffsets.y;
    const tt = mapTooltipConfig(
      xPos,
      yPos,
      da.values[0],
      sik,
      xKey,
      groupBy,
      meta,
      null, // update this to real row
      groupByMode,
      valueKeys
    );
    return setTooltip(tt);
  }

  function limitBarsToBarsWithDataValues(dataRow) {
    const rawKeys = yKeys || seriesItemKeys;
    return rawKeys.filter(
      (k) => dataRow.values[0]?.[k] || dataRow.values[0]?.[k] === 0
    );
  }

  return (
    <g data-cy="grouped-bar-container">
      <YAxis
        {...props}
        yScale={y}
        yTicksCount={props.yTicksCount || 5}
        yTicksColor={props.yTicksColor}
        hideYAxisLine={props.hideYAxisLine}
        hideYAxisTicks={props.hideYAxisTicks}
        yAxisGrid={props.yAxisGrid}
        yAxisGridColor={props.yAxisGridColor || theme.divider}
        width={width}
        yAxisFormat={".2s"}
        theme={theme}
      />
      <SimpleXAxis
        width={width + 15}
        height={height + 1}
        xScale={xOuter}
        values={allXKeys}
        xKeyFormat={props.xKeyFormat}
        bands={true}
        allTicks
      />
      {data.map((dataRow, dai) => (
        <g key={dataRow.key + "-" + dai}>
          <g transform={`translate(${xOuter(dataRow.key)}, 0)`}>
            {/*individual positive bars*/}
            {limitBarsToBarsWithDataValues(dataRow).map((sik, keyIndex, s) => (
              <Fragment key={keyIndex + "-" + sik + "-" + dataRow.key}>
                <SpringBar
                  x={xInner(sik)}
                  y={getYPos(dataRow, sik, keyIndex)}
                  startPos={height}
                  height={getHeight(dataRow, sik, keyIndex)}
                  width={xInner.bandwidth()}
                  color={getColor(colors, sik, dataRow.key)}
                  onMouseOver={() => handleTooltip(dataRow, dai, sik, keyIndex)}
                  onMouseOut={() => setTooltip(null)}
                  useLines={data.length > 50}
                  skipAnimation={data.length > 50}
                />
                {showLabel && (
                  <SpringGroup
                    y={getYPos(dataRow, sik, keyIndex) - 6}
                    x={xInner(sik) + xInner.bandwidth() / 2}
                    skipAnimation
                  >
                    {/*Adjustment value of 0.43 should not be here*/}
                    {/*xInner only exists when there are series item keys*/}
                    <text
                      data-cy="grouped-bar-label"
                      textAnchor="middle"
                      fontSize="9px"
                      fontWeight="bold"
                      fill={theme.text.secondary}
                      transform={
                        rotateBarLabels ? "translate(6, -10) rotate(-90)" : null
                      }
                    >
                      {doFormat(matched(dataRow, sik, null, keyIndex), sik)}
                    </text>
                  </SpringGroup>
                )}
              </Fragment>
            ))}
          </g>
        </g>
      ))}
      {y.domain()[0] < 0 ? (
        <TargetLine
          width={width}
          y={y}
          target={0}
          thickness={1}
          color="#499bff"
        />
      ) : null}

      {lineKeys?.length ? (
        <StackedLinesVisualization
          initialData={rawData}
          data={data}
          xKey={xKey}
          width={width}
          height={height}
          xScale={xOuter}
          xInner={xInner}
          yScale={lineScale}
          hideAxis={!!props.useSameYAxisScale}
          colors={colors}
          lineKeys={lineKeys}
          meta={meta}
          setTooltip={setTooltip}
          log
        />
      ) : null}
    </g>
  );
}

GroupedBar.defaultProps = {
  xFormat: "",
};
