import { lightenDarkenColor } from "./colorConvertor";

const bg = (type) => {
  return type === "primary" ? "secondary" : "primary";
};

export default (
  theme,
  range = null,
  belowValue = "primary",
  overPrimary,
  menuHeight
) => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? overPrimary
        ? "white"
        : theme.textOnPrimary
      : theme.text.secondary,
    fontSize: "0.8em",
    borderBottom: "1px solid " + theme.divider,
    background: state.isSelected
      ? overPrimary
        ? theme.primary
        : lightenDarkenColor(theme.primary, 30)
      : state.isFocused
      ? theme.background[bg(belowValue, theme.name)]
      : theme.background[bg(belowValue, theme.name)],
    "&:hover": {
      background: lightenDarkenColor(theme.primary, 30),
      color: theme.text.textOnPrimary,
    },
  }),
  control: (provided) => ({
    ...provided,
    margin: "0 1px",
    border: `1px solid ${range && range.notValidDate ? "red" : theme.divider}`,
    backgroundColor: overPrimary
      ? lightenDarkenColor(theme.primary, 14)
      : theme.background.primary,
    input: {
      opacity: "1 !important",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.text.secondary,
    background: overPrimary ? "none" : theme.background.primary,
    fontSize: "0.8em",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: overPrimary ? "white" : theme.text.secondary,
    fontSize: "0.8em",
  }),
  menu: (provided) => ({
    ...provided,
    background: theme.background[bg(belowValue, theme.name)],
    color: theme.text.secondary,
    textAlign: "left",
    zIndex: 9999,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    background: overPrimary ? "white" : theme.text.secondary,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.text.secondary,
    "&:hover": {
      color: overPrimary
        ? theme.textOnPrimary // Dark mode hover color
        : lightenDarkenColor(theme.text.secondary, 30), // Hover color for light mode
    },
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: "hidden",
    maxHeight: menuHeight ?? 200,
  }),
  input: (provided) => ({
    ...provided,
    color: overPrimary ? "white" : theme.text.secondary,
  }),
  group: (provided) => ({
    ...provided,
    marginLeft: 10,
  }),
  groupHeading: (provided) => ({
    ...provided,
    marginLeft: -10,
  }),
});
