import formatter from "../../utils/formatters/formatter";

export function mapTooltipConfig(
  xPos,
  yPos,
  matchedRow,
  valueAlias,
  xKeyAlias,
  groupByAlias, // @todo remove this
  meta,
  dataRow,
  groupByMode,
  valueKeys
) {
  const valueMatch = groupByMode ? valueKeys[0].alias : valueAlias;

  const valueMetaAttempt = meta.fields.find((m) => m.alias === valueMatch);
  const valueMeta =
    valueMetaAttempt || meta.fields.find((m) => m.alias === valueKeys[0].alias);

  const valueLabel = valueMeta?.label;

  const matchedValue = matchedRow[valueAlias] || matchedRow[valueMatch]; //@todo, I don't like guessing like this
  const valueDisplay = formatter(matchedValue, valueMeta.type);
  const xMeta = meta.fields.find(
    (m) => m.alias === xKeyAlias || m.name === xKeyAlias
  );

  const xLabel = xMeta.label;

  const xValue = matchedRow[xKeyAlias] || valueAlias;
  const xDisplay = formatter(xValue, xMeta.type);
  const tooltipRows = [
    { label: valueLabel, value: valueDisplay },
    { label: xLabel, value: xDisplay },
  ];

  if (groupByMode) {
    const groupByMeta = meta.fields.find(
      (m) => m.alias === groupByMode || m.name === groupByMode
    );
    const groupByLabel = groupByMeta.label;
    tooltipRows.push({ label: groupByLabel, value: valueAlias });
  }

  return {
    x: xPos,
    y: yPos,
    tooltip: tooltipRows,
    allValues: dataRow,
  };
}
