import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { ChromePicker } from "react-color";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Container = styled.div`
  position: relative;
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
`;

const ColorBox = styled.div`
  background: ${(props) => props.color};
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.divider};
  width: 44px;
  height: 18px;
  padding: 3px;
  background-clip: content-box, padding-box;
`;

const Picker = styled.div(
  ({ offsetLeft }) => `
  position: absolute;
  z-index: 2;
  left: ${offsetLeft}px;
`
);

export default function ColorPicker({
  initialColor = "#000000",
  onChangeComplete,
  type = "hex",
  label,
  isDisabled,
  offsetLeft = 0,
}) {
  const ref = useRef(null);

  const [color, setColor] = useState(initialColor);
  const [showPicker, setShowPicker] = useState(false);

  useOnClickOutside(ref, () => setShowPicker(false));

  function setShow() {
    if (isDisabled) {
      return;
    }

    setShowPicker(!showPicker);
  }

  return (
    <Container ref={ref} isDisabled={isDisabled}>
      {label && <span>{label}</span>}
      <ColorBox color={color} onClick={setShow} data-cy="color-picker" />
      {showPicker && (
        <Picker offsetLeft={offsetLeft}>
          <ChromePicker
            color={color}
            onChange={(colors) => setColor(colors[type])}
            onChangeComplete={(colors) => onChangeComplete(colors[type])}
          />
        </Picker>
      )}
    </Container>
  );
}
