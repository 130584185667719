import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileExcel,
  faFileWord,
} from "@fortawesome/pro-light-svg-icons";
import { ACTIVE_TABLE_FILE_TYPE_MAP } from "../../../utils/activeTable";

const LabelSpan = styled.span`
  path {
    fill: ${(props) => props.theme.text.primary} !important;
  }
`;

const Label = (props) => {
  return (
    <LabelSpan>
      <FontAwesomeIcon
        style={{ fontSize: 16, position: "relative", top: 1 }}
        icon={typeof props.icon === "string" ? ["fal", props.icon] : props.icon}
      />
      {props.text}
    </LabelSpan>
  );
};

export const typeOptions = [
  {
    label: <Label icon="list-ol" text="Number" />,
    value: "decimal",
    displayOptions: [
      {
        label: <Label icon="tally" text="No format (2030)" />,
        value: "string",
      },
      {
        label: <Label icon="list-ol" text="Whole number (2,030)" />,
        value: "integer",
      },
      {
        label: <Label icon="list-ol" text="1 decimal (2,030.0)" />,
        value: "decimal-tenth",
      },
      {
        label: <Label icon="list-ol" text="2 decimal (2,030.00)" />,
        value: "decimal",
      },
    ],
  },
  {
    label: <Label icon="toggle-on" text="Boolean" />,
    value: "boolean",
  },
  {
    label: <Label icon="calendar" text="Date" />,
    value: "date",
  },
  {
    label: <Label icon="text-size" text="String" />,
    value: "string",
  },
  {
    label: <Label icon="text-width" text="Text (Deprecated)" />,
    value: "text",
    deprecated: true,
  },
  {
    label: <Label icon="file-invoice-dollar" text="Currency" />,
    value: "currency",
    displayOptions: [
      {
        label: <Label icon="dollar-sign" text="Whole number ($2,030)" />,
        value: "currency-whole",
      },
      {
        label: <Label icon="dollar-sign" text="1 decimal ($2,030.0)" />,
        value: "currency-tenth",
      },
      {
        label: <Label icon="dollar-sign" text="2 decimal ($2,030.00)" />,
        value: "currency",
      },
    ],
  },
  {
    label: <Label icon="image" text="Image" />,
    value: "image",
  },
  {
    label: <Label icon={faFilePdf} text="PDF" />,
    value: "api-pdf",
  },
  {
    label: <Label icon={faFileExcel} text="Excel" />,
    value: "api-excel",
  },
  {
    label: <Label icon={faFileWord} text="Word" />,
    value: "api-word",
  },
  {
    label: (
      <Label
        icon={ACTIVE_TABLE_FILE_TYPE_MAP["api-document"].light_icon}
        text="Any Document"
      />
    ),
    value: "api-document",
  },
];

export const editOptions = [
  {
    label: <Label icon="keyboard" text="Text entry" />,
    value: "string",
  },
  {
    label: <Label icon="list-alt" text="List select" />,
    value: "select",
  },
];

const defaultDisplayOptions = [
  {
    label: <Label icon="text" text="Default" />,
    value: "default",
  },
];

function findInDisplayOptions(options, colDef) {
  return (options ?? []).some(
    (display) =>
      display.value === colDef.cellEditorParams?.displayFromatOverride
  );
}

export function getTypeAndDisplayOptions(colDef) {
  const { type, cellEditorParams } = colDef;

  const option = typeOptions.find(
    (option) =>
      option.value === type ||
      findInDisplayOptions(option.displayOptions, colDef)
  );

  const { value, displayOptions } = option ?? {};

  return {
    type: value,
    displayOptions: displayOptions ?? defaultDisplayOptions,
    displayOptionType:
      cellEditorParams?.displayFromatOverride ?? type ?? "default",
  };
}
