import React, { useState, useEffect, useRef } from "react";
import { ImageWithTipper } from "../RenderAsImage/renderAsImage";
import Loading from "../../../UI/Loading/Loading";
import Flex from "../../../UI/Flex/Flex";
import { addSuffixToFilePath } from "../../../utils/func";
import Modal from "../../../UI/Modal/Modal";
import warehouseImages from "../../../warehouseImages";

export default function ApiImage({ value, blockZoom, contain }) {
  const [src, setSrc] = useState(null);
  const [fullSrcState, setFullSrcState] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const abortControllerRef = useRef();
  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  function loadFullSizedImage() {
    if (fullSrcState.loading || fullSrcState.data) {
      return;
    }

    warehouseImages
      .get(value, {
        responseType: "arraybuffer",
        signal: abortControllerRef.current.signal,
      })
      .then(convertResponseToInlineImage)
      .then((src) => {
        setFullSrcState({
          loading: false,
          error: null,
          data: src,
        });
        setError(null);
      })
      .catch((err) => {
        if (err?.message !== "canceled") {
          reportError(err);
        }

        setError("Failed to load image.");
      });
  }

  function openModal() {
    setModalOpen(true);
    loadFullSizedImage();
  }

  useEffect(() => {
    warehouseImages
      .get(addSuffixToFilePath(value, "_thumb"), {
        responseType: "arraybuffer",
        signal: abortControllerRef.current.signal,
      })
      .then(convertResponseToInlineImage)
      .then((src) => {
        setSrc(src);
        setError(null);
      })
      .catch((err) => {
        if (src) {
          return;
        }
        if (err?.message !== "canceled") {
          reportError(err);
        }

        setError("Failed to load image.");
      });
  }, [src, value]);

  /** C-Life thumbnail width */
  const width = 50;
  const marginAdjustmentToMakeLoadingSpinnerNotJump = 23;
  const height =
    width * (1 / clifeWidthHeightRatio) +
    marginAdjustmentToMakeLoadingSpinnerNotJump;

  if (error) {
    return error;
  }

  if (!src) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{ width, height }}
      >
        <Loading size={width <= 57 || height <= 57 ? "small" : undefined} />
      </Flex>
    );
  }

  return (
    <div>
      {modalOpen && (
        <Modal close={() => setModalOpen(false)} renderInPortal>
          {fullSrcState.data ? (
            <img
              alt="large"
              src={fullSrcState.data}
              style={{ maxWidth: "100%" }}
            />
          ) : (
            <Loading />
          )}
        </Modal>
      )}
      <ImageWithTipper
        alt="Image"
        src={src}
        width={width}
        tipperLabel={
          !fullSrcState.data ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "white", width: 420, height: 420 }}
            >
              <Loading />
            </Flex>
          ) : null
        }
        tipperSrc={fullSrcState.data}
        className="clickable"
        onMouseEnter={loadFullSizedImage}
        onClick={!blockZoom ? openModal : null}
        contain={contain}
      />
    </div>
  );
}

export function convertResponseToInlineImage(response) {
  let mimeType = response.headers["content-type"];
  if (mimeType === "binary/octet-stream") {
    mimeType = "image/png";
  }

  const base64ImageData = btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );

  return `data:${mimeType};base64,${base64ImageData}`;
}

const clifeWidthHeightRatio = 1651 / 1275;
