import React, { useMemo } from "react";
import Loading from "../../UI/Loading/Loading";
import { useTheme } from "emotion-theming";
import SingleDataSourceMenuFiltersItem from "./SingleDataSourceMenuFiltersItem";
import Flex from "../../UI/Flex/Flex";
import { systemColumns } from "../../charts/ActiveTable/useActiveTableSettings";
import { useSelector } from "react-redux";
import {
  emptyObject,
  FILTER_TYPE_MULTI_RANGE,
} from "../../utils/constants/constants";
import useSingleDataSourceFilters from "./useSingleDataSourceFilters";

export default function SingleDataSourceMenuFilters({
  additionalTheme,
  dataSourceAccessConfig,
  select,
  pageUuid,
}) {
  const singleDataSourceFilters = useSingleDataSourceFilters(pageUuid);
  const theme = useTheme();
  const { loading, data, error, queryUuid, loadSingleFieldByName } =
    singleDataSourceFilters;

  const selectedValuesByFieldName = useSelector(
    (state) =>
      state.layout.singleQueryFiltersByQueryUuid[queryUuid]?.fieldsByName ??
      emptyObject
  );

  const filteredData = useMemo(() => {
    return (data ?? []).filter(
      (item) => !systemColumns.includes(item.fieldName)
    );
  }, [data]);

  const selectedValuesOfFields = useMemo(
    () =>
      filteredData
        ?.map((row) => selectedValuesByFieldName?.[row.fieldName])
        .filter(
          (v) =>
            v &&
            v.selectedValues.length &&
            v.filterType !== FILTER_TYPE_MULTI_RANGE
        ),
    [filteredData, selectedValuesByFieldName]
  );

  if (loading || !data) {
    return (
      <Flex>
        <Loading />
      </Flex>
    );
  }

  if (error) {
    return (
      <div style={{ color: theme.notification.errorMain }}>{error.message}</div>
    );
  }

  return (
    <div>
      {filteredData.map((row) => (
        <SingleDataSourceMenuFiltersItem
          key={row.fieldName}
          dataSourceAccessConfig={dataSourceAccessConfig}
          additionalTheme={additionalTheme}
          select={select}
          item={row}
          queryUuid={queryUuid}
          loadSingleFieldByName={loadSingleFieldByName}
          selectedValuesOfFields={selectedValuesOfFields}
        />
      ))}
    </div>
  );
}
