import React from "react";
import PropTypes from "prop-types";
import SpringGroup from "./GroupedBar/SpringGroup";
import styled from "@emotion/styled";
import mobileBreakpoints from "../styles/mobileBreakpoints";
import formatter from "../utils/formatters/formatter";
import { getMappingType } from "../utils/getVisualizationLabel";

const Label = styled.text`
  fill: ${(props) => props.theme.text.secondary};
  text-anchor: middle;
  font-size: 9px;
  font-weight: 700;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    transform: rotate(-90deg);
  }
`;

export default function BarLabels(props) {
  const {
    x,
    y,
    values,
    yFormat = ".2s",
    skipAnimation,
    xInner,
    meta,
    valueKeys,
  } = props;

  if (values.length > 100) return null;
  const offset = {
    x: mobileBreakpoints.isMobile ? 4 : values.length > 12 ? 4 : 0,
    y: mobileBreakpoints.isMobile ? -20 : values.length > 12 ? -10 : 0,
  };

  const orientation = values.length > 12 ? "left" : "middle";
  function xPos(v) {
    return x(v.x) + x.bandwidth() / 2 + xInner.bandwidth() / 2;
  }

  return values.map((v, i) => {
    const type = getMappingType(
      meta?.fields,
      ((valueKeys ?? [])[0] ?? {}).name
    );

    const formatted = formatter(v.labelTotal || v.total, type || yFormat);

    return (
      <SpringGroup
        skipAnimation={skipAnimation}
        key={v.x + "-" + i}
        x={xPos(v)}
        y={y(Math.round(v.total)) - 6 + offset.y}
      >
        <Label
          data-cy="bar-label"
          style={{
            textAnchor: orientation,
            fontSize: 9,
            fontWeight: 700,
            transform: `rotate(${
              values.length > 12 || mobileBreakpoints.isMobile
                ? "-90deg"
                : "0deg"
            })`,
          }}
        >
          {(formatted + "").replace("G", "B")}
        </Label>
      </SpringGroup>
    );
  });
}

BarLabels.propTypes = {
  x: PropTypes.func,
  y: PropTypes.func,
  values: PropTypes.array,
};
