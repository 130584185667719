import IOButton from "../../UI/Form/Button/IOButton";
import {
  customFilterOptions,
  localStorageFiltersKey,
} from "../../utils/constants/constants";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { useTheme } from "emotion-theming";
import menuFilterSettingsFormatConverter from "../../utils/formatConverters/menuFilterSettingsFormatConverter";
import {
  saveMenuFilterSettings,
  saveDateFilterSettings,
} from "../../store/actions/auth";
import { getFiltersFromLocalStorage } from "../../utils/browser";
import { useShallowEqualSelector } from "../../store";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  padding-bottom: 35px;
  justify-content: center;
  width: 100%;
  color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.secondary};
  button {
    font-size: 12px;
  }
`
);

const SaveFiltersToApi = ({ filters = [], activeTab }) => {
  const dispatch = useDispatch();

  const { menuFilterSettingsData, currentDateTerm, dateFilters } =
    useShallowEqualSelector((state) => ({
      menuFilterSettingsData: state.auth.menuFilterSettings.data,
      currentDateTerm: state.layout.currentDateTerm,
      dateFilters: state.layout.dateFilters,
    }));

  const dateFilterType = customFilterOptions.find(
    (option) => `[${option.label}]` === currentDateTerm
  );

  const theme = useTheme();
  const filtersAndActiveTabAvailable = Array.isArray(filters) && activeTab;

  const selectedFilters = filtersAndActiveTabAvailable
    ? filters
        .filter((filter) => !(filter.hide || []).includes(activeTab.uuid))
        .reduce((acc, curr) => {
          const checkedValues = curr.values.filter((value) => value.checked);

          if (checkedValues.length) {
            acc[curr.uuid] = checkedValues.map((val) => val.value);
          }

          return acc;
        }, {})
    : null;

  useEffect(() => {
    // Saving deprecated localStorage settings to the API.
    const filtersFromLocalStorage = getFiltersFromLocalStorage();
    if (
      menuFilterSettingsFormatConverter.local.isEmpty(
        filtersFromLocalStorage
      ) ||
      !menuFilterSettingsFormatConverter.response.isEmpty(
        menuFilterSettingsData
      )
    ) {
      return;
    }
    (async () => {
      await dispatch(saveMenuFilterSettings(filtersFromLocalStorage));
      localStorage.removeItem(localStorageFiltersKey);
    })();
  }, [dispatch, menuFilterSettingsData]);

  if (!filtersAndActiveTabAvailable) {
    return null;
  }

  const saveFiltersToApi = () => {
    dispatch(saveMenuFilterSettings(selectedFilters));
    if (!dateFilterType && currentDateTerm === "[Selected Range]") {
      // This date range type is currently not supported.
      return;
    }
    dispatch(saveDateFilterSettings({ dateFilterType, dateFilters, dispatch }));
  };

  return (
    <Container>
      <IOButton
        onClick={saveFiltersToApi}
        smallPadding
        cy="save-filters-button"
        outline
        bgPrimary={theme.menuPrimary}
        type="button"
      >
        REMEMBER SETTINGS
      </IOButton>
    </Container>
  );
};

SaveFiltersToApi.propTypes = {
  filters: PropTypes.array.isRequired,
};

export default React.memo(SaveFiltersToApi);
