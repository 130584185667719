import React, { useState } from "react";
import VisualizationBase from "../BaseChart/VisualizationBase";
import Details from "../../Layout/Details/Details";
import {
  assignAsKey,
  groupByKey,
  pipe,
  groupUnder,
  doIfElse,
  doIf,
  divided,
} from "../../utils/func";
import MarkerToggleSwitch from "../LineChart/MarkerToggleSwitch";
import LineLegend from "../MultipleLineChart/LineLegend";
import LineBarChart from "./LineBarChart";
import convertDateAggStrings from "../../utils/dates/convertDateAggStrings";
import { toDateType } from "../../utils/dates/dateFunc";

export const hasAttr = (attrs, key) => attrs && attrs.find((a) => a === key);
const formulaFns = { division: divided };

export default function LineBarChartVisualization(props) {
  const { chart, details, dashboardId } = props;
  const {
    lineValueKeys = [],
    xKey,
    groupBy,
    data,
    curvedKeys,
    dashedKeys,
    visualizationId,
    legendItems,
    colors,
    meta,
    defaultColors,
    formula = {},
    convertToDate,
    legendLinks,
    xKeys,
    margin,
  } = chart;

  const chartMargin = { top: 20, left: 40, bottom: 24, right: 30, ...margin };
  const [showCircles, setShowCircles] = useState(true);
  const [detailsMarginTop, setDetailsMarginTop] = useState(0);

  const { type, columns, columnName } = formula;
  const d = convertDateAggStrings(convertToDate)(data);

  const calculated = type
    ? d
        .map((d) => ({
          ...d,
          [columnName]: formulaFns[type]([d[columns[0]], d[columns[1]]]),
        }))
        .filter((d) => !isNaN(d[columnName]))
    : d;

  const initialLineGroups = pipe(
    assignAsKey(xKeys || xKey, "xValue"),
    toDateType("xValue", null, null, true),
    doIf(groupBy, assignAsKey(lineValueKeys[0], "value")),
    doIfElse(
      groupBy,
      groupByKey(groupBy, !!xKeys),
      groupUnder(lineValueKeys, [], xKeys)
    )
  )(calculated);

  const lineGroups = initialLineGroups.map((l) => ({
    ...l,
    curved: hasAttr(curvedKeys, l.key),
    dashed: hasAttr(dashedKeys, l.key),
    values: l.values.map((v) => ({ ...v, value: +v.value })),
  }));

  return (
    <>
      <div id={visualizationId} style={{ position: "relative" }}>
        <VisualizationBase {...{ ...props, margin: chartMargin }}>
          <LineBarChart
            {...chart}
            data={calculated}
            showCircles={showCircles}
            lineGroups={lineGroups}
            width={chart.width + chart.offsetWidth}
            precision={chart.precision}
          />

          <LineLegend
            foreignObject
            legendItems={legendItems}
            lineGroups={lineGroups}
            chartColors={colors}
            fieldMapping={meta && meta.fields}
            defaultColors={defaultColors}
            legendLinks={legendLinks}
            setDetailsMarginTop={setDetailsMarginTop}
          />
        </VisualizationBase>
        <MarkerToggleSwitch
          onClick={() => setShowCircles(!showCircles)}
          active={showCircles}
        />
      </div>

      {details && data.length > 0 ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={visualizationId}
          marginTop={detailsMarginTop}
          hideDetailLink={chart.hideDetailLink}
          chart={chart}
        />
      ) : null}
    </>
  );
}
