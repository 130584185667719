import React, { useMemo } from "react";
import IOButton from "../../../UI/Form/Button/IOButton";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  margin-top: ${(props) => (props.selectedColumn ? 18 : 60)}px;
  svg {
    margin-right: 6px;
    position: relative;
    left: -4px;
    top: -1px;
  }
`;
export default function ActionSettingsButtons(props) {
  const {
    updateSettings,
    isDirty,
    handleSetDeleteColumn,
    activeColumn,
    setAllLocked,
    columnCount,
    columnErrorsObject,
    colDef,
    activeTableUpdateLoading,
    editing,
  } = props;

  const isInvalid =
    !!columnErrorsObject && !!Object.values(columnErrorsObject).length;

  const errorsTitleMessage = useMemo(() => {
    return Object.values(columnErrorsObject ?? {})
      .map((errorStrings) => errorStrings.join("\n"))
      .join("\n");
  }, [columnErrorsObject]);

  const cantSaveMessage =
    errorsTitleMessage ||
    (!isDirty && "There are no changes to save.") ||
    (activeTableUpdateLoading && "Currently saving/loading.") ||
    (editing && "Currently editing a field.");

  return (
    <Container selectedColumn={!!activeColumn}>
      <div
        style={{
          width: 110,
          marginRight: 10,
        }}
      >
        <IOButton
          cy="delete-field"
          fit
          color="#ff704f"
          onClick={handleSetDeleteColumn}
          disabled={
            !!(!activeColumn || columnCount === 1 || colDef?.queryOnly) ||
            activeTableUpdateLoading
          }
        >
          <FontAwesomeIcon icon={["fal", "trash"]} />
          Delete
        </IOButton>
      </div>
      <IOButton
        standard
        onClick={updateSettings}
        disabled={
          !!(!isDirty || isInvalid) || activeTableUpdateLoading || editing
        }
        title={cantSaveMessage || ""}
        cy="save-active-settings"
        processing={activeTableUpdateLoading}
      >
        Save
      </IOButton>
      <div style={{ marginLeft: 26 }}>
        <IOButton outline onClick={setAllLocked}>
          Lock all columns
        </IOButton>
      </div>
    </Container>
  );
}
