import { useEffect, useMemo, useCallback } from "react";
import { Prompt, useRouteMatch, matchPath } from "react-router";

/**
 * Warns before closing the browser window.
 *
 * Also if you put the returned {prompt} in the JSX in render(), then navigating away from the route
 * gets hooked into too.
 *
 * @param {Function|boolean} condition
 * @param {Object=} options
 * @param {boolean=} options.onlyIfPathNotMatches If TRUE, the prompt should not show up
 *  if navigation results in the same path.
 * @param {string=} options.message Message to use
 */
export default function useWarnBeforeTabWindowClose(condition, options = {}) {
  const {
    onlyIfPathNotMatches,
    message = "There are unsaved changes. Are you sure you want to leave?",
  } = options;
  const match = useRouteMatch();

  const conditionPasses = useCallback(() => {
    return typeof condition === "function" ? condition() : condition;
  }, [condition]);

  useEffect(() => {
    const listener = (event) => {
      if (conditionPasses()) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [condition, conditionPasses]);

  const prompt = useMemo(() => {
    return (
      <Prompt
        message={(location) =>
          conditionPasses() &&
          (!onlyIfPathNotMatches || !matchPath(location.pathname, match))
            ? message
            : true
        }
      />
    );
  }, [conditionPasses, match, message, onlyIfPathNotMatches]);

  return {
    prompt,
  };
}
