import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Switcher from "../../../UI/Switcher/Switcher";
import LimitRowsInput from "./LimitRowsInput";
import SelectedFilter from "./SelectedFilter";
import Search from "./Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayRowsRuleLimit } from "../../../utils/constants/constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.divider};
  border-radius: 5px;
  width: fit-content;
  position: absolute;
  z-index: 17;
  background: ${(props) => props.theme.background.primary};
  right: 5px;
  top: -60px;
  span {
    font-size: 12px;
  }
`;

const RoundContainer = styled.div(
  ({ theme }) => `
  border: 1px solid ${theme.divider};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  &:hover {
    background: ${theme.primary};
    color: white;
  }
`
);

export default function StickyHeader({
  stickyHeaders,
  setSticky,
  sticky,
  loading,
  limit,
  displayRows,
  setLimit,
  rowsQuantity,
  selectedMenuFilter,
  search,
  setSearch,
  chart,
}) {
  const [show, setShow] = useState(!chart.collapseTopMenu);

  const somethingToShow = displayRows || chart?.showSearch || stickyHeaders;

  useEffect(() => {
    setShow(!chart.collapseTopMenu);
  }, [chart.collapseTopMenu]);

  if (loading || !somethingToShow) {
    return null;
  }

  // Calculates the effective number of rows to display based on the requested count and row limits.
  function calculateDisplayRowLimit(count) {
    // If a count is provided, use the smaller value between the requested count and maximum rows available.
    if (count) {
      return count < rowsQuantity ? count : rowsQuantity;
    }

    // If no count is provided, apply the default limit or the maximum rows available, whichever is smaller.
    return rowsQuantity > displayRowsRuleLimit ? displayRowsRuleLimit : count;
  }

  // Calculate the effective row limit and display rows using the helper function.
  const effectiveRowLimit = calculateDisplayRowLimit(limit);
  const effectiveDisplayRows = calculateDisplayRowLimit(displayRows);

  return (
    <Container>
      {show && (
        <>
          {selectedMenuFilter && (
            <SelectedFilter selectedMenuFilter={selectedMenuFilter} />
          )}

          {effectiveDisplayRows && (
            <LimitRowsInput
              limit={effectiveRowLimit}
              displayRows={effectiveDisplayRows}
              rowsQuantity={rowsQuantity}
              setLimit={setLimit}
            />
          )}

          <Search
            search={search}
            setSearch={setSearch}
            loading={chart?.loading}
            dataLength={chart.data?.length}
            showSearch={chart.showSearch}
          />

          {stickyHeaders && (
            <Switcher
              handleSwitchChange={() => setSticky(!sticky)}
              checked={sticky}
              rightLabel="Vertical scroll"
              containerStyle={{ marginRight: 20 }}
            />
          )}
        </>
      )}

      <RoundContainer onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
      </RoundContainer>
    </Container>
  );
}
