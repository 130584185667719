import { unique } from "../../utils/func";

export default function (data, yKey, yKeys, valueKeys = [], groupBy, xKey) {
  if (yKeys) {
    return {
      data: data,
      yKey: yKey,
      yKeys: yKeys,
      groupBy,
      valueKeys: yKeys.map((k) => ({ alias: k })),
      lineKeys: [],
    };
  } else if (yKey) {
    if (groupBy) {
      const { returnData, allYKeys } = groupByDataMapping(data, groupBy, xKey, [
        { alias: yKey },
      ]);
      return {
        data: returnData,
        yKeys: allYKeys,
        groupBy: undefined,
        valueKeys: [{ alias: yKey }],
        lineKeys: [],
      };
    } else {
      return {
        data: data,
        yKey: undefined,
        yKeys: [yKey],
        groupBy,
        lineKeys: [],
      };
    }
  } else {
    // new style
    const lineKeys = valueKeys.filter((v) => v?.extras?.shape === "line");
    const barKeys = valueKeys
      .filter((v) => v?.extras?.shape !== "line")
      .map((k) => k.alias);
    const negativeReturnKeys = valueKeys
      .filter((v) => v?.extras?.shape === "barNegative")
      .map((v) => v.alias);

    if (!groupBy) {
      const afterNegatives = calcNegatives(data, negativeReturnKeys);
      return {
        data: afterNegatives,
        yKey: undefined,
        groupBy: undefined,
        yKeys: barKeys,
        valueKeys,
        lineKeys,
      };
    } else {
      // Modern groupBy style
      const { returnData, allYKeys } = groupByDataMapping(
        data,
        groupBy,
        xKey,
        valueKeys,
        negativeReturnKeys
      );
      return {
        data: returnData,
        yKeys: allYKeys,
        groupBy: undefined,
        lineKeys,
        valueKeys,
        negativeYKeys: negativeReturnKeys,
      };
    }
  }
}

function calcNegatives(data, negativeKeys) {
  return data.map((d) => {
    // Create a new object with all original keys and values
    const updatedData = { ...d };

    // Go through each key in negativeKeys and multiply its value by -1 if it exists
    negativeKeys.forEach((key) => {
      if (key in updatedData) {
        updatedData[key] = updatedData[key] * -1; // Negate the value
      }
    });

    return updatedData; // Return the updated object
  });
}

function groupByDataMapping(data, groupBy, xKey, valueKeys, negativeKeys = []) {
  const allXKeys = unique(data.map((d) => d[xKey]));
  const allYKeys = unique(data.map((d) => d[groupBy]));

  const returnData = allXKeys.map((xKeyValue) => {
    const matchedRows = data.filter((d) => d[xKey] === xKeyValue);

    const mergedValueRows = matchedRows.reduce((acc, curr) => {
      const key = curr[groupBy];
      const valueAlias = valueKeys[0].alias;
      const value = curr[valueAlias];

      // Check if the key is in negativeKeys, and negate its value if true
      const adjustedValue = negativeKeys.includes(valueAlias)
        ? value * -1
        : value;

      return { ...acc, [key]: adjustedValue };
    }, {});

    return { [xKey]: xKeyValue, ...mergedValueRows };
  });

  return { allYKeys, returnData };
}
