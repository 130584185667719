import styled from "@emotion/styled";

const Square = styled.div`
  width: 10px;
  margin-top: 2px;
  height: 10px;
  border-radius: 1px;
  background: ${(props) => props.color};
  flex-shrink: 0;
`;

const Rect = styled.div`
  width: 30px;
  margin-top: 2px;
  height: 5px;
  border-radius: 2px;
  background: ${(props) => props.color};
  flex-shrink: 0;
`;

const Circle = styled.div`
  width: 10px;
  margin-top: 2px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.color};
  flex-shrink: 0;
`;

const Dashed = styled.div`
  width: 30px;
  margin-top: 2px;
  height: 0px;
  border-bottom: 3px dashed ${(props) => props.color};
  flex-shrink: 0;
`;

export function generateDynamicLegendFigure(type) {
  switch (type) {
    case "rect":
      return Rect;

    case "circle":
      return Circle;

    case "dashed":
      return Dashed;

    default:
      return Square;
  }
}

export function isValueNotDefined(value) {
  return (
    value === null ||
    value === undefined ||
    value === "null" ||
    value === "undefined"
  );
}
