import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import SimpleTooltip from "../../UI/SimpleTooltip/SimpleTooltip";
import formatter from "../../utils/formatters/formatter";
import { scrollbarDe } from "../../styles/styledSnippets";
import { generateDynamicLegendFigure, isValueNotDefined } from "./helper";

const LegendContainer = styled.div(
  ({ theme, highTop, horizontal, wrapping, legendHeight }) => `
  padding: 12px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-top: ${highTop ? -30 : 0}px;
  display: flex;
  flex-direction: ${horizontal ? "row" : "column"};
  flex-wrap: ${wrapping ? "wrap" : "nowrap"};
  position: relative;
  justify-content: center;

  ${
    legendHeight &&
    `
    max-height: ${legendHeight}px;
    min-height: ${legendHeight}px;
    overflow-y: auto;
    ${scrollbarDe(theme)};
  `
  };
 
`
);

const Label = styled.div`
  margin: 0 18px 0 6px;
  font-size: 12px;
  text-align: left;
  color: ${({ theme }) => theme.text.secondary};
  white-space: ${(props) => (props.wrapping ? "nowrap" : "wrap")};
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
`;

function Legend(props) {
  const {
    colors,
    sections,
    highTop,
    wrapping,
    horizontal,
    border,
    hide,
    legendTooltips,
    groupByFormat,
    meta,
    term,
    ignoreDateTerm,
    legendHeight,
  } = props;

  const renderLegend = useCallback(
    (children, key) => {
      if (legendTooltips) {
        return (
          <SimpleTooltip
            key={key}
            label={legendTooltips.find((lt) => lt.name === key)?.popup}
            position="top"
          >
            {children}
          </SimpleTooltip>
        );
      }
      return children;
    },
    [legendTooltips]
  );

  const getLabel = useCallback(
    (section) => {
      const label = getMetaVersion() ?? section;
      function getMetaVersion() {
        const match = meta && meta.fields.find((f) => f.alias === section);
        return match?.label;
      }
      const format = groupByFormat || term;

      return format && !ignoreDateTerm ? formatter(label, format) : label;
    },
    [groupByFormat, term, ignoreDateTerm, meta]
  );

  if (!sections || !sections.length || hide) {
    return null;
  }

  function getColor(index) {
    return colors[index]?.color;
  }

  return (
    <LegendContainer
      wrapping={wrapping}
      highTop={highTop}
      border={border}
      horizontal={horizontal}
      legendHeight={legendHeight}
    >
      {sections.map((section, index) => {
        let label = getLabel(section);

        const Figure = generateDynamicLegendFigure("add from extras");

        if (isValueNotDefined(label)) {
          label = "[blank]";
        }

        return renderLegend(
          <Flex key={section + "-" + index} data-cy="legend-item" align>
            <Figure color={getColor(index)} data-cy="legend-square" />
            <Label data-cy="legend-text" wrapping={wrapping}>
              {label}
            </Label>
          </Flex>,
          section
        );
      })}
    </LegendContainer>
  );
}

Legend.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  sections: PropTypes.array,
  colorFunction: PropTypes.bool,
  horizontal: PropTypes.bool,
  wrapping: PropTypes.bool,
};

export default React.memo(Legend);
