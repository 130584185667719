import { useState } from "react";
import Input from "../../../../../UI/Form/Input/Input";
import Select from "../../../../../UI/Form/Select/Select";
import useDebounceChange from "../../../../../hooks/useDebounceChange";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import MultipleRowGroupKeys from "./MultipleRowGroupKeys/MultipleRowGroupKeys";
import MultipleColumnsRowGroupKeys from "./MultipleColumnsRowGroupKeys/MultipleColumnsRowGroupKeys";

export default function RowGroupKeyContainer(props) {
  const {
    chart,
    setChartState,
    repeatingOptions,
    isParameterized,
    setSelectedColumn,
    setNavigationState,
  } = props;

  const {
    rowGroupKey,
    overrides,
    multipleRowGroups,
    multipleRowGroupsDisplayName,
  } = chart;

  const { debounce } = useDebounceChange();

  const nonAggregatedRowGroupKey = getRidOfAggregation(
    rowGroupKey ?? "",
    overrides
  );

  const { mapping } =
    (overrides ?? []).find(
      (override) => override.name === nonAggregatedRowGroupKey
    ) ?? {};

  const initialRowGroupKeyDisplayName = multipleRowGroups
    ? multipleRowGroupsDisplayName
    : mapping?.displayName ?? "";

  const [rowGroupKeyDisplayName, setRowGroupKeyDisplayName] = useState(
    initialRowGroupKeyDisplayName
  );

  function setGroupingColumn(option) {
    setChartState({
      ...chart,
      rowGroupKey: option?.value,
      rawRows: false,
    });
  }

  function updateOverrideDisplayName(override, displayName) {
    if (override.name === nonAggregatedRowGroupKey) {
      return {
        ...override,
        mapping: {
          ...(override.mapping ?? {}),
          displayName,
        },
      };
    }

    return override;
  }

  function isWhitespaceString(str) {
    return !str.replace(/\s/g, "").length;
  }

  function changeRowGroupKeyDisplayName(e) {
    const displayName = e.target.value;

    if (displayName && isWhitespaceString(displayName)) {
      return;
    }

    if (multipleRowGroups) {
      setRowGroupKeyDisplayName(displayName);
      return debounce(
        () =>
          setChartState({
            ...chart,
            multipleRowGroupsDisplayName: displayName,
          }),
        1000
      );
    }

    debounce(
      () =>
        setChartState({
          ...chart,
          overrides: overrides.map((override) =>
            updateOverrideDisplayName(override, displayName)
          ),
        }),
      1000
    );
  }

  function changeMultipleRowGroups() {
    setChartState({
      ...chart,
      multipleRowGroups: !multipleRowGroups,
    });
  }

  function getRowGroupKeyValue() {
    return repeatingOptions.find(
      (ro) => ro.value === getRidOfAggregation(rowGroupKey, overrides)
    );
  }

  return (
    <>
      <Checkbox
        label="Multiple Grouping column (many columns in one)"
        checked={!!multipleRowGroups}
        onChange={changeMultipleRowGroups}
        color="inherit"
        fontSize="12px"
      />
      <br />
      {multipleRowGroups ? (
        <MultipleRowGroupKeys
          repeatingOptions={repeatingOptions}
          chart={chart}
          setChartState={setChartState}
          isParameterized={isParameterized}
        />
      ) : (
        <Select
          options={repeatingOptions}
          value={getRowGroupKeyValue()}
          label="Grouping column (Rows)"
          onChange={setGroupingColumn}
          isClearable
        />
      )}
      <br />
      {rowGroupKey && (
        <Input
          label="Grouping column Display name"
          value={rowGroupKeyDisplayName}
          onChange={changeRowGroupKeyDisplayName}
        />
      )}
      <br />

      <MultipleColumnsRowGroupKeys
        repeatingOptions={repeatingOptions}
        chart={chart}
        setChartState={setChartState}
        debounce={debounce}
        setSelectedColumn={setSelectedColumn}
        isParameterized={isParameterized}
        setNavigationState={setNavigationState}
      />
      <br />
    </>
  );
}
