import React from "react";
import PropTypes from "prop-types";
import useLabel from "../Form/LabelWrapper/useLabel";
import styled from "@emotion/styled";

const DatePickerContainer = styled.div(
  ({ theme, menuPrimary }) => `
    width: 90%;
    margin: 0 auto;
    input {
      width: calc(100% - 22px);
      margin-bottom: 12px;
      padding: 10px 12px;
      outline: none;
      border: .01rem solid ${menuPrimary ? "white" : theme.divider};
      color: ${menuPrimary ? "white" : theme.text.secondary};
      border-radius: 4px;
      box-sizing: content-box;
      background: transparent;
      &::-webkit-calendar-picker-indicator {
          filter: invert(1) brightness(80%);
      }
      &:focus {
          border: 2px solid ${theme.menuPrimary ? "white" : theme.primary};
      }
    }
`
);

export default function DatePicker(props) {
  const { value, onChange, label, required } = props;
  const withLabel = useLabel(label, required);

  const el = (
    <DatePickerContainer>
      <input type="date" value={value || ""} onChange={onChange} />
    </DatePickerContainer>
  );

  return withLabel(el);
}

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
};
