import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { scaleBand, scaleLinear } from "d3-scale";
import { format } from "d3-format";
import { extent } from "d3-array";
import YAxis from "../Axes/YAxis";
import SpringBar from "./SpringBar";
import SpringGroup from "./SpringGroup";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { mapping } from "../../utils/getVisualizationLabel";
import formatter, { labelFormatter } from "../../utils/formatters/formatter";
import SimpleXAxis from "../SimpleXAxis";
import TransparentRect from "../Rectangles/TransparentRect";
import { formats } from "../../utils/constants/constants";
import { isNumber } from "lodash-es";

export default function GroupedBarTwo(props) {
  const {
    valueKey1,
    valueKey2,
    data,
    xKey,
    height,
    width,
    color1,
    color2,
    autoWidth,
    barWidth1,
    barWidth2,
    bar2Labels,
    labelFormat,
    xLabelFormat,
    labelSize,
    labelWeight,
    labelColor,
    intraGroupSpacing,
    bar2ColorRange,
    hideYAxis,
    yTicksCount,
    yTicksColor,
    hideYAxisLine,
    hideYAxisTicks,
    yAxisGrid,
    yAxisGridColor,
    valuePadding,
    xTicksColor,
    setTooltip,
    bar2DefaultColor,
    meta,
    xLabelKey,
    yAxisFormat,
    color,
    xAxisFormat,
    expandBar,
    setBar,
    bar,
    expandedData,
    xAxisDate,
    reverseBarColors,
    targetLabels,
    expandedXKey,
    labelFormula,
  } = props;

  function nullToZero(val) {
    return +val || 0;
  }

  const allValues = data.reduce(
    (acc, curr) => [
      ...acc,
      nullToZero(curr[valueKey1]),
      nullToZero(curr[valueKey2]),
    ],
    []
  );

  const allXValues = data.map((d, i) => (xKey ? d[xKey] : i));
  const x = scaleBand()
    .domain(allXValues)
    .rangeRound([0, width])
    .paddingInner(0.3)
    .paddingOuter(0.1);
  // @todo this needs to be cleaned up
  const valueKey1Mapping = mapping(meta.fields, valueKey1);
  const valueKey2Mapping = mapping(meta.fields, valueKey2);
  // @todo undo Gary mess below - this needs to be generalized in a single utility for everywhere
  const doFormat = (val2, val1) => {
    if (labelFormula) {
      const val = val2 - val1;
      const plusSign = val > 0 ? "+" : "";
      return plusSign + labelFormatter(val2 - val1, labelFormat);
    }
    if (labelFormat) {
      if (formats.find(({ value }) => value === labelFormat)) {
        return formatter(nullToZero(val2), labelFormat);
      }
      return labelFormatter(val2, labelFormat);
    }

    return format(getFormat)(val2).replace("G", "B");
  };

  const getFormat = yAxisFormat || valueKey2Mapping.type || labelFormat;

  const yDomain = extent([...allValues, 0]).map((d, i) =>
    i === 1 ? d * valuePadding : d
  );

  const y = scaleLinear().domain(yDomain).range([height, 0]);
  const minYDomain = Math.min(...yDomain);
  const getBarHeight = (value) => {
    const chartHeight = props.height;

    if (value < 0) {
      const fromBottomToYBar = y(minYDomain) - y(value);
      return chartHeight - y(0) - fromBottomToYBar;
    }

    const fromBottomToYZero = y(minYDomain) - y(0);
    return chartHeight - y(value) - fromBottomToYZero;
  };

  const xWidth = (fixedWidthOverride) =>
    autoWidth ? x.bandwidth() / 2 : (x.bandwidth() / 2) * fixedWidthOverride;

  const xPos = (d, i) => x(xKey ? d[xKey] : i);
  const xPos2 = (d, i) =>
    x(xKey ? d[xKey] : i) +
    xWidth(barWidth1) +
    x.paddingInner() * intraGroupSpacing;

  const yPos = (d, valueKey) => (d[valueKey] > 0 ? y(d[valueKey]) : y(0));
  const rotateDeg = mobileBreakpoints.isMobile ? "-90deg" : "0deg";
  const offset = {
    x: mobileBreakpoints.isMobile ? 2 : 0,
    y: mobileBreakpoints.isMobile ? -10 : 0,
  };

  // TODO: Gary we need to discuss this conditions
  // because I think we making some unnecessary things
  const calculateColorByValues = useCallback(
    (bar) => {
      if (!bar2ColorRange) {
        return color2;
      }

      const { short, success, almost, tolerance } = bar2ColorRange;
      const target = +bar[valueKey1];
      const value = +bar[valueKey2];

      const condition = reverseBarColors ? target < value : target <= value;
      if (condition) {
        return reverseBarColors ? short : success;
      }

      if (reverseBarColors) {
        return success;
      }

      if (target - tolerance < value) {
        return almost;
      }

      return short;
    },
    [bar2ColorRange, color2, reverseBarColors, valueKey1, valueKey2]
  );

  const getConfigurableColor = useCallback(
    (barData) => {
      if (color) {
        return color;
      }

      if (barData[expandedXKey] && bar.color) {
        return bar.color;
      }

      if (isNumber(+barData[valueKey1])) {
        return calculateColorByValues(barData);
      }

      return bar2DefaultColor;
    },
    [
      bar2DefaultColor,
      color,
      calculateColorByValues,
      valueKey1,
      bar,
      expandedXKey,
    ]
  );

  const showRect = bar && expandedData?.length > 0;

  return (
    <g>
      {showRect && (
        <TransparentRect
          xOffset={xPos(bar)}
          width={
            (xWidth(barWidth2) + xWidth(barWidth1)) *
            (expandedData.length + 1) *
            2
          }
          height={height}
          onClose={() => setBar(null)}
        />
      )}

      {!hideYAxis ? (
        <g data-cy="grouped-bar-value-axis">
          <YAxis
            {...props}
            yScale={y}
            yTicksCount={yTicksCount}
            yTicksColor={yTicksColor}
            hideYAxisLine={hideYAxisLine}
            hideYAxisTicks={hideYAxisTicks}
            yAxisGrid={yAxisGrid}
            yAxisGridColor={yAxisGridColor}
            width={width}
            yAxisFormat={getFormat}
          />
        </g>
      ) : null}
      <g data-cy="grouped-bar-x-axis">
        <SimpleXAxis
          values={data.map((d) => d[xLabelKey] || d[xKey])}
          xScale={x}
          width={width}
          height={height}
          xAxisDate={xAxisDate}
          xKeyFormat={xAxisFormat || xLabelFormat || xLabelKey || xKey} // lets decide to use only one of these params
          xTicksColor={xTicksColor}
        />
      </g>

      {props.data
        .filter((d) => d[valueKey1] || d[valueKey2])
        .map((d, i) => (
          <g key={i}>
            {/*Target Bar*/}
            {d[valueKey1] ? (
              <SpringBar
                cy="grouped-bar-bar"
                startPos={y(0)}
                y={yPos(d, valueKey1)}
                x={xPos(d, i) - (targetLabels ? 1 : 0)}
                height={getBarHeight(nullToZero(d[valueKey1]))}
                width={xWidth(barWidth1) + (targetLabels ? 3 : 0)}
                chartNum="1"
                color={color1}
                onMouseOver={() =>
                  setTooltip({
                    x: xPos(d, i) + xWidth(barWidth1) / 2,
                    y: yPos(d, valueKey1),
                    tooltip: [{ ...valueKey1Mapping, value: d[valueKey1] }],
                    allValues: d,
                  })
                }
                onMouseOut={() => setTooltip(null)}
              />
            ) : null}

            <SpringBar
              data-cy="grouped-bar-bar"
              startPos={y(0)}
              y={yPos(d, valueKey2)}
              x={xPos2(d, i)}
              height={getBarHeight(nullToZero(d[valueKey2]))}
              width={xWidth(barWidth2)}
              chartNum="2"
              color={getConfigurableColor(d)}
              onMouseOver={() =>
                setTooltip({
                  x: xPos2(d, i) + xWidth(barWidth2) / 2,
                  y: yPos(d, valueKey2),
                  tooltip: [{ ...valueKey2Mapping, value: d[valueKey2] }],
                  allValues: d,
                })
              }
              cursor={d.clicable ? "pointer" : "auto"}
              onMouseOut={() => setTooltip(null)}
              onClick={() => expandBar(d, getConfigurableColor(d))}
            />

            {bar2Labels ? (
              <SpringGroup
                y={yPos(d, valueKey2) - 5 + offset.y}
                x={xPos2(d, i) + xWidth(barWidth2) / 2 + offset.x}
              >
                <text
                  data-cy="grouped-bar-label"
                  textAnchor="middle"
                  fontSize={labelSize - (targetLabels ? 1 : 0) + "px"}
                  fontWeight={labelWeight}
                  fill={labelColor}
                  style={{ transform: `rotate(${rotateDeg})` }}
                >
                  {doFormat(d[valueKey2], d[valueKey1])}
                </text>
              </SpringGroup>
            ) : null}

            {targetLabels ? (
              <SpringGroup
                y={yPos(d, valueKey1) + 24 + offset.y}
                x={xPos(d, i) + xWidth(barWidth1) / 2 + 1}
              >
                <text
                  data-cy="target-bar-label"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fontSize={labelSize - 2 + "px"}
                  fontWeight={500}
                  fill={"white"}
                  opacity={1}
                  style={{ transform: "rotate(-90deg)" }}
                >
                  {doFormat(d[valueKey1])}
                </text>
              </SpringGroup>
            ) : null}
          </g>
        ))}
    </g>
  );
}

GroupedBarTwo.defaultProps = {
  labelFormat: ".2s",
  valueKey1: "value1",
  valueKey2: "value2",
  xKey: "month",
  labelSize: 12,
  labelWeight: "normal",
  labelColor: "black",
  intraGroupSpacing: 1,
  valuePadding: 1,
  xTicksColor: "black",
  barWidth1: 1,
  barWidth2: 1,
  meta: { fields: [] },
  color1: "pink",
  color2: "salmon",
};

GroupedBarTwo.propTypes = {
  meta: PropTypes.object,
  data: PropTypes.array,
  valueKey1: PropTypes.string,
  valueKey2: PropTypes.string,
  xKey: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  color1: PropTypes.string,
  color2: PropTypes.string,
  autoWidth: PropTypes.bool,
  barWidth1: PropTypes.number,
  barWidth2: PropTypes.number,
  bar1Labels: PropTypes.bool,
  bar2Labels: PropTypes.bool,
  labelFormat: PropTypes.string,
  labelSize: PropTypes.number,
  labelWeight: PropTypes.string,
  labelColor: PropTypes.string,
  intraGroupSpacing: PropTypes.number,
  bar2ColorRange: PropTypes.object,
  hideYAxis: PropTypes.bool,
  yTicksCount: PropTypes.number,
  yTicksColor: PropTypes.string,
  hideYAxisLine: PropTypes.bool,
  hideYAxisTicks: PropTypes.bool,
  yAxisGrid: PropTypes.bool,
  yAxisGridColor: PropTypes.string,
  valuePadding: PropTypes.number,
  xTicksColor: PropTypes.string,
};
