import React from "react";
import ActiveTableColumnSelector from "../ColumnSettings/ActiveTableColumnSelector";
import IOButton from "../../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { themeText } from "../../../styles/styledSnippets";
import DetailInstructions from "./DetailInstructions";
import ViewDetailsSummary from "./ViewDetailsSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLock,
  faQuestion,
  faUnlock,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faPlus as falPlus,
  faListOl as falListOl,
} from "@fortawesome/pro-light-svg-icons";
import { colors, iconTypes } from "../constants";
import Scorecard from "./Scorecard";
import ActiveTableAggregations from "./ActiveTableAggregations";

const ColumnDetails = styled.div`
  margin-top: 12px;
  height: 180px;
  max-width: 520px;
  margin-bottom: 28px;
  color: ${themeText("secondary")};
  position: relative;
  display: flex;
  & > div {
    margin-right: 12px;
    flex-shrink: 0;
  }
`;

const HideButtons = styled.div`
  position: absolute;
  bottom: 18px;
  display: flex;
  align-items: center;
  button {
    margin-right: 8px;
    margin-top: 18px;
  }
`;

const Field = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-top: 24px;
`;
export default function ColumnVisibilityDetails(props) {
  const {
    viewColumns,
    setNextColumns,
    handleSetActive,
    setHoverCol,
    activeColumn,
    active,
    visibleColumns,
    changeValue,
    setVisible,
    setInvisible,
    setAllVisible,
    setAllInvisible,
  } = props;

  // Set active column details
  const activeColumnObject = viewColumns.find((c) => c.colId === activeColumn);

  function handleSetVisible() {
    if (!activeColumn) return;
    return activeColumnObject.visible
      ? setInvisible(activeColumn)
      : setVisible(activeColumn);
  }

  const { aggregationType, field, type, visible, editable } =
    activeColumnObject ?? {};

  return (
    <>
      <DetailInstructions>
        Select the fields you want to display for this view
      </DetailInstructions>
      <ActiveTableColumnSelector
        columns={viewColumns}
        active={active}
        setNextColumns={setNextColumns}
        handleSetActive={handleSetActive}
        hideAdd
        setHoverCol={setHoverCol}
      />
      {activeColumnObject ? (
        <>
          <Field>field name: {field}</Field>
          <ColumnDetails>
            <Scorecard
              label="Type"
              value={<FontAwesomeIcon icon={iconTypes[type] ?? faQuestion} />}
            />
            <Scorecard
              label="Visible"
              onClick={handleSetVisible}
              value={<FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />}
              className="clickable"
            />
            {aggregationType ? (
              <Scorecard
                label="Aggregated"
                value={
                  <FontAwesomeIcon icon={aggregationIcons[aggregationType]} />
                }
              />
            ) : (
              <Scorecard
                label="Editable"
                value={<FontAwesomeIcon icon={editable ? faUnlock : faLock} />}
              />
            )}

            <HideButtons>
              {activeColumn && visible ? (
                <IOButton
                  onClick={() => setInvisible(activeColumn)}
                  color="gray"
                >
                  Set status to 'hide'
                </IOButton>
              ) : (
                <IOButton
                  onClick={() => setVisible(activeColumn)}
                  color={colors.visible}
                >
                  Set status to 'show'
                </IOButton>
              )}

              <ActiveTableAggregations
                activeColumn={activeColumn}
                changeValue={changeValue}
                aggregationType={aggregationType}
                aggregationIcons={aggregationIcons}
              />
            </HideButtons>
          </ColumnDetails>
        </>
      ) : (
        <ViewDetailsSummary
          visibleColumns={visibleColumns}
          setVisible={setVisible}
          setAllVisible={setAllVisible}
          setAllInvisible={setAllInvisible}
          allColumns={viewColumns}
        />
      )}
    </>
  );
}

const aggregationIcons = {
  SUM: falPlus,
  "DISTINCT COUNT": falListOl,
};
