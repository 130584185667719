import React, { useEffect, useState } from "react";
import Input from "../../../UI/Form/Input/Input";
import styled from "@emotion/styled";
import useDebounceChange from "../../../hooks/useDebounceChange";
import usePrevious from "../../../utils/usePrevious";

const DynamicLimitContainer = styled.div`
  align-items: center;
  color: ${(props) => props.theme.text.secondary};
  margin-right: 20px;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export default React.memo(function LimitRowsInput({
  limit,
  rowsQuantity,
  displayRows,
  sticky,
  setLimit,
}) {
  const [localLimit, setLocalLimit] = useState(limit);
  const { debounce } = useDebounceChange();

  const prevLimit = usePrevious(limit);

  useEffect(() => {
    if (limit !== prevLimit) {
      setLocalLimit(limit);
    }
  }, [limit, prevLimit, setLimit]); // do not remove setLimit from dependencies

  if (!displayRows || !rowsQuantity || sticky) {
    return null;
  }

  function changeLimit(e) {
    setLocalLimit(+e.target.value);
    debounce(() => setLimit(e));
  }

  return (
    <DynamicLimitContainer>
      <Label>Display Rows:&nbsp;</Label>
      <Input
        value={localLimit ?? ""}
        onChange={changeLimit}
        type="number"
        small
      />
      <Label>&nbsp;Total:&nbsp;{rowsQuantity}</Label>
    </DynamicLimitContainer>
  );
});
