import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import isEqual from "react-fast-compare";
import ActiveTableFileEditor from "./Grid/ActiveTableFileEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiImage from "../TableView/Elements/ApiImage";
import { cloneDeep, orderBy, pick } from "lodash-es";
import { isDate } from "../../utils/formatters/dateFormatter";
import { mergeColumnsAndQuery } from "./activeTableHelpers";
import usePrevious from "../../utils/usePrevious";
import styled from "@emotion/styled";
import {
  getActiveTableFileTypeConfig,
  getSpecificFileTypeByFilename,
} from "../../utils/activeTable";
import { colors } from "./constants";
import Flex from "../../UI/Flex/Flex";
import { useActiveTableFileDownload } from "./Grid/common";
import { DATA_SOURCE_COLUMN_TYPE_MAP } from "../../utils/dataSources";
import formatter from "../../utils/formatters/formatter";
import produce from "immer";
import ActiveTableDropdown from "./Grid/ActiveTableDropdown";

/**
 * @typedef {import('@ag-grid-community/core').ColDef} ColDef
 */

const Container = styled.div`
  height: 100%;
  & > div {
    height: 100% !important;
    width: 80px !important;
    margin: 0 auto;
  }
`;

export const noFileOpacity = 0.4;

function FileRenderer(value) {
  const columnType = value.colDef.type;
  const fileTypeConfig = getActiveTableFileTypeConfig(columnType);

  const field = value.colDef.field;
  const fileValue = value.data[field];

  const fileIsProcessed = !!fileValue && typeof fileValue === "string";
  const isImage = columnType === "image";

  const icon = (getSpecificFileTypeByFilename(fileValue) ?? fileTypeConfig)
    .solid_icon;

  const { downloadIcon } = useActiveTableFileDownload(fileValue);

  return (
    <Container>
      {isImage && fileIsProcessed ? (
        <ApiImage value={fileValue} blockZoom contain />
      ) : (
        <Flex
          style={{
            fontSize: 32,
            color: colors.fileIcon,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
          title={fileIsProcessed ? "" : "No File Uploaded."}
        >
          <FontAwesomeIcon
            icon={icon}
            color={colors.fileIcon}
            opacity={fileIsProcessed ? 1 : noFileOpacity}
          />

          {!isImage && downloadIcon}
        </Flex>
      )}
    </Container>
  );
}

export default function useActiveTableSettings(
  tableConfig,
  user = { groups: [] },
  queryFields,
  handleRowChangeRef
) {
  // To always call the latest-rendered version of the callback.
  const handleRowChange = useCallback(
    (...args) => handleRowChangeRef.current(...args),
    [handleRowChangeRef]
  );
  // Table Columns for Configs
  const [columns, setColumns] = useState([]);

  // Last timeTableConfig was updated
  const [prevConfig, setPrevConfig] = useState(null);

  // Last time table config was updated, translated versions
  const [prevTranslatedConfig, setPrevTranslatedConfig] = useState(null);
  // Errors object similar Laravel's API error response format.
  // Keys represent field names, and the values are arrays of error messages.
  const [columnErrorsObject, setColumnErrorsObject] = useState({});

  function setColumnError(key, value) {
    setColumnErrorsObject((columnErrorsObject) =>
      produce(columnErrorsObject, (draft = {}) => {
        if (value) {
          if (Array.isArray(value)) {
            draft[key] = value;
          } else {
            if (!columnErrorsObject[key]) {
              draft[key] = [];
            }
            // Trick to make sure the error object does not get mutated
            // unnecessarily if it would result in no changes.
            draft[key].splice(0, Infinity, value);
          }
        } else {
          delete draft[key];
        }
        return draft;
      })
    );
  }

  const defaultRow = columns.reduce((acc, curr) => {
    return { ...acc, [curr.field]: curr.type === "boolean" ? false : "" };
  }, {});

  // for join mode we need to get actual query fields which are come after active table
  // so wee need to update columns one more time
  const prevQueryFields = usePrevious(queryFields);

  const shouldUpdate =
    !isEqual(tableConfig, prevConfig) || !isEqual(queryFields, prevQueryFields);

  // update full configs on load and after save response is returned
  useEffect(() => {
    if (shouldUpdate) {
      const mergedColumns = mergeColumnsAndQuery(
        tableConfig.columns,
        queryFields
      );
      const initialColumns = removeIoSystemColumns(mergedColumns);
      const orderedInitialColumns = orderBy(initialColumns, "sortPriority");

      const nextColumnsConverted = orderedInitialColumns
        .map(applyAccessRules)
        .map((column) => convertColumn(column, handleRowChange, queryFields))
        .map(amendColumnForActiveGrid);
      setColumns(nextColumnsConverted);
      setPrevConfig(tableConfig);
      setPrevTranslatedConfig({
        ...tableConfig,
        columns: nextColumnsConverted,
      });
    }
    function applyAccessRules(column) {
      if (user.role === "tenant_owner") return column;
      const accessUuids = column.displaySettings?.accessGroups;
      if (!accessUuids?.length) return column;
      if (!column.isEditable) return column;
      const hasAccess = user.groups.find((uuid) => accessUuids.includes(uuid));
      return { ...column, isEditable: !!hasAccess };
    }
  }, [tableConfig, user, queryFields, handleRowChange, shouldUpdate]);

  const updateColumn = (index, newColumn) => {
    const newColumns = columns.map((col, i) => {
      const { queryOnly, ...rest } = newColumn;
      return i === index
        ? convertColumn(rest, handleRowChange, queryFields)
        : col;
    });
    setColumns(newColumns);
    return convertToApiColumns(newColumns);
  };

  const apiColumns = convertToApiColumns(columns);

  const addColumn = (name, type) => {
    const nextColumns = [
      ...columns,
      {
        colId: "temp",
        field: name || "",
        type: type || "string",
        editable: true,
        displaySettings: { activeOnly: true },
      },
    ];
    return setColumns(nextColumns);
  };

  const setAllLocked = () => {
    const nextColumns = columns.map((col) => ({ ...col, editable: false }));
    return setColumns(nextColumns);
  };

  const getColumn = (uuid) => {
    const column = columns.find((c) => c.colId === uuid);
    if (!column) {
      console.error(`No column with UUID '${uuid}' found`);
      return;
    }
    return column;
  };

  function produceColumnByUuid(uuid, produceCallback) {
    setColumns((columns) => {
      const column = columns.find((c) => c.colId === uuid);
      if (!column) {
        console.error(`No column with UUID '${uuid}' found`);
        return columns;
      }
      const updatedColumn = produce(column, produceCallback);
      return updatedColumn && updatedColumn !== column
        ? columns.map((existingColumn) =>
            existingColumn.colId === uuid ? updatedColumn : existingColumn
          )
        : columns;
    });
  }

  const addOption = (uuid, value) => {
    const column = getColumn(uuid);

    column.cellEditorParams.values = [...column.cellEditorParams.values, value];

    const nextColumns = columns.map((col) =>
      col.uuid === uuid ? column : col
    );

    return setColumns(nextColumns);
  };

  function setOptions(uuid, rawOptions) {
    return setColumns((columns) => {
      const column = cloneDeep(columns.find((c) => c.colId === uuid));
      let options;

      if (typeof rawOptions[0] === "object") {
        options = rawOptions.map((option) => option.value);
      } else {
        options = rawOptions;
      }

      column.cellEditorParams.values = options;

      const nextColumns = columns.map((col) =>
        col.colId === uuid ? column : col
      );
      return nextColumns;
    });
  }

  function setAccessGroups(uuid, nextGroups) {
    const nextColumns = columns.map((col) =>
      col.colId === uuid
        ? {
            ...col,
            cellEditorParams: {
              ...(col.cellEditorParams ?? {}),
              accessGroups: nextGroups,
            },
          }
        : col
    );

    return setColumns(nextColumns);
  }

  function setDisplayFormatOverride(uuid, value) {
    const nextColumns = columns.map((col) =>
      col.colId === uuid
        ? {
            ...col,
            cellEditorParams: {
              ...(col.cellEditorParams ?? {}),
              displayFromatOverride: value,
            },
          }
        : col
    );

    return setColumns(nextColumns);
  }

  const nextConfig = { ...prevTranslatedConfig, columns: apiColumns };

  const isDirtyStraight = !!(
    nextConfig.columns.length !== 0 &&
    !isEqual(columns.map(ignoreVisible), prevTranslatedConfig.columns)
  );

  function ignoreVisible(column) {
    const { visible, ...rest } = column;
    return rest;
  }

  function updateConfig(config) {
    setPrevConfig(config);
  }

  // Settings Field name validation
  function validateFieldName(colDef) {
    const { field, colId } = colDef;
    const regex = /^\w+( +\w+)*$/;
    let message = null;

    // do not include new field and editing field
    const fields = columns.filter(
      (col) => col.colId !== "temp" && col.colId !== colId
    );

    if (!field) {
      message = "Column name cannot be empty.";
    } else if (field.length < 2) {
      message = "Column name must have at least 2 characters.";
    } else if (!regex.test(field)) {
      message = `Column name cannot contain only spaces, end or start with space or non alphanumeric symbols`;
    } else if (fields.some((col) => col.field === field)) {
      message = "That column name already exists.";
    }

    setColumnError("name", message);
  }

  return {
    nextConfig,
    columns,
    apiColumns,
    updateColumn,
    setColumns,
    defaultRow,
    addColumn,
    addOption,
    setOptions,
    produceColumnByUuid,
    setAllLocked,
    setAccessGroups,
    isDirty: isDirtyStraight,
    updateConfig, // setUpdateConfig
    prevConfig,
    validateFieldName,
    convertToApiColumns,
    columnErrorsObject,
    setColumnError,
    setDisplayFormatOverride,
  };
}

export function convertToApiColumns(cols) {
  return cols
    .filter((col) => !col.queryOnly || col.editable)
    .map((col, i) => {
      const {
        field,
        editable,
        colId,
        cellEditor,
        cellEditorParams,
        cellClass,
        displayName,
        ...rest
      } = col;
      let type = col.type;
      let displaySettings = {
        accessGroups: cellEditorParams?.accessGroups || [],
        activeOnly: !!col.displaySettings?.activeOnly,
        displayFromatOverride: cellEditorParams?.displayFromatOverride,
        ...pick(cellEditorParams, loadFromDataProperties),
      };

      let valueOptions = [];
      if (type === "select") {
        type = "string";
        displaySettings.editMode = "select";
        valueOptions = col.cellEditorParams.values.map((v) => ({ value: v }));
      }
      if (type === "image") {
        type = DATA_SOURCE_COLUMN_TYPE_MAP["api-image"].key;
      }

      const {
        headerName,
        joinedOverride,
        queryOnly,
        headerClass,
        cellStyle,
        cellRenderer,
        ...remaining
      } = rest;

      return {
        ...remaining,
        name: col.field,
        isEditable: col.editable,
        uuid: col.colId !== "temp" ? col.colId : undefined,
        type,
        displaySettings,
        valueOptions,
        sortPriority: i,
      };
    });
}

export const systemColumns = [
  "uuid",
  "io_created_at",
  "io_updated_at",
  "IOUUID",
  "IOLastUpdatedBy",
  "IOLastUpdated",
  "IOCreated",
  "IOCreatedBy",
  "IOEffectiveStart",
  "IOEffectiveEnd",
];

const loadFromDataProperties = [
  "loadFromData",
  "loadFromQueryUuid",
  "loadFromFieldName",
];

function removeIoSystemColumns(columns) {
  return columns
    .filter((c) => !systemColumns.find((s) => s === c.name))
    .map((c) => ({ ...c, field: c.name }));
}

export const convertColumn = (column, handleRowChange, queryFields) => {
  const accessGroups =
    column.displaySettings?.accessGroups ??
    column.cellEditorParams?.accessGroups ??
    [];

  const displayFromatOverride =
    column.displaySettings?.displayFromatOverride ??
    column.cellEditorParams?.displayFromatOverride;

  const isEditEnabled = !!(column.isEditable || column.editable);

  const { mapping } =
    (queryFields ?? []).find(
      (field) => field.name === column.name || field.name === column.field
    ) ?? {};
  const displayName = mapping?.displayName ?? column.field;

  /** @type ColDef */
  const setting = {
    field: column.field,
    editable: isEditEnabled, // To work for FE and API configs
    type:
      column?.displaySettings?.editMode === "select" ? "select" : column.type,
    colId: column.colId || column.uuid,
    headerName: isEditEnabled ? `✏️ ${displayName} ️` : displayName,
    cellEditorParams: {
      accessGroups,
      displayFromatOverride,
    },
    displayName: column.field?.substring(0, Math.floor(20)), // @farhod Where is this used that we can't use headerName?
    displaySettings: column.displaySettings,
  };
  if (column.queryOnly) setting.queryOnly = true;
  if (column.joinedOverride) setting.joinedOverride = true;

  const values = column.cellEditorParams?.values?.length
    ? column.cellEditorParams.values
    : column.valueOptions?.length
    ? column.valueOptions.map((v) => v.value)
    : [];

  const type = displayFromatOverride ?? setting.type;

  switch (type) {
    case "api-image":
    case "image": // type apiImage
      return {
        ...setting,
        ...getCommonFileRendererProperties(),
        type: "image",
      };
    case "api-pdf":
    case "api-excel":
    case "api-word":
    case "api-document":
    case "api-document-or-image":
      return {
        ...setting,
        ...getCommonFileRendererProperties(),
        type: setting.type,
      };
    case "date":
      return {
        ...setting,
        cellEditor: "agDateStringCellEditor",
        valueFormatter: (params) => {
          if (params.value && isDate(params.value)) {
            return format(parseISO(params.value), "MM/dd/yyyy");
          }
          return params.value;
        },
      };
    case "select":
      return {
        ...setting,
        cellEditor: column.displaySettings.loadFromData
          ? ActiveTableDropdown
          : "agSelectCellEditor",
        cellEditorParams: {
          values: [...new Set(["None...", ...values])],
          accessGroups,
          ...pick(column.displaySettings, loadFromDataProperties),
        },
        ...(column.displaySettings.loadFromData
          ? { cellClass: "no-overflow-hidden" }
          : {}),
      };
    case "currency":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value ?? "", "currency");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "currency-whole":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value ?? "", "currency-whole");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "currency-tenth":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value ?? "", "currency-tenth");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "integer":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value, "integer");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "decimal":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value, "decimal");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "decimal-tenth":
      return {
        ...setting,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value, "decimal-tenth");
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "text":
      return { ...setting, cellEditor: "agTextCellEditor" };
    case "string":
      return {
        ...setting,
        cellEditor: "agTextCellEditor",
        cellEditorParams: {
          values,
          accessGroups,
          displayFromatOverride,
        },
      };
    case "boolean":
      return {
        ...setting,
        cellEditor: "agCheckboxCellEditor",
        cellRenderer: "agCheckboxCellRenderer",
        cellStyle: { display: "flex", justifyContent: "center" },
      };
    default:
      return setting;
  }

  function getCommonFileRendererProperties() {
    return {
      cellEditorPopup: true,
      cellEditorPopupPosition: "over",
      cellEditorParams: {
        handleChange: handleRowChange,
        accessGroups,
      },
      headerClass: "ag-center-aligned-header",
      cellEditor: ActiveTableFileEditor,
      cellRenderer: FileRenderer,
    };
  }
};

function amendColumnForActiveGrid(column) {
  return { ...column, enableCellChangeFlash: true };
}
