export const settingPriority = (changes, supported) => (key) => {
  return changes[key] ?? supported[key];
};

export function copyPropertiesFromSourceToTarget(
  source,
  target,
  shouldMove,
  properties
) {
  for (const property of properties) {
    if (!(property in source)) {
      continue;
    }
    target[property] = source[property];
    // if (shouldMove) {
    //   delete source[property];
    // }
  }
}

export function aggregationNumber(value) {
  if (typeof value === "string") return aggNumbers[value];
  return value;
}

const aggNumbers = {
  MAX: 1,
  AVG: 2,
  COUNT: 3,
  MIN: 4,
  DISTINCT_COUNT: 5,
  "DISTINCT COUNT": 5,
  YEAR: 6,
  QUARTER: 7,
  MONTH: 8,
  WEEK: 9,
  DAY: 10,
  SUM: 11,
};

export const aggPrefixByNumber = {
  1: "Max",
  2: "Avg",
  3: "Count",
  4: "Min",
  5: "DistinctCount",
  6: "Year",
  7: "Quarter",
  8: "Month",
  9: "Week",
  10: "Day",
  11: "Sum",
};

export const dateKeyStyleMap = {
  6: "annual",
  7: "quarterly",
  8: "monthly",
  9: "weekly",
  10: "daily",
};
