import styled from "@emotion/styled";
import Select from "../../../../../UI/Form/Select/Select";
import Switcher from "../../../../../UI/Switcher/Switcher";
import OrderValues from "./OrderValues";

const OrderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.notification.errorMain};
  }
`;

export default function Order({
  fields,
  changeOrderColumn,
  order,
  index,
  setChartState,
  chart,
}) {
  function onValueAdd(value) {
    changeOrderColumn(
      {
        name: [...(order.values ?? []), value],
      },
      index,
      "values"
    );
  }

  function onValueRemove(value) {
    changeOrderColumn(
      {
        name: (order.values ?? []).filter((item) => item !== value),
      },
      index,
      "values"
    );
  }

  return (
    <>
      <Select
        options={fields}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        label="Column"
        value={fields.find((override) => override.name === order.name)}
        onChange={(o) => changeOrderColumn(o, index, "name")}
        cy={`select-order-field-${index}`}
      />
      <br />
      <OrderActions>
        <Switcher
          leftLabel="ASC"
          rightLabel="DESC"
          checked={order.sort.toLowerCase() === "desc"}
          handleSwitchChange={() =>
            changeOrderColumn(
              {
                name: order.sort.toLowerCase() === "desc" ? "ASC" : "DESC",
              },
              index,
              "sort"
            )
          }
        />
        <span
          onClick={() =>
            setChartState({
              ...chart,
              orders: chart.orders.filter((_, i) => i !== index),
            })
          }
        >
          Remove order
        </span>
      </OrderActions>
      <br />
      {/* Reusable component */}
      <OrderValues
        values={order.values}
        onValueAdd={onValueAdd}
        onValueRemove={onValueRemove}
      />
      <br />
    </>
  );
}
