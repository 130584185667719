import { useMemo, useState } from "react";
import FilterItem from "./FilterItem";
import styled from "@emotion/styled";
import Input from "../../UI/Form/Input/Input";
import {
  checkboxOrRadio,
  filterValuesLimit,
  FILTER_TYPE_BUCKET_RANGE,
  FILTER_TYPE_RADIO,
} from "../../utils/constants/constants";
import ComparisonSelectAll from "./ComparisonSelectAll/ComparisonSelectAll";
import FilterValuesPagination from "./FilterValuesPagination";
import useFilterPagination from "../../hooks/useFilterPagination";
import GeneralSelectAll from "./GeneralSelectAll";
import { orderBy, stubTrue } from "lodash-es";
import Flex from "../../UI/Flex/Flex";

const SearchWrapper = styled.div(
  () => `
  & > input {
    margin-bottom: 10px;
    font-size: 14px;
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, .1);
  }

  & > input::placeholder {
    font-size: 12px;
  }
`
);

const perPage = 125;

function NoResults() {
  return (
    <Flex justifyContent="center">
      <em>No results</em>
    </Flex>
  );
}

export default function GeneralFilters(props) {
  const {
    searchValue,
    setSearchValue,
    select,
    finalFiltersBeforePagination,
    type,
    comparisonModeItem,
    selectPowerEditorModeFilter,
    filter,
    searchFilterLoading,
    additionalTheme,
    selectedValues,
  } = props;

  const [limit, setLimit] = useState(perPage);

  const { page, setPage, fetchFilterValues, usePagination } =
    useFilterPagination(filter);

  const { totalCount } = filter ?? {};
  const quantityInStore = finalFiltersBeforePagination.length;

  const selectedFilters = useMemo(() => {
    return selectedValues
      ? selectedValues.map((value) => ({
          value,
          label: value,
          checked: true,
          key: filter.name,
          type: filter.name,
          // So we know that the checked-ness of this value
          // comes from selectedValues.
          fromSelectedFilters: true,
        }))
      : finalFiltersBeforePagination.filter((filter) => filter.checked);
  }, [filter.name, finalFiltersBeforePagination, selectedValues]);

  const currentPageOfFilters = useMemo(() => {
    return finalFiltersBeforePagination.slice(0, limit);
  }, [finalFiltersBeforePagination, limit]);

  const onSearch = (value) => {
    if (usePagination) {
      fetchFilterValues(value);
    }
    setSearchValue(value);
  };

  const currentPageFilterValues = useMemo(() => {
    return currentPageOfFilters.map((filter) => filter.value);
  }, [currentPageOfFilters]);

  const selectedFiltersNotOnCurrentPage = useMemo(() => {
    return selectedFilters
      .filter((filter) => !currentPageFilterValues.includes(filter.value))
      .map((filter) => ({
        ...filter,
        notOnCurrentPage: true,
      }));
  }, [currentPageFilterValues, selectedFilters]);

  if (
    ![...checkboxOrRadio, FILTER_TYPE_BUCKET_RANGE].find(
      (item) => item === type
    )
  ) {
    return null;
  }

  const filterOutNonEmpty =
    filter.usesCustomValues || filter.showEmptyValues
      ? stubTrue
      : (item) => filterEmpty(item, additionalTheme, filter.uuid);

  const filters = orderBy(
    [...selectedFiltersNotOnCurrentPage, ...currentPageOfFilters]
      .filter(filterOutNonEmpty)
      .filter(filterUnknown),
    "value.position" // sorting for a bucket value objects
  );

  const selectFilter = (filter, type) => {
    // do not allow uncheck radio filter
    if (type === FILTER_TYPE_RADIO && filter.checked) {
      return;
    }
    select(filter, type);
  };

  return (
    <>
      {totalCount > filterValuesLimit && (
        <SearchWrapper>
          <Input
            placeholder="Find..."
            onChange={(e) => onSearch(e.target.value)}
            loading={searchFilterLoading}
            value={searchValue}
          />
        </SearchWrapper>
      )}

      <ComparisonSelectAll
        filters={filters}
        type={type}
        comparisonModeItem={comparisonModeItem}
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
      />

      {!comparisonModeItem && (
        <GeneralSelectAll
          select={select}
          type={type}
          filters={filters}
          filterUuid={filter.uuid}
          additionalTheme={additionalTheme}
        />
      )}

      {filters.map((filter) => {
        const label = <FilterLabel filter={filter} />;
        const name = filter.label;
        const effectiveName = type === "radio" ? label : name;
        const effectiveLabel = type === "radio" ? null : label;

        return (
          <FilterItem
            key={
              filter.value && typeof filter.value === "object"
                ? JSON.stringify(filter.value)
                : filter.value
            }
            name={effectiveName}
            label={effectiveLabel}
            checked={filter.checked ? "checked" : ""}
            onChange={() => selectFilter(filter, type)}
            cy="filter-menu-input"
            type={type}
          />
        );
      })}

      {!filters.length ? <NoResults /> : null}

      {quantityInStore > limit ? (
        <div
          className="clickable"
          onClick={() => setLimit((limit) => limit + perPage)}
        >
          ...
          {quantityInStore - limit} More
        </div>
      ) : null}

      <FilterValuesPagination
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        fetchFilterValues={fetchFilterValues}
        searchValue={searchValue}
      />
    </>
  );
}

export const filterUnknown = ({ value }) => {
  return value?.toString().toLowerCase() !== "unknown";
};

export const filterEmpty = (v, additionalTheme, uuid) => {
  const { includedEmptyValues = {} } = additionalTheme ?? {};

  return (
    includedEmptyValues[uuid] || v.min || v.max || v.value || v.value === 0
  );
};

/**
 * If the provided label is not displayable in a human-friendly way, this
 * function returns a special string as how the label should be displayed
 * as a fallback.
 */
export function getSpecialLabel(label) {
  if (label === null) {
    return "(Empty)";
  }
  if (label === false) {
    return "(False)";
  }
  if (label === true) {
    return "(True)";
  }
  if (label === "") {
    return <span>&nbsp;</span>;
  }
  if (typeof label === "number" && isNaN(label)) {
    return "Not a Number";
  }
  return null;
}

function FilterLabel({ filter }) {
  const label = filter.label;
  const specialLabel = getSpecialLabel(label);
  const displayLabel = <FilterDisplayLabel label={label} />;

  return specialLabel ||
    (filter.fromSelectedFilters && filter.notOnCurrentPage) ? (
    <em>{displayLabel}</em>
  ) : (
    displayLabel
  );
}

/**
 * Displays a label. Ensures that a special string is shown as fallback if
 * the label is not displayable.
 */
export function FilterDisplayLabel({ label, italicizeSpecial }) {
  const specialLabel = getSpecialLabel(label);
  const displayLabel = specialLabel ?? label;

  return specialLabel && italicizeSpecial ? (
    <em>{specialLabel}</em>
  ) : (
    displayLabel
  );
}
