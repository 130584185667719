import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { inputBorders } from "../../../../styles/styledSnippets";

const Option = styled.div(
  (props) => `
  ${inputBorders(props)}
  color: ${props.theme.text.secondary};
  font-size: 12px;
  width: 90%;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  ${
    props.isDragging &&
    `
      background: ${props.theme.primary};
      color: white;
    `
  };
  ${
    props.notAllowed &&
    `
      border-color: none;
      cursor: not-allowed;
    `
  };
`
);

export default function ActiveTableOptionsReorder({
  uuid,
  options,
  setOptions,
}) {
  const [noneOption, ...restOptions] = options;

  function onDragEnd({ destination, source }) {
    if (!destination) {
      return;
    }

    const items = Array.from(restOptions);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setOptions(uuid, [noneOption, ...items]);
  }

  return (
    <div>
      <Option notAllowed>{noneOption.value}</Option>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {restOptions.map((option, index) => (
                <Draggable
                  key={index + "-" + option.value}
                  draggableId={(index + "-" + option.value).toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Option
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                    >
                      {option.value}
                    </Option>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
