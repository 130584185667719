import { unique } from "../../utils/func";

export default function (
  data,
  yValueKeys = [],
  valueKeys = [],
  activeValueKey,
  yKey,
  groupBy,
  breakdownKey,
  lineYKeys,
  yKeys,
  negativeYKeys = [],
  xKey
) {
  let yValueReturnKeys = yKeys || yValueKeys;

  let dataReturn = data;
  const legacyKeysSettings =
    yValueReturnKeys && yValueReturnKeys.find((k) => !!k);
  if (legacyKeysSettings) {
    return {
      data: dataReturn,
      yValueKeys: [yValueReturnKeys[0]] || activeValueKey?.valueKey,
      yKey: yKey ?? yValueReturnKeys[0],
      breakdownKey,
      lineYKeys: (lineYKeys || []).map((l, i) => ({ alias: l, colorIndex: i })),
      negativeYKeys,
    };
  }
  if (groupBy && legacyKeysSettings) {
    return {
      data: dataReturn,
      yValueKeys: [valueKeys[0].name],
      yKey: null,
      breakdownKey,
      lineYKeys: (lineYKeys || []).map((l, i) => ({ alias: l, colorIndex: i })),
      negativeYKeys,
    };
  }

  // Modern style below

  const defaultValueKeys = valueKeys
    .filter(
      (v) => v?.extras?.shape !== "line" && v?.extras?.shape !== "barNegative"
    )
    .map((v) => v.alias);

  const lineValueKeys = valueKeys
    .filter((v) => v?.extras?.shape === "line")
    .map((l) => ({
      alias: l.alias,
      colorIndex: valueKeys.findIndex((k) => k.alias === l.alias),
    }));
  const negativeReturnKeys = valueKeys
    .filter((v) => v?.extras?.shape === "barNegative")
    .map((v) => v.alias);

  if (!groupBy) {
    return {
      data: dataReturn,
      yValueKeys: defaultValueKeys,
      yKey: defaultValueKeys[0],
      breakdownKey,
      lineYKeys: lineValueKeys,
      negativeYKeys: negativeReturnKeys,
    };
  }

  // Modern groupBy style
  const allXKeys = unique(data.map((d) => d[xKey]));
  const allYKeys = unique(data.map((d) => d[groupBy]));
  const returnData = allXKeys.map((xKeyValue) => {
    const matchedRows = data.filter((d) => d[xKey] === xKeyValue);
    const mergedValueRows = matchedRows.reduce((acc, curr) => {
      return { ...acc, [curr[groupBy]]: curr[valueKeys[0].alias] };
    }, {});
    return { [xKey]: xKeyValue, ...mergedValueRows };
  });

  return {
    data: returnData,
    yValueKeys: allYKeys,
    breakdownKey: groupBy,
    lineYKeys: lineValueKeys,
    negativeYKeys: [],
  };
}
