import { encodeURIMinimal } from "../../../../utils/browser";
import { handleError } from "../../../../utils/errorHandling";

export default (
  axios,
  type,
  name,
  queryId,
  setUniques = () => {},
  setLoading = () => {},
  setError = () => {}
) => {
  const qs = {
    number: addToQs(name, [1, 2, 4]),
    date: addToQs(name, [1, 4]),
    string: stringQs(name),
    boolean: stringQs(name),
  };

  setLoading(true);

  const endpoint = `api/v1/queries/${queryId}/exec?${qs[type]}`;

  return axios
    .get(endpoint)
    .then(handleSetValue)
    .catch((err) => {
      handleError(err);
      setError(err.message);
    });

  function handleSetValue(res) {
    if (type === "number" || type === "date") {
      const valueKeys = Object.keys(res.data.data[0]);
      const values = valueKeys.reduce(normalizeAggregatedValues, {});
      setUniques(values);
      setLoading(false);
      return values;
    } else {
      setUniques(res.data.data);
      setLoading(false);
      return res.data.data;
    }

    function normalizeAggregatedValues(acc, curr) {
      return { ...acc, [parseKey(curr)]: res.data.data[0][curr] };
    }
    function parseKey(curr) {
      return curr.replace(name, "").toLowerCase();
    }
  }

  function addToQs(name, aggs) {
    let qs = "";
    aggs.forEach((a, i) => {
      qs += `&overrides[fields][${i}][name]=${encodeURIMinimal(
        name
      )}&overrides[fields][${i}][aggregation][type]=${encodeURIMinimal(a)}`;
    });
    return qs;
  }

  function stringQs(name) {
    return `&overrides[fields][0][name]=${encodeURIMinimal(
      name
    )}&overrides[other][isDistinct]=1&order[${encodeURIMinimal(name)}]=ASC`;
  }
};
