import WorldMap from "react-svg-worldmap";
import Flex from "../../UI/Flex/Flex";
import { useTheme } from "emotion-theming";
import Details from "../../Layout/Details/Details";
import GradientKey from "../GeoMap/components/GradientKey";
import {
  mapDataToWorldMapStructure,
  tooltipTextFunction,
} from "./worldMapHelper";
import Loading from "../../UI/Loading/Loading";
import Refreshing from "../BaseChart/Refreshing";
import MapLoading from "../GeoMap/components/MapLoading";
import { useState } from "react";
import CountryDetails from "./CountryDetails";

export default function WorldMapVisualization(props) {
  const { chart } = props;

  const {
    data,
    meta,
    countryKey,
    valueKey,
    colorsConfig,
    loading,
    refreshing,
    colorScheme,
  } = chart;

  const theme = useTheme();
  const [state, setState] = useState(null);

  if (!valueKey || !countryKey) {
    return <span>Please define countryKey/valueKey</span>;
  }

  if (loading) {
    return <MapLoading />;
  }

  const mapDataToCountry = mapDataToWorldMapStructure(
    data,
    countryKey,
    valueKey
  );

  function onClickFunction(context) {
    const dataItem = data.find(
      (row) => row[countryKey] === context.countryName
    );
    setState(dataItem);
  }

  return (
    <Flex justifyContent="center" alignItems="center" direction="column">
      <Refreshing show={refreshing} comp={<Loading />} zIndex={1000} />
      {colorsConfig && (
        <Flex justifyContent="flex-start" width="100%">
          <GradientKey colorsConfig={colorsConfig} />
        </Flex>
      )}

      <Flex justifyContent="space-between" height="100%" alignItems="center">
        <WorldMap
          data={mapDataToCountry}
          size="responsive"
          tooltipTextFunction={(context) =>
            tooltipTextFunction(context, meta, valueKey)
          }
          color={colorScheme ?? theme.primary}
          backgroundColor={theme.background.primary}
          richInteraction
          tooltipBgColor={theme.primary}
          onClickFunction={onClickFunction}
        />

        <CountryDetails
          details={state}
          closeDetails={() => setState(null)}
          meta={meta}
        />
      </Flex>

      {props.details ? (
        <Flex justifyContent="flex-end" width="100%">
          <Details
            dashboardName={props.dashboardId}
            visualizationId={props.chart.visualizationId}
            chart={chart}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
