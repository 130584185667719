import styled from "@emotion/styled";
import Flex from "../../../UI/Flex/Flex";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import Input from "../../../UI/Form/Input/Input";
import Switcher from "../../../UI/Switcher/Switcher";
import SplitBy from "../components/SplitBy";

const Container = styled.div`
  color: ${(props) => props.theme.text.secondary};
`;

export default function ActiveTableSettings(props) {
  const {
    name,
    setName,
    displaySettings,
    setDefaultViewFlag,
    columns,
    setViewSettingsByKey,
  } = props;

  const { isDefaultView, splitBy, limit, splitBySortDirection } =
    displaySettings;

  const sortDirection = splitBySortDirection ?? "asc";

  return (
    <Container>
      <Input
        label="View Name"
        style={{ width: "50%" }}
        inPlaceEdit
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <Checkbox
        label="Default View"
        style={{ marginLeft: 0 }}
        onChange={() => setDefaultViewFlag()}
        checked={!!isDefaultView}
      />
      <div style={{ marginBottom: 40 }} />
      <Flex justifyContent="space-between" width="450px">
        <SplitBy
          columns={columns}
          handleSplit={(option) =>
            setViewSettingsByKey("splitBy", option.value)
          }
          splitBy={splitBy}
          label="Default Split By"
        />
        <div style={{ fontSize: 12 }}>
          <div style={{ marginBottom: 10 }}>Split By Sort Direction</div>

          <Switcher
            leftLabel="Ascending"
            rightLabel="Descending"
            checked={sortDirection === "desc"}
            fontSize={12}
            handleSwitchChange={() =>
              setViewSettingsByKey(
                "splitBySortDirection",
                sortDirection === "asc" ? "desc" : "asc"
              )
            }
          />
        </div>
      </Flex>

      <div style={{ marginBottom: 40 }} />
      <Input
        value={limit ?? ""}
        onChange={(e) => setViewSettingsByKey("limit", +e.target.value)}
        type="number"
        label="Total Rows Limit"
        small
        style={{ width: "200px" }}
      />
    </Container>
  );
}
