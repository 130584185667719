import { useEffect, useState, useCallback } from "react";
import isEqual from "react-fast-compare";
import { useSelector, useDispatch } from "react-redux";
import { SET_ACTIVE_TABLE_CURRENT_VIEW_UUID } from "../../../store/actions/actionTypes";

export default ({ views = [] }) => {
  const dispatch = useDispatch();
  let initialDefaultView = views?.length ? views[0].uuid : null;

  const [previousViews, setPreviousViews] = useState(initialDefaultView);
  const selectedView = useSelector(
    (state) => state.activeTable.currentViewUuid ?? null
  );
  const setSelectedView = useCallback(
    (viewUuid) => {
      dispatch({ type: SET_ACTIVE_TABLE_CURRENT_VIEW_UUID, payload: viewUuid });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isEqual(previousViews, views)) {
      if (selectedView === "new") {
        const newCreatedView = views.find(
          (v) => !previousViews.find((pv) => pv.uuid === v.uuid)
        );
        setSelectedView(newCreatedView?.uuid);
      } else {
        const view =
          (views ?? []).find((view) => view.displaySettings?.isDefaultView) ??
          views[0];

        setSelectedView(selectedView || view?.uuid || null);
      }
      setPreviousViews(views);
    }
  }, [views, previousViews, selectedView, setSelectedView]);

  useEffect(() => {
    const thereAreViewsButSelectedViewWasNotFound =
      views?.length &&
      selectedView !== "new" &&
      !views.find((v) => v.uuid === selectedView);

    if (thereAreViewsButSelectedViewWasNotFound) {
      const view =
        (views ?? []).find((view) => view.displaySettings?.isDefaultView) ??
        views[0];

      setSelectedView(selectedView || view?.uuid || null);
    }
  }, [selectedView, setSelectedView, views]);

  return { selectedView, setSelectedView };
};
